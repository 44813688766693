import {StyleSheet} from "@react-pdf/renderer";

export const PdfDateDetails = StyleSheet.create({
    // Styled die Telefonnummer
    phoneNumber: {
        fontSize: 21,
        color: '#ec3f3f',
        fontWeight: "bold",
        marginTop: 30
    },
    // Platzierung des Infoblocks oben
    infoBlock: {
        marginLeft: "auto",
        paddingLeft: "0",
        marginTop: 70,
        width: "75mm",
        marginRight: "-10mm"
    },
    // Styled die Headline
    headline: {
        fontWeight: "bold",
        fontSize: 18,
        marginBottom: 5
    },
    // Positioniert Blöcke nebeneinander mit möglichst viel abstand zwischen einander
    data: {
        justifyContent: "space-between",
        flexDirection: 'row', // Set flex direction to 'row' for horizontal arrangement
        marginBottom: 0.5,
    },

});
