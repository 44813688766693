import {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";

const dataColOnePriceType = (newElement:any,response:any,Store:any) =>  {
    return [

        {
            'formType': 'input',
            'type': 'hidden',
            'name': 'id',
            'value': (newElement === false ? response.id : '0'),
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    id: 'image_title_image',
                    path:
                        newElement === false
                            ? response.logo !== undefined &&
                            response.logo !== null
                                ? response.logo
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 2,
                        md: 2,
                        sm: 2
                    }
                },
                {
                    formType: 'formFileBucket',
                    onclick: true,
                    onclick_context: 'pielers_element_text',
                    append: 'preview_media',
                    method:'cdn',
                    experiment: true,
                    src: 'image_title_image_',
                    type: 'file',
                    size: {
                        xl: 10,
                        md: 10,
                        sm: 10
                    }
                }
            ]
        },{
            formType: 'hidden',
            name: 'logo',
            id: 'AttachDocumentUrl',
            type: 'text',
            placeholder: 'imageID',
            value: newElement === false ? response.logo : ''
        },
        {
            formType: 'header',
            value:'Unternehmensinformationen',
            type:'h5'
        },
        {
            'formType':'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText':IntlMessageValue('de', 'layout.ownCompany', Store),
            'type': 'text',
            'name': 'ownCompany',
            'value': (newElement === false ? response.ownCompany : '')
        },
        {
            'formType':'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText':IntlMessageValue('de', 'layout.ceo', Store),
            'type': 'text',
            'name': 'ceo',
            'value': (newElement === false ? response.ceo : '')
        },
        {
            'formType':'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText':IntlMessageValue('de', 'layout.phone', Store),
            'type': 'text',
            'name': 'phone',
            'value': (newElement === false ? response.phone : '')
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.address', Store),
            'type': 'text',
            'name': 'address',
            'value': (newElement === false ? response.address : ''),
        },{
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.plz', Store),
            'type': 'text',
            'name': 'plz',
            'value': (newElement === false ? response.plz : ''),
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.city', Store),
            'type': 'text',
            'name': 'city',
            'value': (newElement === false ? response.city : ''),
        },
        {
            formType: 'header',
            value:'Bankinformationen',
            type:'h5'
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.bankName', Store),
            'type': 'text',
            'name': 'bankName',
            'value': (newElement === false ? response.bankName : ''),
        },{
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.iban', Store),
            'type': 'text',
            'name': 'IBAN',
            'value': (newElement === false ? response.IBAN : ''),
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.bic', Store),
            'type': 'text',
            'name': 'BIC',
            'value': (newElement === false ? response.BIC : ''),
        },
        {
            formType: 'header',
            value:'Web-Infos',
            type:'h5'
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.web', Store),
            'type': 'text',
            'name': 'web',
            'value': (newElement === false ? response.web : ''),
        },{
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.mail', Store),
            'type': 'text',
            'name': 'mail',
            'value': (newElement === false ? response.mail : ''),
        },
        {
            formType: 'header',
            value:'Rechtsinfos und Steuern',
            type:'h5'
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.register', Store),
            'type': 'text',
            'name': 'register',
            'value': (newElement === false ? response.register : ''),
        },{
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.taxNumber', Store),
            'type': 'text',
            'name': 'taxNumber',
            'value': (newElement === false ? response.taxNumber : ''),
        },{
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.taxId', Store),
            'type': 'text',
            'name': 'taxId',
            'value': (newElement === false ? response.taxId : ''),
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.courtLoc', Store),
            'type': 'text',
            'name': 'courtLoc',
            'value': (newElement === false ? response.courtLoc : ''),
        },
        {
            'formType': 'input',
            'label': 'color',
            'type': 'color',
            'name': 'color',
            'value': (newElement === false ? response.color : ''),
        },

    ];
}

export default dataColOnePriceType;


/*
{
    "id": 1,
    "logo": "/webkom.png",
    "type": "webkom",
    "created_at": "2024-05-26 13:23:48",
    "updated_at": "2024-05-26 13:23:48"
}
 */
