import {StyleSheet} from "@react-pdf/renderer";

export const PdfCompanyInfo = StyleSheet.create({
    // Positioniert Blöcke nebeneinander mit möglichst viel abstand zwischen einander
    // definiert die Logo-Größe
    logoArea: {
        height: '42mm',
        width: '80mm',
        marginBottom: 15,
        textAlign: "left"
    },


    logo: {
        objectFit: "contain",
        marginRight: 'auto'
    },


    // Adressspalte
    addressColumn: {
        fontSize: 7.5,
        maxWidth: '80mm'
    },
    // Kein Abstand nach unten
    marginBottomZero: {
        marginBottom: 0,
        margin: 0,
        padding: 0
    },
    // Kundenadresse
    customerAddress: {
        fontSize: 10,
        marginTop: 15
    },

});
