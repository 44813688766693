import IntlMessage, {IntlMessageValue} from '../../../../../@WUM/core/component/const/IntlMessage';
import React from "react";

function configuration(conf: string,response:any,newElement:any = false,Store:any) {

  const dataColOne = [
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'id',
      'value': (newElement === false ? response.id : '0'),
    },
    {
      'formType': 'multiply',
      'cols': [
    {
      'formType': 'input',
      'label': 'duration',
      'type': 'number',
      'name': 'duration',
      'value': (newElement === false ? response.duration : ''),
    },
        {
          'formType': 'select',
          'label': 'durationtype',
          'name': 'durationtype',
          'selected': (newElement === false ? response.durationtype : 0),
          'options': [
            {
              'label': '',
              'value': '0'
            },
            {
              'label': 'Jahr',
              'value': '1'
            },
            {
              'label': 'Monat',
              'value': '2'
            },
            {
              'label': 'Tag',
              'value': '3'
            },
            {
              'label': 'Woche',
              'value': '4'
            }]
        }
      ],

    },
    {
      'formType': 'input',
      'label': 'title',
      'type': 'text',
      'name': 'title',
      'value': (newElement === false ? response.title : ''),
    },

  ];

    return dataColOne;

}

export default configuration;
