import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";

const Delete = (props:{showDelete:any,handleCloseDelete:any,makeDelete:any,sendDeleted:any,setFetchData:any,successDeleted:any,deletedRoute:any,FieldMakeDelete:any}) => {
    return(
        <Modal show={props.showDelete} onHide={props.handleCloseDelete}>
            <Modal.Header closeButton>
                <Modal.Title>{(props.makeDelete !== null && props.makeDelete !== '' ?
                    /* @ts-ignore */
                    props.makeDelete[props.FieldMakeDelete[0]] : 'test')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sind Sie sicher, dass Sie {(props.makeDelete !== null && props.makeDelete !== '' ?
                /* @ts-ignore */
                props.makeDelete[props.FieldMakeDelete[1]] : 'test')} -
                {(props.makeDelete !== null && props.makeDelete !== '' ?
                    /* @ts-ignore */
                    props.makeDelete[props.FieldMakeDelete[0]] : 'test')} löschen möchten?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleCloseDelete}>
                    Schließen
                </Button>

                {(props.makeDelete !== null && props.makeDelete !== '' ?
                        <Button variant="danger" onClick={()=>props.sendDeleted(
                            /* @ts-ignore */
                            props.makeDelete?.id,props.successDeleted,props.handleCloseDelete,props.setFetchData,props.deletedRoute)}
                        >
                            Löschen
                        </Button>
                        :
                        <></>
                )}

            </Modal.Footer>
        </Modal>
    )
}

export default Delete;
