import React, {useState} from 'react';
import {DynamikOffCanvaspv} from "../../../@WUM/core/component/const/DynamikModal";

import {useSelector} from "react-redux";
import {
    IntlMessageValue, IntlMessage
} from "../../../@WUM/core/Elements";
import Button from "react-bootstrap/Button";
import PielersForm from "../../../@WUM/core/form-serilizer";
import dataColOnePriceType from "../../settings/layouts/modules/configuration/dataColOne";

interface LayoutCanvas {
    fetchAllProducts?: any
}

const LayoutCanvas = ( props:LayoutCanvas) => {
    const Store = useSelector((state: any) => state)
    const handlesubmit = (e:any) => {
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);


    const handleShow = () => setShow(true);
    const CloseDynmaikModal = () => {
        handleClose();
    }
    const saveRoute = 'pv/layout/[param]/'
    const [fetchData, setFetchData] = useState(0);
    const successFormDynamik = (result:any) => {
        setFetchData(Date.now())
        handleClose() }

    const errorFormDynamik = (error:any)=> {
        handleClose()
    }
    return (
        <>
            <DynamikOffCanvaspv openButtonLabel={<><Button variant="primary" className={'float-end'} onClick={handleShow}>
                                   <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                                        add
                                    </span>
                <IntlMessage Store={Store} messageId="offer.preview.add.layout"/>
            </Button></>}
                                saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                                closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                                SaveID={'CreateNewProjects'}
                                title={IntlMessageValue( 'de', 'layout.add.offcanvas', Store)}
                                saveDisabled={false}
                                ClassOpenButton={'float-end'} indiButton={''}
                                ClassSaveButton={'float-end'}
                                body={<>{PielersForm(dataColOnePriceType(true,{},Store))}</>}
                                handlesubmit={handlesubmit}
                                errorForm={errorFormDynamik}
                                successForm={successFormDynamik}
                                setFetchData={setFetchData}
                                handleShow={handleShow}
                                saveRoute={saveRoute}
                                show={show}
                                setShow={setShow}
                                handleClose={CloseDynmaikModal}
            />
            {/*<DynamikOffCanvas
                openButtonLabel={
                    <>
                        <IntlMessage Store={Store} messageId="offer.preview.add.layout"/>
                    </>
                }
                saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                SaveID={'CreateNewProjects'}
                title={IntlMessageValue( 'de', 'layout.add.offcanvas', Store)}
                saveDisabled={true}
                ClassOpenButton={'float-end'}
                ClassSaveButton={'float-end'}
                body={
                    <>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.logo', Store)}/>
                            <UniFormControl
                                class={"px-3 py-2"}
                                id={'logo'}
                                name={'logo'}
                                type="file"
                                accept=".png"
                                required={false}
                            />
                        </UniForm.InputGroup>
                        <h5>Unternehmensinformationen</h5>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.ownCompany', Store)}/>
                            <UniForm.FormControl name={'ownCompany'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'ownCompany'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.ceo', Store)}/>
                            <UniForm.FormControl name={'ceo'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'ceo'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.phone', Store)}/>
                            <UniForm.FormControl name={'phone'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'phone'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.address', Store)}/>
                            <UniForm.FormControl name={'address'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'address'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.plz', Store)}/>
                            <UniForm.FormControl name={'plz'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'plz'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.city', Store)}/>
                            <UniForm.FormControl name={'city'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'city'}
                            />
                        </UniForm.InputGroup>
                        <h5>Bankinformationen</h5>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.bankName', Store)}/>
                            <UniForm.FormControl name={'bankName'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'bankName'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.iban', Store)}/>
                            <UniForm.FormControl name={'iban'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'iban'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.bic', Store)}/>
                            <UniForm.FormControl name={'bic'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'bic'}
                            />
                        </UniForm.InputGroup>
                        <h5>Web-Infos</h5>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.web', Store)}/>
                            <UniForm.FormControl name={'web'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'web'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.mail', Store)}/>
                            <UniForm.FormControl name={'mail'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'mail'}
                            />
                        </UniForm.InputGroup>
                        <h5>Rechtsinfos und Steuern</h5>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.register', Store)}/>
                            <UniForm.FormControl name={'register'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'register'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.taxNumber', Store)}/>
                            <UniForm.FormControl name={'taxNumber'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'taxNumber'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.taxId', Store)}/>
                            <UniForm.FormControl name={'taxId'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'taxId'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'layout.courtLoc', Store)}/>
                            <UniForm.FormControl name={'courtLoc'}
                                                 required={true}
                                                 type={'text'}
                                                 id={'courtLoc'}
                            />
                        </UniForm.InputGroup>

                    </>
                }
                handlesubmit={handlesubmit}
                handleShow={handleShow}
                show={show}
                setShow={setShow}
                handleClose={CloseDynmaikModal}

            />*/}

        </>
    )
}


export default LayoutCanvas