import React from "react";
import PeopleOverview from "./Pages/overview";
import {
    initialStatePeopledMenuConfig,
    initialStatePeoplePagesConfig, PeopledMenuConfigInterface,
    PeoplePagesConfigInterface
} from "../interface/People.interface";
import {LanguageSwitcherValue} from "../../../../@WUM/core/component/core/components/LanguageSwitcher";
import PeopleNew from "./Pages/new";
import DetailPeople from "./Pages/detail";
import Peopledeleted from "./Pages/deleted";

export const OrderPagesConfig = (props: PeoplePagesConfigInterface = initialStatePeoplePagesConfig) => {


    return [
        {
            path: '/order/overview/:page',
            elements: <PeopleOverview value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/order/new/:page',
            elements: <PeopleNew value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/order/detail/:id',
            elements: <DetailPeople value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/order/delete/:deleteID',
            elements: <Peopledeleted value={props.value}/>,
            roles: ['superadmin','admin']
        },
    ];
}

export const OrderMenuConfig = (props: PeopledMenuConfigInterface = initialStatePeopledMenuConfig) => {
    return [
        {
            name: 'Aufträge',
            route: '/order/overview/1',
            icon: 'shopping_bag',
            roles: ['superadmin','admin']
        },
    ]
}
