import {initialStatePeopleDetail, PeopleDetailInterface} from "../../interface/People.interface";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {useLocalStorage} from "../../../../../@WUM/core/Function/index";
import {SpinnerJSX} from "../../../../../@WUM/core/Elements/index";
import {peopleApiType} from "../../API/request/peopleApiType";
import { useSelector,useDispatch  } from 'react-redux'
import {
    Responsebuytype,
    ResponseDetailPeople, ResponsefetchAllProducts,
    ResponseMwST,
    Responsepricetype,
    Responseservicetimetype
} from "../../API/response/people";
import {PDFViewer} from "@react-pdf/renderer";
import InvoicePdf from "../elements/pdf/invoicePdf";

const DetailPeople = (props:PeopleDetailInterface=initialStatePeopleDetail) => {
    const dispatch = useDispatch()
    const Store = useSelector((state: any) => state)
    const {id} = useParams<{ id: string }>();

    let response2 = ResponseDetailPeople(id); // old data callmeins
    let MwST = ResponseMwST()
    let buytype = Responsebuytype()
    let pricetype = Responsepricetype()
    let servicetimetype = Responseservicetimetype()
    let fetchAllProducts = ResponsefetchAllProducts();

    const [currentTutorial, setCurrentTutorial] = React.useState<peopleApiType[]>(response2);
    const [element, setElement] = React.useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    const [seo_title, setseo_title] = useLocalStorage("seo_title", "");
    const [seo_description, setseo_description] = useLocalStorage("seo_description", "");
    const [deleteElement, setdeleteElement] = React.useState(0);
    const history = useNavigate();
    const [Index, setIndex] = React.useState(0);
    const [validated, setValidated] = React.useState(false);

    const [layout, setLayout] = useState({
        logo:"/webkom.png",
        phone: "+49 171 7751453",
        address: "Zwickauer Straße 480",
        plz: "09117",
        ownCompany: "WEBKOMMUNIKATION24 GmbH",
        bankName: "Sparkasse Chemnitz",
        IBAN: "DE18 8705 0000 0714 9839 93",
        BIC: "CHEKDE81XXX",
        web: "www.weko24.de",
        mail: "info@weko24.de",
        ceo: "Dr. Karin Rabe",
        city: "Chemnitz",
        register: "HRB 27243",
        taxNumber: "215/122/04551",
        taxId: "DE 281479345",
        courtLoc: "Chemnitz",
        type:'webkom'
    }); // State to hold initial layout 640x491

    const debug = (deleteElement: number) => {
        return;
    }

    debug(deleteElement);
    const isObjectAndNotEmpty = (response2:any) => {
        return typeof response2 === 'object' && Object.keys(response2).length > 0;
    };
    console.log(isObjectAndNotEmpty(response2))
    if (response2 !== undefined && isObjectAndNotEmpty(response2)) {

        return (
            <>
                <PDFViewer
                    width={"auto"}
                    height={"750px"}
                    className={"col-12"}
                >
                    <InvoicePdf data={response2} fetchAllMwST={MwST} status={'final'} mode={'detail'} layout={layout} fetchAllProducts={fetchAllProducts} Store={Store} PaymentMethod={servicetimetype} buytype={buytype}/>
                </PDFViewer>
            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default DetailPeople;
