import React from "react";
import {Text, View} from "@react-pdf/renderer";
import {PdfDateDetails as styles} from "../css/PdfDateDetails.css";
import {DocumentType} from "../interfaces/Documenttypes";
import {CheckValue} from "../../../functions/checkValue";
import {TodayGerman} from "../../today";
import {DocumentStatus} from "../interfaces/DocumentStatus";

interface DocumentInfoProps {
    type: DocumentType;
    Data: any;
    Store: any;
    label:string;
    mode: 'date' | 'number';
    status?: DocumentStatus;
}

export const DocumentInfo = (props: DocumentInfoProps) => {
    const invoiceNumber = props.Data.invoiceNumber;
    const offerNumber = props.Data.offerNumber;
    const invoiceDate = props.Data.invoiceDate || TodayGerman();
    const offerDate = props.Data.offerDate ||TodayGerman();
    let HolderPDF = [];

    switch (props.type) {
        case "offer":
            HolderPDF.push(
                <View style={[styles.data]}>
                    <Text>{props.label}</Text>
                    <Text>
                        {props.mode === 'number' ? offerNumber : props.status === 'final' ? <>{CheckValue(offerDate)}</> : <>Noch nicht versendet</>}
                    </Text>
                </View>
            );
            break;
        default:
            HolderPDF.push(
                <View style={[styles.data]}>
                    <Text>{props.label}</Text>
                    <Text>{props.mode === 'number' ? invoiceNumber : props.status === 'final' ? <>{CheckValue(invoiceDate)}</> : <>Noch nicht versendet</>}</Text>
                </View>
            );
            break;
    }

    return <>{HolderPDF}</>; // Return the single JSX element
};
