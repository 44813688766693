import Form from "react-bootstrap/Form";
import PielersForm from "../../../../@WUM/core/form-serilizer";
import React from "react";
import CloseMember from "./CloseMember";

const Edit = (responses:any,sendEdit:any,dataColOne:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute:any) => {
    return (
        <>
            <div className="offcanvas-body">
                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        style={{padding: 0}}
                        className={'formControl'}
                        onSubmit={(e) =>
                            sendEdit(e, CloseMember,responses.id,'addDialogdynamik',successForm,errorForm,setFetchData,navigate,editRoute)
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Bearbeiten</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember(true,'addDialogdynamik')}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                {PielersForm(dataColOne(false,responses))}
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember(true,'addDialogdynamik')}>Close
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'}
                                        className="btn btn-primary float-end">Save
                                </button>
                            </div>

                        </div>
                    </Form>
                </div>
            </div>
        </>
    )

}

export default Edit;
