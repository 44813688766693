const dataColOne = (newElement:any,response:any) =>  {
    return [
        {
            'formType': 'input',
            'type': 'hidden',
            'name': 'id',
            'value': (newElement === false ? response.id : '0'),
        },
        {
            'formType': 'input',
            'label': 'Name',
            'type': 'text',
            'name': 'name',
            'required': true,
            'value': (newElement === false ? response.name : ''),
        },
        {
            'formType': 'input',
            'label': 'Standort',
            'type': 'text',
            'name': 'location',
            'value': (newElement === false ? response.location : ''),
        },
        {
            'formType': 'input',
            'label': 'Nummer',
            'type': 'text',
            'name': 'landline_number',
            'value': (newElement === false ? response.landline_number : ''),
        },
        {
            'formType': 'input',
            'label': 'Handy',
            'type': 'text',
            'name': 'mobile_number',
            'value': (newElement === false ? response.mobile_number : ''),
        },
        {
            'formType': 'input',
            'label': 'Kurzwahl intern',
            'type': 'text',
            'name': 'speed_dialing',
            'value': (newElement === false ? response.speed_dialing : ''),
        },
        {
            'formType': 'input',
            'label': 'Email',
            'type': 'text',
            'name': 'email',
            'value': (newElement === false ? response.email : ''),
        },
        {
            'formType': 'input',
            'label': 'Info',
            'type': 'text',
            'name': 'info',
            'value': (newElement === false ? response.info : ''),
        }
    ];
}

export default dataColOne;
