import {initialStatePeopleDetail, PeopleDetailInterface} from "../../interface/People.interface";
import React, {useEffect} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {useLocalStorage} from "../../../../../@WUM/core/component/const/useLocalStoreage";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import updateData from "../../../../../@WUM/core/component/core/components/updateData";
import FormCMS from "../../../../../@WUM/core/component/core/components/Form.cms";
import TabsConfig from "../configuration/Tabs.config";
import {peopleApiType} from "../../API/request/peopleApiType";
import {people} from "../../API/request/people";
import { useSelector,useDispatch  } from 'react-redux'
import {

    ResponseDetailPeople

} from "../../API/response/people";

const DetailPeople = (props:PeopleDetailInterface=initialStatePeopleDetail) => {
    const dispatch = useDispatch()
    const Store = useSelector((state: any) => state)
    const {id} = useParams<{ id: string }>();

    let response2 = ResponseDetailPeople(id);

    const [currentTutorial, setCurrentTutorial] = React.useState<peopleApiType[]>(response2);
    const [element, setElement] = React.useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    const [seo_title, setseo_title] = useLocalStorage("seo_title", "");
    const [seo_description, setseo_description] = useLocalStorage("seo_description", "");
    const [deleteElement, setdeleteElement] = React.useState(0);
    const history = useNavigate();
    const [Index, setIndex] = React.useState(0);
    const [validated, setValidated] = React.useState(false);


    const debug = (deleteElement: number) => {
        return;
    }

    debug(deleteElement);


        if (response2 !== undefined) {
        return (
            <>
                <FormCMS history={history} element={element} setElement={setElement} id={id} Index={Index} setIndex={setIndex} currentTutorial={currentTutorial}
                         modalData={modalData} modalShow={modalShow} setModalShow={setModalShow} setModalData={setModalData} seo_title={seo_title}
                         setseo_title={setseo_title} setseo_description={setseo_description} seo_description={seo_description} response2={response2}
                         part={'priceType'} setdeleteElement={setdeleteElement} setValidated={setValidated} validated={validated} NowRoute={people}
                         setCurrentTutorial={setCurrentTutorial} updateData={updateData} config={TabsConfig} Store={Store} nonPreview={true}  isTab={true}
                />

            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default DetailPeople;
