import { ValueFormat} from "./IntlNumberFormat"
interface setTotalPriceProps {
        tableIdRef: number
}

const setTotalPrice = (props:setTotalPriceProps) => {

        let invoicePriceValue = (document.getElementById('invoicePrice'+props.tableIdRef) as HTMLInputElement)
        let SinglePrice = invoicePriceValue.value
        let invoiceAmountValue = (document.getElementById('invoiceAmount'+props.tableIdRef) as HTMLInputElement)
        let Amount = invoiceAmountValue.value
        let invoiceTotalValue = (document.getElementById('invoiceTotal'+props.tableIdRef) as HTMLInputElement)
        let discountValue = (document.getElementById('invoiceDiscount'+props.tableIdRef) as HTMLInputElement)
        let Discount = 100 - parseFloat(discountValue.value)
        let Total = parseFloat(SinglePrice) * parseFloat(Amount) * (Discount/100)
        invoiceTotalValue.value = '' + ValueFormat.format(Total)

        //console.log(Total)


        let offerPriceNetto = (document.getElementById('offerPriceNetto') as HTMLInputElement)
        let offerVat = (document.getElementById('offerVat') as HTMLInputElement)
        let offerPriceBrutto = (document.getElementById('offerPriceBrutto') as HTMLInputElement)


        // Select all input fields with ID containing "invoiceTotal"
        const inputFields = document.querySelectorAll('input[id*="invoiceTotal"]');

        // Initialize sum variable
        let sum = 0;

        // Iterate through input fields and extract values
        for (const inputField of inputFields) {
                const value = parseInt((inputField as HTMLInputElement).value.replace(',','').replace('.',''));
                // console.log(parseInt((inputField as HTMLInputElement).value.replace(',','').replace('.','')) + "value")
                // const value = parseFloat((inputField as HTMLInputElement).value.replace(',', '.'));
                // console.log(parseFloat((inputField as HTMLInputElement).value.replace(',', '.')) + "parse")
                if (!isNaN(value)) { // Check if value is a valid number
                        sum += value/100;
                        console.log(sum + "sum")
                }
        }

        let vat = sum * 0.19
        let brutto = sum * 1.19

        offerPriceNetto.value = ValueFormat.format(sum)
        offerVat.value = ValueFormat.format(vat)
        offerPriceBrutto.value = ValueFormat.format(brutto)


}

export default setTotalPrice