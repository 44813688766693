import {phoneApiType} from "../request/phoneApiType";
import {useEffect, useState} from 'react';
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {error} from "../../../../../@WUM/core/Elements/index";
import {phone} from "../request/phone";

export const ResponsephoneOverview = (limit: any, searchValue: any,fetchData:any) => {
    const [posts, setPosts] = useState<phoneApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await phone.getPosts(limit,searchValue)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [limit,searchValue,fetchData])


    return isError ? (posts ) : (posts);

}

export const ResponseDetailphone = (id: any) => {
    const [posts, setPosts] = useState<phoneApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await phone.getAPost(id)
                .then((data) => {
                    checkLogin(data);
                    return setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (posts ) : (posts);

}

export const ResponseDeletephone = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await phone.deletePost(id)
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    error(err);
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (isError ) : (isError);

}
