import {priceTypeMenuConfig, priceTypePagesConfig} from "./priceType/modules";
import {MwStTypeMenuConfig, MwStTypePagesConfig} from "./MwSTType/modules";
import {SZRTypeMenuConfig, SZRTypePagesConfig} from "./szrType/modules";
import {ZahlungsartypeMenuConfig, ZahlungsartypePagesConfig} from "./zahlungsartype/modules";
import {MemberMenuConfig, MemberPagesConfig} from "./Member";
import {layoutMenuConfig, layoutPagesConfig} from "./layouts/modules";
import {companyMenuConfig, companyPagesConfig} from "./company/modules";


export const SettingsRouteConfig = (value:any) => {
    return [
        ...priceTypePagesConfig(value),
        ...MwStTypePagesConfig(value),
        ...SZRTypePagesConfig(value),
        ...ZahlungsartypePagesConfig(value),
        ...MemberPagesConfig(value),
        ...layoutPagesConfig(value),
        ...companyPagesConfig(value)
    ]
}

export const SettingsMenuConfig = (value:any) => {
        return [
            {
                header: 'Einstellungen',
                icon: 'settings',
                roles: ['superadmin','admin'],
                menuitem: [
                    ...MemberMenuConfig(value),
                    ...priceTypeMenuConfig(value),
                    ...MwStTypeMenuConfig(value),
                    ...SZRTypeMenuConfig(value),
                    ...ZahlungsartypeMenuConfig(value),
                    ...layoutMenuConfig(value),
                    ...companyMenuConfig(value)
                ]
            },
        ];
    }

