import {
    IntlMessageValue, ApiURL
} from "../../../../../@WUM/core/Elements";
import React, {useState} from "react";
import {DynamikOffCanvaspv} from "../../../../../@WUM/core/component/const/DynamikModal";
import MIcon from "../../../../../@WUM/core/Elements/MIcon";
import PielersForm from "../../../../../@WUM/core/form-serilizer";
import dataColOneProduct from "../../../products/modules/configuration/dataColOne";
import AddDialogdynamik from "../../../telephone/helper/AddDialogdynamik";
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {getToken} from "../../../../../@WUM/core/component/const/getToken";



const ProductDetailModal = (props:{Store:any,setFetchData:any}) => {

    const Store = props.Store

    const [showDelete, setShowDelete] = useState(false);
    const [makeDelete, setMakeDelete] = useState(null);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const CloseDynmaikModal = () => { handleClose(); }
    const saveRoute = 'pv/prodcut/[param]/'

    const successFormDynamik = (result:any) => {
        props.setFetchData(Date.now())
        handleClose() }

    const errorFormDynamik = (error:any)=> {
        handleClose()
    }

    const handleSubmit = (e:any,successFormDynami:any,errorFormDynami:any,saveRoute:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(Data);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +'pv/prodcut/0/', requestOptions)
            .then(response => response.text())
            .then(result => successFormDynamik(result))
            .catch(error => errorFormDynamik(error));
    }

    return (
        <>
            <DynamikOffCanvaspv openButtonLabel={<><MIcon newMaterial={true} children={'add'} variant={'fill'} className={'py-0'}/></>}
                                saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                                closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                                SaveID={'newphone'}
                                title={'Produkt anlegen'}
                                saveDisabled={false}
                                ClassOpenButton={'float-end'}
                                ClassSaveButton={'float-end'}
                                body={<>{PielersForm(dataColOneProduct(true,[]))}</>}
                                handlesubmit={handleSubmit}
                                errorForm={errorFormDynamik}
                                successForm={successFormDynamik}
                                setFetchData={props.setFetchData}
                                handleShow={handleShow}
                                saveRoute={saveRoute}
                                show={show}
                                setShow={setShow}
                                handleClose={CloseDynmaikModal}
            />
            <br style={{clear: 'both'}}/>
            <AddDialogdynamik />
        </>

    )
}

export default ProductDetailModal