import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import React from "react";
import {dateFormat} from "../../../../@WUM/core/component/const/dateFormat";

const BusinessCard = (props:{response:any,AddedFunc:any,Edit:any,loadedDelete:any,sendEdit:any,dataColOne:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute?:any}) => {

    let bcard = [];

    for(let r=0;r<props.response.length;r++) {
        if(props.response[r].name){
            bcard.push(
                <><Col className={'col-3 mb-3'} >
                    <Card>
                        <Card.Header>{(props.response[r].name !== null && props.response[r].name !== '' ? props.response[r].name : 'Kein Name angegeben')}</Card.Header>
                        <Card.Body>
                            <Card.Title>{props.response[r].location !== null && props.response[r].location !== '' ? props.response[r].location : 'Kein Standort'}</Card.Title>
                            <Card.Text>
                                <p>E-Mail: {(props.response[r].email !== null && props.response[r].email === '' && props.response[r].email.includes('@') === true ? <a href={'mailto:'+props.response[r].email}>{props.response[r].email}</a> : (props.response[r].email !== null && props.response[r].email !== '' ?  props.response[r].email : 'Keine E-Mail' ))} </p>
                                <p>Telefon: {(props.response[r].landline_number !== '' && props.response[r].landline_number !== null ? <a href={'tel:'+props.response[r].landline_number}>{props.response[r].landline_number}</a> : 'Keine Telefonnummer')}</p>
                                <p>Handy: {(props.response[r].mobile_number !== '' && props.response[r].mobile_number !== null ? <a href={'tel:'+props.response[r].mobile_number}>{props.response[r].mobile_number}</a>: 'Keine Mobilnummer')}</p>
                                <Button variant="primary" onClick={(e) => props.AddedFunc(props.Edit(props.response[r],props.sendEdit,props.dataColOne,props.successForm,props.errorForm,props.setFetchData,props.navigate,props.editRoute))}>Bearbeiten</Button>
                                <Button variant="danger"  className={'float-end'} onClick={(e) => props.loadedDelete(props.response[r])}>Löschen</Button>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted">{(props.response[r].info !== null && props.response[r].info !== '' ? props.response[r].info : 'Keine Info')}</Card.Footer>
                    </Card>
                </Col>
                </>
            )
        }

    }

    return(
        <>
            <Row>
                {bcard}
            </Row>

        </>
    )
}

export default BusinessCard;

export const BusinessCardPriceType = (props:{response:any,AddedFunc:any,Edit:any,loadedDelete:any,sendEdit:any,dataColOne:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute?:any}) => {

    let bcard = [];

    for(let r=1;r<props.response.length;r++) {
        if(props.response[r].text){
            bcard.push(
                <><Col className={'col-3 mb-3'} >
                    <Card>
                        <Card.Header>{(props.response[r].text !== null && props.response[r].text !== '' ? props.response[r].text : 'Kein Name angegeben')}</Card.Header>
                        <Card.Body>
                            <Card.Title>{props.response[r].textkurz !== null && props.response[r].textkurz !== '' ? props.response[r].textkurz : 'Keine Location Angabe'}</Card.Title>
                            <Card.Text>
                               <Button variant="primary" onClick={(e) => props.AddedFunc(props.Edit(props.response[r],props.sendEdit,props.dataColOne,props.successForm,props.errorForm,props.setFetchData,props.navigate,props.editRoute))}>Bearbeiten</Button>
                                <Button variant="danger"  className={'float-end'} onClick={(e) => props.loadedDelete(props.response[r])}>Löschen</Button>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted">{(props.response[r].info !== undefined && props.response[r].info !== null && props.response[r].info !== '' ? props.response[r].info : 'Keine Info')}</Card.Footer>
                    </Card>
                </Col>
                </>
            )
        }

    }

    return(
        <>
            <Row>
                {bcard}
            </Row>

        </>
    )
}

export const BusinessCardLayouts = (props:{response:any,AddedFunc:any,Edit:any,loadedDelete:any,sendEdit:any,dataColOne:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute?:any}) => {

    let bcard = [];

    for(let r=1;r<props.response.length;r++) {
        if(props.response[r].BIC){
            bcard.push(
                <><Col className={'col-3 mb-3'} >
                    <Card>
                        <Card.Header>{(props.response[r].ownCompany !== null && props.response[r].ownCompany !== '' ? props.response[r].ownCompany : 'Kein ownCompany angegeben')}</Card.Header>
                        <Card.Body>
                            <Card.Title>

                                {props.response[r].ceo !== null && props.response[r].ceo !== '' ? props.response[r].ceo : 'Keine ceo Angabe'}<br/>
                                {props.response[r].address !== null && props.response[r].address !== '' ? props.response[r].address : 'Keine address Angabe'}<br/>
                                {props.response[r].plz !== null && props.response[r].plz !== '' ? props.response[r].plz : 'Keine plz Angabe'} {props.response[r].city !== null && props.response[r].city !== '' ? props.response[r].city : 'Keine city Angabe'}<br/>
                                {props.response[r].register !== null && props.response[r].register !== '' ? props.response[r].register : 'Keine register Angabe'}<br/>
                                {props.response[r].taxId !== null && props.response[r].taxId !== '' ? props.response[r].taxId : 'Keine taxId Angabe'}<br/>
                                {props.response[r].taxNumber !== null && props.response[r].taxNumber !== '' ? props.response[r].taxNumber : 'Keine taxNumber Angabe'}<br/>
                            </Card.Title>
                            <Card.Text>
                                <Button variant="primary" onClick={(e) => props.AddedFunc(props.Edit(props.response[r],props.sendEdit,props.dataColOne,props.successForm,props.errorForm,props.setFetchData,props.navigate,props.editRoute))}>Bearbeiten</Button>
                                <Button variant="danger"  className={'float-end'} onClick={(e) => props.loadedDelete(props.response[r])}>Löschen</Button>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted">{(props.response[r].web !== undefined && props.response[r].web !== null && props.response[r].web !== '' ? props.response[r].web : 'Keine web')}</Card.Footer>
                    </Card>
                </Col>
                </>
            )
        }

    }

    return(
        <>
            <Row>
                {bcard}
            </Row>

        </>
    )
}

export const BusinessCardCompany = (props:{response:any,AddedFunc:any,Edit:any,loadedDelete:any,sendEdit:any,dataColOne:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute?:any}) => {

    let bcard = [];

    for(let r=1;r<props.response.length;r++) {
        if(props.response[r].Firma){
            bcard.push(
                <><Col className={'col-3 mb-3'} >
                    <Card>
                        <Card.Header>{(props.response[r].Firma !== null && props.response[r].Firma !== '' ? props.response[r].Firma : 'Kein ownCompany angegeben')}</Card.Header>
                        <Card.Body>
                            <Card.Title>

                                {props.response[r].GF !== null && props.response[r].GF !== '' ? props.response[r].GF : 'Keine ceo Angabe'}<br/>
                                {props.response[r].Steuernummer !== null && props.response[r].Steuernummer !== '' ? props.response[r].Steuernummer : 'Keine Steuernummer Angabe'}<br/>
                                {props.response[r].Kreditinstitut !== null && props.response[r].Kreditinstitut !== '' ? props.response[r].Kreditinstitut : 'Keine Kreditinstitut Angabe'} {props.response[r].city !== null && props.response[r].city !== '' ? props.response[r].city : 'Keine city Angabe'}<br/>
                                {props.response[r].BIC !== null && props.response[r].BIC !== '' ? props.response[r].BIC : 'Keine BIC Angabe'}<br/>
                                {props.response[r].SWIFT !== null && props.response[r].SWIFT !== '' ? props.response[r].SWIFT : 'Keine SWIFT Angabe'}<br/>
                                <img src={props.response[r].icon !== null && props.response[r].icon !== '' ? props.response[r].icon : ''} /><br/>
                            </Card.Title>
                            <Card.Text>
                                <Button variant="primary" onClick={(e) => props.AddedFunc(props.Edit(props.response[r],props.sendEdit,props.dataColOne,props.successForm,props.errorForm,props.setFetchData,props.navigate,props.editRoute))}>Bearbeiten</Button>
                                <Button variant="danger"  className={'float-end'} onClick={(e) => props.loadedDelete(props.response[r])}>Löschen</Button>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted">{(props.response[r].Web !== undefined && props.response[r].Web !== null && props.response[r].Web !== '' ? props.response[r].Web : 'Keine web')}</Card.Footer>
                    </Card>
                </Col>
                </>
            )
        }

    }

    return(
        <>
            <Row>
                {bcard}
            </Row>

        </>
    )
}

export const BusinessCardMwStType = (props:{response:any,AddedFunc:any,Edit:any,loadedDelete:any,sendEdit:any,dataColOne:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute?:any}) => {

    let bcard = [];

    for(let r=1;r<props.response.length;r++) {
        if(props.response[r].Text){
            bcard.push(
                <><Col className={'col-3 mb-3'} >
                    <Card>
                        <Card.Header>{(props.response[r].Schluessel !== null && props.response[r].Schluessel !== '' ? props.response[r].Schluessel : 'Kein Schluessel angegeben')}</Card.Header>
                        <Card.Body>
                            <Card.Title>{props.response[r].Text !== null && props.response[r].Text !== '' ? props.response[r].Text : 'Keine Text Angabe'} {props.response[r].standartwert !== null && props.response[r].standartwert !== '' ? props.response[r].standartwert : 'Keine Location Angabe'}%</Card.Title>
                            <Card.Text>
                                <Button variant="primary" onClick={(e) => props.AddedFunc(props.Edit(props.response[r],props.sendEdit,props.dataColOne,props.successForm,props.errorForm,props.setFetchData,props.navigate,props.editRoute))}>Bearbeiten</Button>
                                <Button variant="danger"  className={'float-end'} onClick={(e) => props.loadedDelete(props.response[r])}>Löschen</Button>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted">{(props.response[r].info !== undefined && props.response[r].info !== null && props.response[r].info !== '' ? props.response[r].info : 'Keine Info')}</Card.Footer>
                    </Card>
                </Col>
                </>
            )
        }

    }

    return(
        <>
            <Row>
                {bcard}
            </Row>

        </>
    )
}

export const BusinessCardszrType = (props:{response:any,AddedFunc:any,Edit:any,loadedDelete:any,sendEdit:any,dataColOne:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute?:any}) => {

    let bcard = [];

    for(let r=1;r<props.response.length;r++) {
        if(props.response[r].title){
            bcard.push(
                <><Col className={'col-3 mb-3'} >
                    <Card>
                        <Card.Header>{(props.response[r].title !== null && props.response[r].title !== '' ? props.response[r].title : 'Kein title angegeben')}</Card.Header>
                        <Card.Body>
                            <Card.Title>Dauer: {props.response[r].duration !== null && props.response[r].duration !== '' ? props.response[r].duration : 'Keine duration Angabe'}<br/> Durationtype: {props.response[r].durationtype !== null && props.response[r].durationtype !== '' ? props.response[r].durationtype : 'Keine Durationtype Angabe'}</Card.Title>
                            <Card.Text>
                                <Button variant="primary" onClick={(e) => props.AddedFunc(props.Edit(props.response[r],props.sendEdit,props.dataColOne,props.successForm,props.errorForm,props.setFetchData,props.navigate,props.editRoute))}>Bearbeiten</Button>
                                <Button variant="danger"  className={'float-end'} onClick={(e) => props.loadedDelete(props.response[r])}>Löschen</Button>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted">{(props.response[r].info !== undefined && props.response[r].info !== null && props.response[r].info !== '' ? props.response[r].info : 'Keine Info')}</Card.Footer>
                    </Card>
                </Col>
                </>
            )
        }

    }

    return(
        <>
            <Row>
                {bcard}
            </Row>

        </>
    )
}

export const BusinessCardsztType = (props:{response:any,AddedFunc:any,Edit:any,loadedDelete:any,sendEdit:any,dataColOne:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute?:any}) => {

    let bcard = [];

    for(let r=1;r<props.response.length;r++) {
        if(props.response[r].bez){
            bcard.push(
                <><Col className={'col-3 mb-3'} >
                    <Card>
                        <Card.Header>{(props.response[r].bez !== null && props.response[r].bez !== '' ? props.response[r].bez : 'Kein Bezeichnung angegeben')}</Card.Header>
                        <Card.Body>
                            <Card.Title>{(props.response[r].bez !== null && props.response[r].bez !== '' ? props.response[r].bez : 'Kein Bezeichnung angegeben')}</Card.Title>
                            <Card.Text>
                                <p>Schlusssatz: {props.response[r].schlusssatz !== null && props.response[r].schlusssatz !== '' ? props.response[r].schlusssatz : 'Keine duration Angabe'}<br/><br/> Beschreibung: {props.response[r].beschreibung !== null && props.response[r].beschreibung !== '' ? props.response[r].beschreibung : 'Keine Beschreibung '}</p>
                                <Button variant="primary" onClick={(e) => props.AddedFunc(props.Edit(props.response[r],props.sendEdit,props.dataColOne,props.successForm,props.errorForm,props.setFetchData,props.navigate,props.editRoute))}>Bearbeiten</Button>
                                <Button variant="danger"  className={'float-end'} onClick={(e) => props.loadedDelete(props.response[r])}>Löschen</Button>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted">{(props.response[r].info !== undefined && props.response[r].info !== null && props.response[r].info !== '' ? props.response[r].info : 'Keine Info')}</Card.Footer>
                    </Card>
                </Col>
                </>
            )
        }

    }

    return(
        <>
            <Row>
                {bcard}
            </Row>

        </>
    )
}

export const BusinessCardPeople = (props:{response:any,AddedFunc:any,Store:any,Edit:any,loadedDelete:any,sendEdit:any,dataColOne:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute?:any}) => {

    let bcard = [];

    for(let r=1;r<props.response.length;r++) {
        if(props.response[r].vorname){
            bcard.push(
                <><Col className={'col-3 mb-3'} >
                    <Card>
                        <Card.Header>{(props.response[r].title !== null && props.response[r].title !== '' ? props.response[r].title : 'Kein title angegeben')}</Card.Header>
                        <Card.Body>
                            <Card.Title>{(props.response[r].vorname !== null && props.response[r].vorname !== '' ? props.response[r].vorname : 'Kein Bezeichnung angegeben')} {(props.response[r].name !== null && props.response[r].name !== '' ? props.response[r].name : 'Kein Bezeichnung angegeben')}</Card.Title>
                            <Card.Text>
                                <p>Geburtstag: {props.response[r].source !== null && props.response[r].source !== '' ?
                                    dateFormat(
                                        props.response[r].source,
                                        localStorage.getItem('locale'),
                                        'us',
                                        false,
                                        false,
                                        props.Store
                                    ) : 'Keine Geburtstag angegeben'}<br/>Geschlecht: {props.response[r].geschlecht !== null && props.response[r].geschlecht !== '' ? (props.response[r].geschlecht === 0 ? 'Männlich' : props.response[r].geschlecht === 1 ? 'Weiblich' : 'Divers') : 'Kein geschlecht angegeben'}<br/> Zusatz: {props.response[r].zusatz !== null && props.response[r].zusatz !== '' ? props.response[r].zusatz : 'Kein zusatz angegeben'}</p>
                                <Button variant="primary" onClick={(e) => props.AddedFunc(props.Edit(props.response[r],props.sendEdit,props.dataColOne,props.successForm,props.errorForm,props.setFetchData,props.navigate,props.editRoute))}>Bearbeiten</Button>
                                <Button variant="danger"  className={'float-end'} onClick={(e) => props.loadedDelete(props.response[r])}>Löschen</Button>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted">
                            {(props.response[r].info !== undefined && props.response[r].info !== null && props.response[r].info !== '' ? props.response[r].info : '')}</Card.Footer>
                    </Card>
                </Col>
                </>
            )
        }

    }

    return(
        <>
            <Row>
                {bcard}
            </Row>

        </>
    )
}

export const BusinessCardCustomer = (props:{response:any,AddedFunc:any,Edit:any,loadedDelete:any,sendEdit:any,dataColOne:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute?:any}) => {

    let bcard = [];

    for(let r=1;r<props.response.length;r++) {
        if(props.response[r].firma){
            bcard.push(
                <><Col className={'col-3 mb-3'} >
                    <Card>
                        <Card.Header>{(props.response[r].geschaeftsfuehrer !== null && props.response[r].geschaeftsfuehrer !== '' ? props.response[r].geschaeftsfuehrer.replace('{PERSON 0}','') : 'Kein geschaeftsfuehrer angegeben')}</Card.Header>
                        <Card.Body>
                            <Card.Title style={{height: '80px'}}>{(props.response[r].firma !== null && props.response[r].firma !== '' ? props.response[r].firma : 'Kein Firma angegeben')}</Card.Title>
                            <Card.Text style={{height: '110px'}}>
                                <p>{props.response[r].address.strasse !== null && props.response[r].address.strasse !== '' ? props.response[r].address.strasse : 'Keine strasse angegeben'}<br/>{props.response[r].address.plz !== null && props.response[r].address.plz !== '' ? props.response[r].address.plz : 'Kein geschlecht angegeben'} {props.response[r].address.Ortsteil !== null && props.response[r].address.Ortsteil !== '' ? props.response[r].address.Ortsteil : 'Kein Ortsteil angegeben'}<br/> Ansprechparter<br/> <strong>{props.response[r].ansprechparter !== null && props.response[r].ansprechparter !== '' ? props.response[r].ansprechparter : 'Kein Ansprechparter angegeben'}</strong></p>

                            </Card.Text>
                            <Button variant="primary" onClick={(e) => props.AddedFunc(props.Edit(props.response[r],props.sendEdit,props.dataColOne,props.successForm,props.errorForm,props.setFetchData,props.navigate,props.editRoute))}>Bearbeiten</Button>
                            <Button variant="danger"  className={'float-end'} onClick={(e) => props.loadedDelete(props.response[r])}>Löschen</Button>
                        </Card.Body>
                        <Card.Footer className="text-muted">{(props.response[r].bemerkungen !== undefined && props.response[r].bemerkungen !== null && props.response[r].info !== '' ? props.response[r].bemerkungen : 'Keine Bemerkungen angegeben')}</Card.Footer>
                    </Card>
                </Col>
                </>
            )
        }

    }

    return(
        <>
            <Row>
                {bcard}
            </Row>

        </>
    )
}

export const BusinessCardProdukt = (props:{response:any,AddedFunc:any,Edit:any,loadedDelete:any,sendEdit:any,dataColOne:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute?:any}) => {

    let bcard = [];

    for(let r=1;r<props.response.length;r++) {
        if(props.response[r].bezeichnung){
            bcard.push(
                <><Col className={'col-3 mb-3'} >
                    <Card>
                        <Card.Header>{(props.response[r].intbez !== null && props.response[r].intbez !== '' ? props.response[r].intbez.replace('{PERSON 0}','') : 'Kein intbez angegeben')}</Card.Header>
                        <Card.Body>
                            <Card.Title style={{height: '50px'}}>{(props.response[r].bezeichnung !== null && props.response[r].bezeichnung !== '' ? props.response[r].bezeichnung : 'Kein bezeichnung angegeben')}</Card.Title>
                            <Card.Text style={{height: '110px'}}>
                                <p>
                                    <strong>Artikel.-Nr:</strong> {props.response[r].artikelnummer !== null && props.response[r].artikelnummer !== '' ? props.response[r].artikelnummer : 'Keine artikelnummer angegeben'}<br/>
                                    <strong>Type:</strong> {props.response[r].type !== null && props.response[r].type !== '' ? props.response[r].type : 'Keine type angegeben'}<br/>
                                    <strong>Kürzel:</strong> {props.response[r].kuerzel !== null && props.response[r].kuerzel !== '' ? props.response[r].kuerzel : 'Keine kuerzel angegeben'}<br/>
                                    <strong>Einzeplpreis-Vorgabe:</strong> {props.response[r].preisjeeinheit !== null && props.response[r].preisjeeinheit !== '' ? props.response[r].preisjeeinheit : 'Keine preisjeeinheit angegeben'}<br/>
                                   </p>

                            </Card.Text>
                            <Button variant="primary" onClick={(e) => props.AddedFunc(props.Edit(props.response[r],props.sendEdit,props.dataColOne,props.successForm,props.errorForm,props.setFetchData,props.navigate,props.editRoute))}>Bearbeiten</Button>
                            <Button variant="danger"  className={'float-end'} onClick={(e) => props.loadedDelete(props.response[r])}>Löschen</Button>
                        </Card.Body>
                        <Card.Footer className="text-muted">{(props.response[r].leistungsbeschreibung !== undefined && props.response[r].leistungsbeschreibung !== null && props.response[r].leistungsbeschreibung !== '' ? props.response[r].leistungsbeschreibung : 'Keine Leistungsbeschreibung angegeben')}</Card.Footer>
                    </Card>
                </Col>
                </>
            )
        }

    }

    return(
        <>
            <Row>
                {bcard}
            </Row>

        </>
    )
}
