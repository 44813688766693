
const errorForm = (error:any,close:any,mode?:any,modeID?:any)=> {
    if(mode !== undefined && mode !== null) {
        if(modeID !== undefined && modeID !== null) {
            close(mode,modeID)
        }else{
            close(mode)
        }
    } else {
        close()
    }
}

export default errorForm;
