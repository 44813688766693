import React from "react";
import PeopleOverview from "./Pages/overview";
import {
    initialStatePeopledMenuConfig,
    initialStatePeoplePagesConfig, PeopledMenuConfigInterface,
    PeoplePagesConfigInterface
} from "../interface/People.interface";
import {LanguageSwitcherValue} from "../../../../@WUM/core/component/core/components/LanguageSwitcher";
import PeopleNew from "./Pages/new";
import DetailPeople from "./Pages/detail";
import Peopledeleted from "./Pages/deleted";
import {DashbaordMenuConfig} from "../../dashboard/modules";
import {PhoneMenuConfig} from "../../telephone/modules";
import {PeopledMenuConfig} from "../../people/modules";
import {CustomerMenuConfig} from "../../customer/modules";
import {ProductMenuConfig} from "../../products/modules";
import {ProjekteMenuConfig} from "../../projekte/modules";
import {OrderMenuConfig} from "../../order/modules";
import DetailPreview from "../../billing/modules/Pages/detailPreview";

export const InvoicesPagesConfig = (props: PeoplePagesConfigInterface = initialStatePeoplePagesConfig) => {


    return [
        {
            path: '/invoices/overview/:page',
            elements: <PeopleOverview value={props.value}/>,
            roles: ['superadmin','admin']

        },
        {
            path: '/invoices/new/:page',
            elements: <PeopleNew value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/invoices/detail/:id',
            elements: <DetailPeople value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/invoices/delete/:deleteID',
            elements: <Peopledeleted value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/invoices/preview/:page',
            elements: <DetailPreview value={props.value}/>,
            roles: ['superadmin','admin']
        }
    ];
}

export const InvoicesMenuConfig = (props: PeopledMenuConfigInterface = initialStatePeopledMenuConfig) => {
    return [
        {
            name: 'Rechnungen',
            route: '/invoices/overview/1',
            icon: 'payments',
            roles: ['superadmin','admin']
        },
    ]
}

export const BillingMenuConfig = (value:any) => {
    return [
        {
            header: 'Rechnungen',
            icon: 'payments',
            roles: ['superadmin','admin'],
            menuitem: [
                {
                    name: 'Überblick',
                    route: '/invoices/overview/1',
                    icon: 'payments',
                    roles: ['superadmin','admin']
                },
            ]
        },
    ];
}


