import {ResponsefetchAllCustomers} from "../../CMS/offer/API/response/people";

// interface propsSetCustomer {
//     customer: any
//     companyName: string;
//     companyOwner: string;
//     companyAddressValue: string;
//     companyCityValue: string;
//     companyZip: string;
//     companyNameValue: string;
//     customerNumber: string;
// }

    const setCustomer = (data: any, source: any) => {

        // console.log(data)

    let companyNameValue = (document.getElementById('companyName') as HTMLInputElement)
    let companyAddressValue = (document.getElementById('companyAddress') as HTMLInputElement)
    let companyCityValue = (document.getElementById('companyCity') as HTMLInputElement)
    let customerNumber = (document.getElementById('customerNumber') as HTMLInputElement)
    let companyOwner = (document.getElementById('companyOwner') as HTMLInputElement)

        for(let p=0;p<source.length;p++) {

            // console.log(source[p].id+' == ' + data)
            // console.log(source[p])
            if(source[p].value == data) {
                console.log(source[p])
                companyNameValue.value = source[p].companyName
                companyOwner.value = source[p].companyOwner.trimStart()
                companyAddressValue.value = source[p].companyAddressValue
                companyCityValue.value = source[p].companyZip + ' ' +  source[p].companyCityValue
                customerNumber.value = source[p].value

            }}
                // if (props.customer.target.value === '1') {
    //     companyNameValue.value = 'WUM Solution UG (haftungsbeschränkt)'
    //     companyOwner.value = 'Marcel Ugurcu'
    //     companyAddressValue.value = 'Wurster Landstraße 16'
    //     companyCityValue.value = '27607 Geestland'
    //     customerNumber.value = '00001'
    // } else if (props.customer.target.value === '2') {
    //     companyNameValue.value = 'IMPULS Consulting EWIV'
    //     companyOwner.value = 'Holger Fröhner'
    //     companyAddressValue.value = 'Franckestraße 1'
    //     companyCityValue.value = '06110 Halle Saale'
    //     customerNumber.value = '00002'
    // } else {
    //     companyNameValue.value = ''
    //     companyOwner.value = ''
    //     companyAddressValue.value = ''
    //     companyCityValue.value = ''
    //     customerNumber.value = ''
    // }




}

export default setCustomer


