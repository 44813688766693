import {Image, Text, View} from "@react-pdf/renderer";
import {PdfDateDetails as styles} from "./css/PdfDateDetails.css";
import React from "react";
import {CheckValue} from "../../functions/checkValue";
import {dateFormat} from "../../../../@WUM/core/component/const/dateFormat";
import {TodayGerman} from "../today";
import DocumentTitle from "./DocumentTitle/index";
import {DocumentStatus} from "./interfaces/DocumentStatus";
import {DocumentType} from "./interfaces/Documenttypes";
import {PeriodOfPerformance} from "./DocumentInformation/PeriodOfPerformance";
import {DocumentInfo} from "./DocumentInformation/DocumentInfo";


interface PdfDateDetailsInterface {
    layout: any
    Data: any
    type: DocumentType
    Store: any
    status?: DocumentStatus
}

export const PdfDateDetails = (props:PdfDateDetailsInterface) => {
    const customerNumber = props.Data.customerNumber
    const offerUntil = dateFormat(
        props.Data.offerValid,
        'de',
        'us',
        false,
        false,
        props.Store
    )
    const offerPaymentAim = dateFormat(
        props.Data.offerPaymentAim,
        'de',
        'us',
        false,
        false,
        props.Store
    )
    const billingPaymentAim = dateFormat(
        props.Data.billingPaymentAim,
        'de',
        'us',
        false,
        false,
        props.Store
    )





    return(
        <>
            <View>
                <View style={[styles.phoneNumber]}>
                    <Text>
                        Telefon: {props.layout.phone}
                    </Text>
                </View>
                <View style={[styles.infoBlock]}>

                    {/*sets headline*/}
                    <View>
                        <DocumentTitle type={props.type} status={props.status}/>
                    </View>

                    {/*sets document number (e.g. Rechnungsnummer 123*/}
                    {
                        props.type === 'offer' ? (
                            <DocumentInfo type={props.type} Data={props.Data} Store={props.Store} label={"Angebotsnummer:"} mode={"number"} status={props.status}/>
                        ) : (
                            <DocumentInfo type={props.type} Data={props.Data} Store={props.Store} label={"Rechnungsnummer: "} mode={"number"} status={props.status}/>
                        )
                    }
                    {/*sets invoice data or offer date*/}
                    {
                        props.type === 'offer' ? (
                            <DocumentInfo type={props.type} Data={props.Data} Store={props.Store} label={"Angebotsdatum:"} mode={"date"} status={props.status}/>
                        ) : (
                            <DocumentInfo type={props.type} Data={props.Data} Store={props.Store} label={"Rechnungsdatum: "} mode={"date"} status={props.status}/>
                        )
                    }

                    <View style={[styles.data]}>
                        <Text>
                            KD-Nr:
                        </Text>
                        <Text>
                            {customerNumber}
                        </Text>
                    </View>

                    {/*sets period of performance or stays empty for offer*/}
                    <PeriodOfPerformance type={props.type} Data={props.Data} Store={props.Store}/>

                    {/*sets payment aim or expiration date*/}
                    {
                        props.type === 'invoice' ? (
                            <View style={[styles.data]}>
                                <Text>
                                    Zahlungsziel:
                                </Text>
                                <Text>
                                    {CheckValue(billingPaymentAim)}
                                </Text>
                            </View>
                        ) : props.type === 'offer' ? (
                            <View style={[styles.data]}>
                                <Text>
                                    Gültig bis:
                                </Text>
                                <Text>
                                    {CheckValue(offerUntil)}
                                </Text>
                            </View>
                        ) : (
                            <></>
                        )
                    }

                    {/*<View style={[styles.data]}>
                                    <Text>
                                        Zahlungsziel:
                                    </Text>
                                    <Text>
                                        {CheckValue(offerPaymentAim)}
                                    </Text>
                                </View>*/}
                    <View style={styles.data}>
                        <Text>
                            Seite:
                        </Text>
                        <Text render={({pageNumber, totalPages}) => (
                            `${pageNumber} von ${totalPages}`
                        )}/>
                    </View>
                </View>
            </View>
        </>
    )
}

