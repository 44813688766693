import React from 'react';
import PhoneOverview from "./Pages/overview";
import PhoneNew from './Pages/new';
import DetailPhone from "./Pages/detail";
import Phonedeleted from "./Pages/deleted";
import {
    initialStatePeopledMenuConfig,
    initialStatePeoplePagesConfig,
    PeopledMenuConfigInterface,
    PeoplePagesConfigInterface
} from "../../products/interface/People.interface";

export const PhonePagesConfig = (props: PeoplePagesConfigInterface = initialStatePeoplePagesConfig) => {

    return [
        {
            path: '/phone/overview/:page',
            elements: <PhoneOverview value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/phone/new/:page',
            elements: <PhoneNew value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/phone/detail/:id',
            elements: <DetailPhone value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/phone/delete/:deleteID',
            elements: <Phonedeleted value={props.value}/>,
            roles: ['superadmin','admin']
        },
    ];
}

export const PhoneMenuConfig = (props: PeopledMenuConfigInterface = initialStatePeopledMenuConfig) => {
    return [
            {
                name: 'Telefon',
                route: '/phone/overview/1',
                icon: 'call',
                roles: ['superadmin','admin']
            },
        ]
}
