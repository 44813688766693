import {requests} from '../../../../../@WUM/core/RestFullApi/api';
import {peopleApiDetailType, peopleApiType} from "./peopleApiType";

export const people = {
    getPosts: (limit:any,searchValue:any): Promise<peopleApiType[]>=> requests.get(`pv/zahlungsart/All/${limit}/${searchValue}`),
    getAPost: (id: string): Promise<peopleApiType[]> => requests.get(`pv/zahlungsart/${id}/`),
    createPost: (put: any,id:number): Promise<peopleApiDetailType> =>
        requests.put(`pv/zahlungsart/${id}/`, put),
    updatePost: (post: any, id: number): Promise<peopleApiDetailType> =>
        requests.post(`pv/zahlungsart/${id}/`, JSON.stringify(post)),
    deletePost: (id: string): Promise<void> => requests.delete(`pv/zahlungsart/${id}/`)
}
