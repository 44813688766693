import {CMSMenuConfig} from "../modules/CMS";
import {ShowDialog} from "../@WUM/core/sidebar/sidebar";
import {itSupportSidebar, TicketMenuConfig} from "../modules/ticketsystem/modules";
import {SettingsMenuConfig} from "../modules/settings";
import {checkLocation} from "../@WUM/core/sidebar/ItemMenu";
import {BillingMenuConfig} from "../modules/CMS/billing/modules";
import {QuoteMenuConfig} from "../modules/CMS/offer/modules";


export const RegisterSitemap = (value: any) => {
    return [
        ...CMSMenuConfig(value),
        ...BillingMenuConfig(value),
        ...QuoteMenuConfig(value),
        ...SettingsMenuConfig(value),
        ...Media(value),
        ...TicketMenuConfig(value)


    ]

}

const Media = (value:any) => [
    {
        header: 'Media',
        icon: 'perm_media',

        roles: ['superadmin','admin'],
        menuitem: [
            {
                name: 'Galerie',
                route: '/',
                icon: 'collections',
                onClick:ShowDialog,
                roles: ['superadmin','admin']
            },
        ]
    }]

export const Submenu = (index:any,active:any) => {

    if(active === '') {
        active = window.location.pathname
    }

    let identifier;

    return false;

}


export const switchOpen = (identifier:any,active:any) => {
    let check = [];
    for(let x=0;x<identifier.length;x++) {
        if(checkLocation(identifier[x].split('/')[1],active)===true) {
            check.push('true');
        } else {
            check.push('false');
        }
    }
    if(check.includes('true') === true){
        return true;
    } else {
        return false;
    }
}
