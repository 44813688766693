import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import {Cards} from "../../../../../@WUM/core/Elements/index";
import MergeTwoObjecttoArray from "../../../../../@WUM/core/component/const/MergeTwoObjecttoArray";
import {ShowDialog} from "../../../../../@WUM/core/sidebar/sidebar";

const DashbaordOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)


    let dashboard = [];
    let conf = configruation(Store);
    for(let x=0;x<conf.length;x++){
        if(conf[x].onClick !== undefined) {
            dashboard.push(
                <>
                    <Col lg={2}><Cards title={conf[x].title}
                                       href={conf[x].href}
                                       icon={conf[x].icon}
                                       undertitle={conf[x].undertitle}
                                       text={conf[x].text} Store={Store}
                                       onClick={conf[x].onClick}/></Col>
                </>
            )
        } else {
            dashboard.push(
                <>
                    <Col lg={2}><Cards title={conf[x].title}
                                       href={conf[x].href}
                                       icon={conf[x].icon}
                                       undertitle={conf[x].undertitle}
                                       text={conf[x].text} Store={Store}/></Col>
                </>
            )
        }
    }

    return (<>
        <Row className={'DashbaordRow text-center'}>
            {dashboard}
        </Row>
    </>)
}

export default DashbaordOverview;

const configruation = (Store:any) => {

    let config: any = [];
    config =  MergeTwoObjecttoArray(config,pv())


    return config;



}



const pv = () => [
    {
        title: 'Dashboard',
        href: '/ticket/overview/1',
        undertitle:'Dashboard',
        icon: 'dashboard',
        text: 'Mustertext'
    },
    {
        title: 'Telefon',
        href: '/phone/overview/1',
        undertitle:'Telefon',
        icon: 'call',
        text: 'Mustertext'
    },
    {
        title: 'Personen',
        href: '/people/overview/1',
        undertitle:'Personen',
        icon: 'groups',
        text: 'Mustertext'
    },
    {
        title: 'Kunden',
        href: '/customer/overview/1',
        undertitle:'Kunden',
        icon: 'support_agent',
        text: 'Mustertext'
    },
    {
        title: 'Kundenakquise',
        href: '/Blog/1',
        undertitle:'Kundenakquise',
        icon: 'connect_without_contact',
        text: 'Mustertext'
    },
    {
        title: 'Außendienst',
        href: '/Pages/overview/1',
        undertitle:'Außendienst',
        icon: 'contact_emergency',
        text: 'Mustertext'
    },
    {
        title: 'Kalender',
        href: 'Produkte & Leistungen',
        undertitle:'Kalender',
        icon: 'calendar_month',
        text: 'Mustertext'
},
    {
        title: 'Produkte & Leistungen',
        href: '/product/overview/1',
        undertitle:'Produkte & Leistungen',
        icon: 'production_quantity_limits',
        text: 'Mustertext'
    },
    {
        title: 'Projekte',
        href: '/project/overview/1',
        undertitle:'Projekte',
        icon: 'travel_explore',
        text: 'Mustertext'
    },
    {
        title: 'Aufträge',
        href: '/order/overview/1',
        undertitle:'Aufträge',
        icon: 'shopping_bag',
        text: 'Mustertext'
    },
    {
        title: 'Rechnungen',
        href: '/invoices/overview/1',
        undertitle:'Rechnungen',
        icon: 'payments',
        text: 'Mustertext'
    },
    {
        title: 'Provision',
        href: '/analytics/visitor',
        undertitle:'Abrechnungen',
        icon: 'price_change',
        text: 'Mustertext'
    },
    {
        title: 'Referenzdaten',
        href: '/analytics/behave',
        undertitle:'Referenzdaten',
        icon: 'score',
        text: 'Mustertext'
    },
    {
        title: 'Berichte (alt)',
        href: '/analytics/acquisition',
        undertitle:'Berichte',
        icon: 'forward_to_inbox',
        text: 'Mustertext'
    },
    {
        title: 'Tools',
        href: '/analytics/acquisition',
        undertitle:'Tools',
        icon: 'handyman',
        text: 'Mustertext'
    },
    {
        title: 'Benutzerverwaltung (alt)',
        href: '/analytics/acquisition',
        undertitle:'Benutzerverwaltung',
        icon: 'handyman',
        text: 'Mustertext'
    },
    {
        title: 'Price Type',
        href: '/priceType/overview/1',
        undertitle:'Price',
        icon: 'score',
        text: 'Mustertext'
    },
    {
        title: 'MwSt Type',
        href: '/mwstType/overview/1',
        undertitle:'MwSt',
        icon: 'account_balance',
        text: 'Mustertext'
    },
    {
        title: 'SZR Type',
        href: '/szrType/overview/1',
        undertitle:'Zahlungsrhytmus',
        icon: 'sync',
        text: 'Mustertext'
    },
    {
        title: 'Zahlungsart Type',
        href: '/zahlungsartype/overview/1',
        undertitle:'Zahlungsart',
        icon: 'payment',
        text: 'Mustertext'
    }
];

