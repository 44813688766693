

export interface configPeople {
  id: number;
  title: string;
  name: string;
  vorname: string;
  zusatz: string;
  source: string;
  geschlecht: number;
}

export function outputPeople(Data: any) {
  let back = {
    id: Data.id,
    title: Data.title,
    name: Data.name,
    vorname: Data.vorname,
    zusatz: Data.zusatz,
    source: Data.source,
    geschlecht: Data.geschlecht
  }

  return back;
}
