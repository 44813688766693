import IntlMessage, {IntlMessageValue} from '../../../../../@WUM/core/component/const/IntlMessage';
import React from "react";

function configuration(conf: string,response:any,newElement:any = false,Store:any) {

  const dataColOne = [
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'id',
      'value': (newElement === false ? response.id : '0'),
    },
    {
      'formType': 'header',
      'type': 'h2',
      'value': 'Basisinfomation',
    },
    {
      'formType': 'input',
      'label': 'Projektname',
      'type': 'text',
      'name': 'project[name]',
      'value': (newElement === false ? response.project.name : ''),
    },
    {
      'formType': 'input',
      'label': 'Schlagworte',
      'type': 'text',
      'name': 'meta[0][lvalue]',
      'value': (newElement === false ? response.metas[0].lvalue : ''),
    },
    {
      'formType': 'header',
      'type': 'h2',
      'value': 'Domains',
    },
    {
      'formType': 'select',
      'label': 'VSBG-Einstellung',
      'br': true,
      'name': 'project´[vsbg]',
      'selected': (newElement === false ? response.project.vsbg : 0),
      'options': [
        {
          'label': 'keine Angabe',
          'value': '0'
        },
        {
          'label': 'bis 10, nimmt nicht teil',
          'value': '1'
        },
        {
          'label': 'über 10, nimmt nicht teil',
          'value': '2'
        },
        {
          'label': 'nimmt teil',
          'value': '3'
        }]

    },
  ];

    return dataColOne;

}

export default configuration;
