import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import React from "react";
import header from "../../../../../@WUM/core/form-serilizer/elements/header";

const dataColOneCutomer = (newElement:any,response:any) =>  {
    return [
        {
            'formType': 'input',
            'type': 'hidden',
            'name': 'id',
            'value': (newElement === false ? response.id : '0'),
        },
        {
          formType: 'header',
          value:'Adresse'
        },
        {
            'formType': 'input',
            'label': 'id',
            'type': 'hidden',
            'name': 'address[id]',
            'value': (newElement === false ? response.address.id : '0'),

        },
        {
            'formType': 'input',
            'label': 'id',
            'type': 'hidden',
            'name': 'address[location][id]',
            'value': (newElement === false ? response.address.location.id : '0'),

        },
        {
            'formType': 'input',
            'label': 'Strasse',
            'type': 'text',
            'name': 'address[strasse]',
            'placeholder': 'Bitte Straße eingeben',
            'value': (newElement === false ? response.address.strasse : ''),

        },
        {
            'formType': 'input',
            'label': 'PLZ',
            'type': 'text',
            'name': 'address[location][PLZ]',
            'placeholder': 'Bitte PLZ eingeben',
            'value': (newElement === false ? response.address.location.PLZ : ''),
        },
        {
            'formType': 'input',
            'label': 'Ort',
            'type': 'text',
            'name': 'address[location][Ort]',
            'placeholder': 'Bitte Ort eingeben',
            'value': (newElement === false ? response.address.location.Ort : ''),
        },
        {
            formType: 'header',
            value:'Allgemeines'
        },
        {
            'formType': 'input',
            'label': 'Firma',
            'type': 'text',
            'name': 'firma',
            'placeholder': 'Bitte Firma eingeben',
            'value': (newElement === false ? response.firma : ''),
        },
        {
            'formType': 'input',
            'label': 'GF',
            'type': 'text',
            'name': 'geschaeftsfuehrer',
            'placeholder': 'Bitte GF eingeben',
            'value': (newElement === false ? response.geschaeftsfuehrer : ''),
        },
        {
            'formType': 'input',
            'label': 'Ansprechparter',
            'type': 'text',
            'required': true,
            'name': 'ansprechparter',
            'value': (newElement === false ? response.ansprechparter : ''),
        },
        {
            'formType': 'input',
            'label': 'Bemerkungen',
            'type': 'text',
            'required': true,
            'name': 'bemerkungen',
            'value': (newElement === false ? response.bemerkungen : ''),
        },
    ];
}

export default dataColOneCutomer;
