import IntlMessage, {IntlMessageValue} from '../../../../../@WUM/core/component/const/IntlMessage';
import React from "react";

function configuration(conf: string,response:any,newElement:any = false,Store:any) {

  const dataColOne = [
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'id',
      'value': (newElement === false ? response.id : '0'),
    },
    {
      'formType': 'input',
      'label': 'Beschreibung',
      'type': 'text',
      'name': 'beschreibung',
      'value': (newElement === false ? response.beschreibung : ''),
    },
    {
      'formType': 'input',
      'label': 'Bezeichnung',
      'type': 'text',
      'name': 'bez',
      'value': (newElement === false ? response.bez : ''),
    },

    {
      'formType': 'input',
      'label': 'Schlusssatz',
      'type': 'text',
      'name': 'schlusssatz',
      'value': (newElement === false ? response.schlusssatz : ''),
    },

  ];

    return dataColOne;

}

export default configuration;
