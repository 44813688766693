import {DashbaordMenuConfig, DashbaordPagesConfig} from "./dashboard/modules";
import {PeopledMenuConfig, PeoplePagesConfig} from "./people/modules";
import {CustomerMenuConfig, CustomerPagesConfig} from "./customer/modules";
import {ProductMenuConfig, ProductPagesConfig} from "./products/modules";
import {PhoneMenuConfig, PhonePagesConfig} from "./telephone/modules";
import {ProjekteMenuConfig, ProjektePagesConfig} from "./projekte/modules";
import {OrderMenuConfig, OrderPagesConfig} from "./order/modules";
import {InvoicesMenuConfig, InvoicesPagesConfig} from "./billing/modules";
import {OfferMenuConfig, OfferPagesConfig} from "./offer/modules";


export const CMSRouteConfig = (value:any) => {
    return [
        ...DashbaordPagesConfig(value),
        ...PhonePagesConfig(value),
        ...PeoplePagesConfig(value),
        ...CustomerPagesConfig(value),
        ...ProductPagesConfig(value),
        ...ProjektePagesConfig(value),
        ...OrderPagesConfig(value),
        ...InvoicesPagesConfig(value),
        ...OfferPagesConfig(value)
    ]
}

export const CMSMenuConfig = (value:any) => {
        return [
            {
                header: 'Projektverwaltung',
                icon: 'home',
                roles: ['superadmin','admin'],
                menuitem: [
                    ...DashbaordMenuConfig(value),
                    ...PhoneMenuConfig(value),
                    ...PeopledMenuConfig(value),
                    ...CustomerMenuConfig(value),
                    ...ProductMenuConfig(value),
                    ...ProjekteMenuConfig(value),
                    ...OrderMenuConfig(value)
                ]
            },
        ];
    }

