import {
    UniRow,
    UniButton,
    UniCol,
    UniForm,
    IntlMessageValue,
    UniModal, IntlMessage
} from "../../../../../@WUM/core/Elements";
import {UniModalHeader} from "../../../../../@WUM/core/Elements/Modal/Elements/UniModalHeader";
import {UniModalFooter} from "../../../../../@WUM/core/Elements/Modal/Elements/UniModalFooter";
import React from "react";
import {useSelector} from "react-redux";
import MIcon from "../../../../../@WUM/core/Elements/MIcon";


const CustomerDetailModal = () => {
    const Store = useSelector((state: any) => state)
    return (
        <>
                <UniModal
                    className={"modal-mw-95"}
                    Button={{
                    Text: <MIcon newMaterial={true} children={'person_add'} variant={'fill'} className={'py-0'}/>,
                    Variant: 'primary',
                    Class: '',
                    Type: "button"
                }}>
                    <UniModalHeader elements={'Kundendetails'}/>
                    <UniModal.Body
                        elements={
                            <UniForm validated={false} Submit={(e) => alert('')}>
                                <UniRow class={"mb-3"}>
                                    <UniCol lg={4}>
                                        <div className={"border border-dark p-1 mb-2"}>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'billing.customer', Store)}/>
                                                <UniForm.FormControl name={'customerCompany'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.ceo', Store)}/>
                                                <UniForm.FormControl name={'customerCEO'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.contact', Store)} class={"mb-2"}/>
                                                <UniForm.FormControl name={'customerContact'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                        </div>
                                        <div className={"border border-dark p-1"}>
                                            <h5><IntlMessage Store={Store} messageId="offer.modal.address"/></h5>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.search.address', Store)}/>
                                                <UniForm.Select items={[
                                                    {value: 'example', text: 'Auguststr. 71'},
                                                    {value: 'example2', text: 'Wurster Landstraße'}
                                                ]}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.street', Store)}/>
                                                <UniForm.FormControl name={'customerAddressStreet'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.zip', Store)}/>
                                                <UniForm.FormControl name={'customerAddressZip'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.location', Store)}/>
                                                <UniForm.FormControl name={'customerAddressLocation'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.state', Store)}/>
                                                <UniForm.FormControl name={'customerAddressState'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.country', Store)}  class={"mb-2"}/>
                                                <UniForm.FormControl name={'customerAddressCountry'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>

                                        </div>
                                    </UniCol>
                                    <UniCol lg={4}>
                                        <div className={"border border-dark p-1 mb-2"}>
                                            <h5><IntlMessage Store={Store} messageId="offer.modal.legal"/></h5>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.vat', Store)}/>
                                                <UniForm.FormControl name={'customerVatId'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.register.court', Store)}/>
                                                <UniForm.FormControl name={'customerRegisterCourt'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.register.department', Store)}/>
                                                <UniForm.FormControl name={'customerRegisterDepartment'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.hr', Store)}
                                                    class={"mb-2"}/>
                                                <UniForm.FormControl name={'customerHr'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                        </div>
                                        <div className={"border border-dark p-1"}>
                                        <h5><IntlMessage Store={Store} messageId="offer.modal.contact.data"/></h5>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.phone', Store)}/>
                                                <UniForm.FormControl name={'customerPhone'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.mobile', Store)}/>
                                                <UniForm.FormControl name={'customerMobile'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.fax', Store)}/>
                                                <UniForm.FormControl name={'customerFax'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.email', Store)}/>
                                                <UniForm.FormControl name={'customerEmail'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.invoice.email', Store)}
                                                    class={"mb-2"}/>
                                                <UniForm.FormControl name={'customerInvoiceEmail'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>


                                        </div>
                                    </UniCol>
                                    <UniCol lg={4}>
                                        <div className={"border border-dark p-1 mb-2"}>
                                            <h5><IntlMessage Store={Store} messageId="offer.modal.account"/></h5>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.account.user', Store)}/>
                                                <UniForm.FormControl name={'customerVatId'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.iban', Store)}/>
                                                <UniForm.FormControl name={'customerRegisterCourt'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup class={"mb-0 h-100"}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'offer.modal.bic', Store)}
                                                    class={"mb-2"}/>
                                                <UniForm.FormControl name={'customerHr'} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                        </div>
                                        <div className={"border border-dark p-1"}>
                                            <h5><IntlMessage Store={Store} messageId="offer.modal.comment"/></h5>
                                            <UniForm.FormControl
                                                name={'customerComment'}
                                                required={false}
                                                type={'text'}
                                                as="textarea" rows={12}
                                            />


                                        </div>
                                    </UniCol>
                                </UniRow>
                            </UniForm>
                        }/>

                    <UniModalFooter elements={''}/>
                </UniModal>
        </>
    )
}

export default CustomerDetailModal