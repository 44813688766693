import {FormControl, InputGroup} from "react-bootstrap";
import setTotalPrice from "../functions/setTotalPrice";
import {IntlMessage, UniButton, UniCol, UniForm, UniRow} from "../../../@WUM/core/Elements";
import React, {useRef} from "react";
import {ResponsefetchAllProducts} from "../../CMS/offer/API/response/people";
import ProductDetailModal from "../../CMS/offer/modules/elements/productDetailModal";
import {SetProductInfo} from "../functions/setProductInfo"
import {INTLNumberFormat} from "../functions/IntlNumberFormat";
import MIcon from "../../../@WUM/core/Elements/MIcon";
import {CheckLocalisSet} from "../../CMS/billing/modules/Pages/new";

interface productTableProps {
    tableid: number,
    Store:any,
    MwST:any,
    fetchAllProducts: any,
    isCollapsed: any,
    type?: string
    setFetchData:any
    new?:any
    LS?:any
}




const ProductTable = (props:productTableProps) => {

    const tableid = props.tableid
    const tableIDRef = useRef(tableid)
    let ProductHolder:any = [];
    ProductHolder.push(
        {value: '', text: 'Bitte wählen'}
    )

    for(let p=0;p<props.fetchAllProducts.length;p++) {
        ProductHolder.push(
            {
                value: props.fetchAllProducts[p].id ,
                text: props.fetchAllProducts[p].bezeichnung +' '+ INTLNumberFormat.format(props.fetchAllProducts[p].preisjeeinheit)},
        )
    }

    const DeletePosition = (id:any) => {
        const rowToDelete = document.getElementById(id+'tr') as HTMLTableRowElement;
        if (rowToDelete) {
            rowToDelete.parentNode?.removeChild(rowToDelete);
        } else {
            console.error(id+'tr');
        }
    }

    return(
        <>
            <td className={"non-max-width"}>{tableIDRef.current}
                {props.new !== undefined ?
                <>
                    <UniButton class={"m-2"} type={"button"} size={'sm'} variant={'danger'} onClick={() => DeletePosition(tableIDRef.current)}>
                        <MIcon newMaterial={true} children={'delete'} variant={'fill'} className={'py-0'}/>
                    </UniButton>

                </>
                :<></>
                }
            </td>
            <td>
                <FormControl
                    name={'offer[' + (tableIDRef.current - 1) + '][invoiceArticleNumber]'}
                    id={'invoiceArticleNumber' + tableIDRef.current}
                    required={true}
                    defaultValue={CheckLocalisSet(props.LS,'offer[' + (tableIDRef.current - 1) + '][invoiceArticleNumber]')}
                    type={'string'}
                />
            </td>
            <td>

                <UniRow class={"mb-3"}>
                    <UniCol class={props.type !== "invoice" ? "pe-0" : ""}>
                        <UniForm.Select name={'offer[' + (tableIDRef.current - 1) + '][invoiceProduct]'} required={true}
                                        items={ProductHolder}
                                        class={'h-100'}
                                        defaultValue={CheckLocalisSet(props.LS,'offer[' + (tableIDRef.current - 1) + '][invoiceProduct]')}
                                        onChange={(e) =>
                                            SetProductInfo({
                                                e: e,
                                                fetchAllProducts: props.fetchAllProducts,
                                                MwST: props.MwST,
                                                tableIdRef: tableIDRef.current
                                            })
                                        }
                        />
                    </UniCol>
                    {props.type !== "invoice" ? <UniCol xs={"auto"} class={""}>
                        <ProductDetailModal Store={props.Store} setFetchData={props.setFetchData}/>
                    </UniCol> : <></>}
                </UniRow>
                <FormControl
                    name={'offer[' + (tableIDRef.current - 1) + '][invoiceDescription]'}
                    id={'invoiceDescription'+ tableIDRef.current}
                    defaultValue={CheckLocalisSet(props.LS,'offer[' + (tableIDRef.current - 1) + '][invoiceDescription]')}
                    required={true}
                    type={'text'}
                    as="textarea" rows={3}
                />
            </td>
            <td>
                <FormControl
                    name={'offer[' + (tableIDRef.current - 1) + '][invoiceAmount]'}
                    id={'invoiceAmount'+ tableIDRef.current}
                    required={true}
                    type={'number'}
                    defaultValue={CheckLocalisSet(props.LS,'offer[' + (tableIDRef.current - 1) + '][invoiceAmount]')}
                    onChange={(e) => {
                        setTotalPrice({
                            tableIdRef: tableIDRef.current
                        })
                }}
                />
            </td>
            <td>
                <InputGroup className={"pe-0 ms-auto"}>
                    <FormControl
                        name={'offer[' + (tableIDRef.current - 1) + '][invoiceDiscount]'}
                        required={true}
                        type={'number'}

                        id={'invoiceDiscount'+ tableIDRef.current}
                        onChange={(e) => {
                            setTotalPrice({
                                tableIdRef: tableIDRef.current
                            })
                        }}
                        defaultValue={CheckLocalisSet(props.LS,'offer[' + (tableIDRef.current - 1) + '][invoiceDiscount]') !== '' ? CheckLocalisSet(props.LS,'offer[' + (tableIDRef.current - 1) + '][invoiceDiscount]') : 0}
                    />
                    <UniForm.InputGroupText text={"%"}/>
                </InputGroup>
            </td>
            <td>
                <FormControl
                    name={'offer[' + (tableIDRef.current - 1) + '][productUnit]'}
                    required={true}
                    type={'string'}
                    id={'productUnit'+ tableIDRef.current}
                    defaultValue={CheckLocalisSet(props.LS,'offer[' + (tableIDRef.current - 1) + '][productUnit]')}
                />

            </td>
            <td>
                <InputGroup className={"pe-0 ms-auto"}>
                    <FormControl
                        name={'offer[' + (tableIDRef.current - 1) + '][invoicePrice]'}
                        required={true}
                        type={'number'}
                        id={'invoicePrice'+ tableIDRef.current}
                        className={"text-end"}
                        readOnly={true}
                        defaultValue={CheckLocalisSet(props.LS,'offer[' + (tableIDRef.current - 1) + '][invoicePrice]')}
                    />
                    <UniForm.InputGroupText
                        text='€'/>
                </InputGroup>

            </td>
            <td>
                <InputGroup className={"pe-0 ms-auto"}>
                    <FormControl
                        name={'offer[' + (tableIDRef.current - 1) + '][invoiceMWST]'}
                        required={true}
                        type={'number'}
                        id={'invoiceMWST'+ tableIDRef.current}
                        className={"text-end p-0"}
                        readOnly={true}
                        defaultValue={CheckLocalisSet(props.LS,'offer[' + (tableIDRef.current - 1) + '][invoiceMWST]')}
                    />
                    <UniForm.InputGroupText text={"%"}/>
                </InputGroup>
            </td>
            <td>
                <InputGroup className={"pe-0 ms-auto"}>
                    <FormControl
                        name={'offer[' + (tableIDRef.current - 1) + '][invoiceTotal]'}
                        id={'invoiceTotal'+ tableIDRef.current}
                        required={true}
                        type={'string'}
                        className={"text-end"}
                        readOnly={true}
                        defaultValue={CheckLocalisSet(props.LS,'offer[' + (tableIDRef.current - 1) + '][invoiceTotal]')}
                    />
                    <UniForm.InputGroupText
                        text='€'/>
                </InputGroup>
            </td>
        </>
    )
}

export default ProductTable