const Configuration = (namespace_search:any,destination:any) => {

    return [{
            namespace: namespace_search,
            destination: destination,
            input: [
                {id: 'name', translation: 'Name', param: 'name', method: 'like'},
                {id: 'location', translation: 'Standort', param: 'location', method: 'like'},
                {id: 'email', translation: 'Email', param: 'email', method: 'like'},
                {id: 'info', translation: 'Info', param: 'info', method: 'like'},
            ],
            button: ['common.search', 'common.del.search', 'Neuen Telefonkontakt anlegen']
        }]
}
export default Configuration;
