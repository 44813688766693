import {useParams} from "react-router-dom";
import {initialStatePeopleOverview, PeopleInterface} from "../../interface/People.interface";
import {ResponseDeletephone} from "../../API/response/phone";


const Phonedeleted = (props:PeopleInterface = initialStatePeopleOverview) => {

    const {deleteID} = useParams<{deleteID: string}>();
    if(deleteID !== undefined){
        ResponseDeletephone(deleteID);
        setTimeout(() => window.location.href = '/phone/overview/1', 3000);
    }
    return(<></>)

}

export default Phonedeleted;
