import { getDocumentTitle} from "./getDocumentTitle";
import { Text } from "@react-pdf/renderer";
import { DocumentTitleProps } from "./DocumentTitle.interface"
import { PdfDateDetails as styles } from "../css/PdfDateDetails.css";

/**
 * React functional component that renders the document title.
 *
 * @param {Props} props - The component props containing document type and status information.
 *
 * @returns {JSX.Element} A JSX element representing the document title as a `Text` component.
 */
const DocumentTitle: React.FC<DocumentTitleProps> = ({ type, status }) => {
    return <Text style={[styles.headline]}>{getDocumentTitle({ type, status })}</Text>;
};

export default DocumentTitle;