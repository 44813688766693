import {initialStatePeopleOverview, PeopleInterface} from "../../interface/People.interface";
import {
    Responsebuytype,
    ResponseDeletePeople, ResponsefetchAllProducts,
    ResponseMwST,
    ResponsePeopleOverview,
    Responsepricetype, Responseservicetimetype
} from "../../API/response/people";
import PielersTable from '../../../../../@WUM/core/tablemaker';
import {useNavigate,useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useState} from "react";
import {IntlMessage, IntlMessageValue, SpinnerJSX, SearchJSX} from "../../../../../@WUM/core/Elements/index";
import {handleSucces} from '../../../../../@WUM/core/Function/index';
import PaginationJSX from '../../../../../@WUM/core/form-serilizer/elements/pagination';
import {useLocalStorage} from "../../../../../@WUM/core/Function/index";
import Button from "react-bootstrap/Button";
import {DynamikOffCanvaspv} from "../../../../../@WUM/core/component/const/DynamikModal";
import PielersForm from "../../../../../@WUM/core/form-serilizer";
import dataColOneMwSTType from "../../../../settings/MwSTType/modules/configuration/dataColOne";
import handlesubmit from "../../../telephone/modules/configuration/handlesubmit";
import {BusinessCardMwStType, BusinessCardProdukt} from "../../../telephone/helper/BusinessCard";
import Edit from "../../../telephone/helper/Edit";
import AddedFunc from "../../../telephone/helper/AddedFunc";
import sendEdit from "../../../telephone/helper/sendEdit";
import successForm from "../../../telephone/helper/successForm";
import errorForm from "../../../telephone/helper/errorForm";
import Delete from "../../../telephone/helper/Delete";
import sendDeleted from "../../../telephone/helper/sendDeleted";
import successDeleted from "../../../telephone/helper/succesDeleted";
import AddDialogdynamik from "../../../telephone/helper/AddDialogdynamik";
import dataColOneProduct from "../configuration/dataColOne";
/**
 *
 * @param props
 * @constructor
 */
const PeopleOverview = (props:PeopleInterface = initialStatePeopleOverview) => {
    const Store = useSelector((state: any) => state)

    const {page} = useParams<{page: string}>();
    const history = useNavigate();
    const namespace_search = "Product_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    let limit: any;
    const destination = 'product';

    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }

    const [fetchData, setFetchData] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [makeDelete, setMakeDelete] = useState(null);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const CloseDynmaikModal = () => { handleClose(); }
    const saveRoute = 'pv/prodcut/[param]/'
    const deletedRoute = 'pv/prodcut/[param]/'
    const editRoute = 'pv/prodcut/[param]/'

    const successFormDynamik = (result:any) => {
        setFetchData(Date.now())
        handleClose() }

    const errorFormDynamik = (error:any)=> {
        handleClose()
    }

    let response = ResponsePeopleOverview(limit, searchValue,fetchData);

    const loadedDelete = (data:any) => {setMakeDelete(data);handleShowDelete(); }
    let MwST = ResponseMwST()
    let buytype = Responsebuytype()
    let pricetype = Responsepricetype()
    let servicetimetype = Responseservicetimetype()
    let fetchAllProducts = ResponsefetchAllProducts();


    const Configuration = {
        'SearchConfig': [{
            namespace: namespace_search,
            destination: destination,
            input: [
                {id: 'firstname', translation: 'common.firstname', param: 'vorname', method: 'like'},
                {id: 'lastname', translation: 'common.lastname', param: 'name', method: 'like'},
            ],
            button: ['common.search', 'common.del.search', 'Neue Produkt anlegen']
        }],
        'table': {
            'tableBody': {
                'data': response,
                'dataID': 'id',
                'underdata': true,
                'keys': ['id','intbez', 'bezeichnung', 'artikelnummer','kuerzel', 'leistungsbeschreibung', 'einzelpreisvorgabe', 'status','mwsttyp','preistyp'],
                'type': ['string','string', 'string', 'string', 'string','string', 'euro', 'status','fetchLocal|MwST|Text','fetchLocal|pricetype|text|8'],
                'position':['left','left','left','left','left','center','left','left','left','left']
            },
            'tableHeader': [
                'ID',
                'Interne Bezeichnung',
                'Bezeichnung',
                'Artikel-Nr.',
                'Kürzel',
                'Leistungsbeschreibung',
                'Einzeplpreis-Vorgabe',
                'Status',
                'MwSt-Typ',
                'PreisTyp'],
            'tableHeaderWidth': ['5%','15%','15%%','5%','5%','20%','10%','10%','10%','10%'],
            'tableHeaderposition': ['left','left','left','left','left','center','left','left','left','left'],
            'tablecolor': ['light'],
            'menu': {
                'data': response,
                'menuDisable': false,
                'menutriggerDelete': true,
                'menuItem': [<IntlMessage Store={Store} messageId="common.edit"/>,
                    <IntlMessage Store={Store} messageId="common.delete"/>],
                'menuroute': ['/'+destination+'/Detail', '/'+destination+'/delete'],
                'menuParams': ['', 'id', 'id'],
                'menuIcons': ['pencil', 'Delete'],
            },
        }
    };
    if (response !== undefined && response.length > 0
        && MwST !== undefined && MwST.length > 0
        && buytype !== undefined && buytype.length > 0
        && pricetype !== undefined && pricetype.length > 0
        && servicetimetype !== undefined && servicetimetype.length > 0
        && fetchAllProducts !== undefined && fetchAllProducts.length > 0) {
    return(
        <>
            <div className={'site'}>
                <SearchJSX setSearchValue={setSearchValue} Store={Store}
                           SearchConfig={Configuration['SearchConfig']}
                           indiButton={<Button variant="primary" className={'float-end'} onClick={handleShow}>
                                   <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                                        add
                                    </span>
                               Produkt anlegen
                           </Button>}
                />
                <br style={{clear: 'both'}}/>
                <DynamikOffCanvaspv openButtonLabel={<></>}
                                    saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                                    closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                                    SaveID={'newphone'}
                                    title={'Produkt anlegen'}
                                    saveDisabled={false}
                                    ClassOpenButton={'float-end'} indiButton={''}
                                    ClassSaveButton={'float-end'}
                                    body={<>{PielersForm(dataColOneProduct(true,response))}</>}
                                    handlesubmit={handlesubmit}
                                    errorForm={errorFormDynamik}
                                    successForm={successFormDynamik}
                                    setFetchData={setFetchData}
                                    handleShow={handleShow}
                                    saveRoute={saveRoute}
                                    show={show}
                                    setShow={setShow}
                                    handleClose={CloseDynmaikModal}
                />
                <br style={{clear: 'both'}}/>
                <BusinessCardProdukt response={response}
                                      Edit={Edit}
                                      AddedFunc={AddedFunc}
                                      loadedDelete={loadedDelete}
                                      sendEdit={sendEdit}
                                      successForm={successForm}
                                      errorForm={errorForm}
                                      editRoute={editRoute}
                                      setFetchData={setFetchData}
                                      navigate={navigate}
                                      dataColOne={dataColOneProduct} />
                <Delete sendDeleted={sendDeleted}
                        makeDelete={makeDelete}
                        successDeleted={successDeleted}
                        handleCloseDelete={handleCloseDelete}
                        deletedRoute={deletedRoute}
                        FieldMakeDelete={['intbez','id']}
                        setFetchData={setFetchData}
                        showDelete={showDelete} />
                <AddDialogdynamik />
                {/*<PielersTable Configuration={Configuration['table']}/>*/}
                <PaginationJSX response={response} history={history} to={'overview'}
                               handleSuccess={handleSucces} destination={destination}/>
            </div>
        </>
    )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default PeopleOverview;
