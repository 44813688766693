import {ResponseDeletePeople} from "../../API/response/people";
import {useParams} from "react-router-dom";
import {initialStatePeopleOverview, PeopleInterface} from "../../interface/People.interface";


const Peopledeleted = (props:PeopleInterface = initialStatePeopleOverview) => {

    const {deleteID} = useParams<{deleteID: string}>();
    if(deleteID !== undefined){
        ResponseDeletePeople(deleteID);
        setTimeout(() => window.location.href = '/project/overview/1', 3000);
    }
    return(<></>)

}

export default Peopledeleted;
