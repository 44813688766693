import React from "react";

const AddDialogdynamik = () => {

    return(
        <>
        <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
             id="addDialogdynamik" style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
            <div className="offcanvas-body">
                <div id="injectModalTask"></div>
            </div>
        </div>
        </>
    )
}

export default AddDialogdynamik;
