import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {getToken} from "../../../../../@WUM/core/component/const/getToken";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";

const handlesubmit = (e:any,successFormDynamik:any,errorFormDynamik:any,saveRoute:any) => {
    console.log('drin')
    const form = e.currentTarget
    e.preventDefault()

    let Data = init2(form)
    console.log(Data)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+ getToken());

    var raw = JSON.stringify(Data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
    };

    let route = saveRoute.replace('[param]','0')

    fetch(ApiURL() +""+route, requestOptions)
        .then(response => response.text())
        .then(result => successFormDynamik(result))
        .catch(error => errorFormDynamik(error));
};

export default handlesubmit;
