import React from 'react';
import {Page, Text, View, Document} from '@react-pdf/renderer';
import {styles} from './css/offerPdf.css'
import FontsRegister from "../../../../../../PDFFonts/FontsRegister";
import {InvoicePDFProps} from "./interface/OfferPDFProps.interface";
import {Layout} from "./interface/Layout.interface";
import {Data} from "./interface/Data.interface"
import {PdfCompanyInfo} from "../../../../../components/Elements/pdf/PdfCompanyInfo";
import {PdfDateDetails} from "../../../../../components/Elements/pdf/PdfDateDetails";
import {PdfProductTable, PdfProductTableHeader} from "../../../../../components/Elements/pdf/PdfProductTable";
import {PdfPricesOverview} from "../../../../../components/Elements/pdf/PdfPriceOverview";
import {PdfTextArea} from "../../../../../components/Elements/pdf/PdfTextArea";
import {PdfFooter} from "../../../../../components/Elements/pdf/PdfFooter";
import {PdfDraftSvg} from "../../../../../components/Elements/pdf/PdfDraftSvg";
import {CheckNull} from "../../Pages/new";
import {CheckSingleMwst} from "../../../../../components/functions/CheckSingleMwst";
import {PdfFooter2} from "../../../../../components/Elements/pdf/PdfFooter2";
import {formatNumberToDigits} from "../../../../../../@WUM/core/Function";

const options = {
    style: 'currency', // Währungsstil
    currency: 'EUR', // Währung (z. B. 'EUR', 'USD', 'CHF')
    minimumFractionDigits: 2, // Minimale Anzahl der Dezimalstellen
    maximumFractionDigits: 2, // Maximale Anzahl der Dezimalstellen
};
export const formatter = new Intl.NumberFormat('de-DE', options); // Formatierer für Deutsch (Deutschland)

// Create Document Component
const InvoicePdf = (props: InvoicePDFProps) => {

    FontsRegister(); //adds fonts

    let Data: Data = JSON.parse(localStorage.getItem('invoice') as string)

    const layout:Layout = {
        logo: props.layout.logo,
        phone: props.layout.phone,
        address: props.layout.address,
        ownCompany: props.layout.ownCompany,
        plz: props.layout.plz,
        bankName: props.layout.bankName,
        IBAN: props.layout.IBAN,
        BIC: props.layout.BIC,
        web: props.layout.web,
        mail: props.layout.mail,
        city: props.layout.city,
        register: props.layout.register,
        taxNumber: props.layout.taxNumber,
        taxId: props.layout.taxId,
        ceo: props.layout.ceo,
        courtLoc: props.layout.courtLoc,
        type: props.layout.type
    }
    if(props.mode === 'detail') {

        let Offer = []



        let netto = 0
        let mwst =0
        let brutto = 0;
        if(props.data.positions !== undefined) {
            for(let pos = 0;pos < props.data.positions.length;pos++) {
                //console.log(props.data.Subject ?? '' + "test")
                netto = (props.data.positions[pos].article.einzelpreisvorgabe * props.data.positions[pos].menge *  (100-props.data.positions[pos].rabatt)/100)
                let total:any = (props.data.positions[pos].article.einzelpreisvorgabe * props.data.positions[pos].menge * (100-props.data.positions[pos].rabatt)/100).toFixed(2)
                mwst = mwst  + (total/100*(props.fetchAllMwST?.find((item: { id: number; }) => item.id === parseInt(props.data.positions[pos].article.mwsttyp))?.standartwert))
                Offer.push(
                    {
                        invoiceAmount: props.data.positions[pos].menge,
                        invoiceProduct: props.data.positions[pos].article.id,
                        invoiceDescription: props.data.positions[pos].article.text, //TODO aktuell nicht enthalten
                        invoiceDiscount: props.data.positions[pos].rabatt,
                        invoicePrice: props.data.positions[pos].article.einzelpreisvorgabe,
                        invoiceMWST: props.fetchAllMwST?.find((item: { id: number; }) => item.id === parseInt(props.data.positions[pos].article.mwsttyp))?.standartwert,
                        invoiceArticleNumber: props.data.positions[pos].article.artikelnummer,
                        invoiceTotal: total,
                        productUnit: props.data.positions[pos].article.einheit
                    }
                )
            }
        }
        let bruttoTotal
        let mwstTotal
        bruttoTotal = props.data.netto * 1.19;
        mwstTotal = props.data.netto * 0.19

        Data  = {
            companyName: props.data.recipient.firma,
            companyAddress:  props.data.recipient.address.strasse,
            companyCity:  props.data.recipient.address.location.PLZ + ' '+ props.data.recipient.address.location.Ort ,
            companyOwner:  props.data.recipient.geschaeftsfuehrer.trim(),
            invoiceNumber:  formatNumberToDigits(props.data.nr),
            customerNumber:  formatNumberToDigits(props.data.recipient.id),
            invoiceDate:  props.data.date,
            copyInvoiceNumber: 'Rechnung ' + props.data.nr,
            billingSubject:  props.data.Subject,
            billingIntro: props.data.intro,
            invoicePriceNetto:  formatter.format(props.data.netto),
            invoiceVat:  formatter.format(mwstTotal),
            invoicePriceBrutto:  formatter.format(bruttoTotal),
            billingComment:  props.data.comment,
            invoicePaymentType:  CheckNull(props.data.zahlungsart,'id'),
            closingSentence:  CheckNull(props.data.zahlungsart,'schlusssatz'),
            offer: Offer,
            invoicePerfStart: '',
            invoicePerfEnd: '',
            billingPaymentAim: '',
            billingExtraComment: ''
        }

    }


    const subject = Data.billingSubject;
    const pretext = Data.billingIntro;

    const paymentMethodValue = Data.invoicePaymentType;

    //const paymentMethod = props.PaymentMethod.find((item: { id: number; }) => item.id === parseInt(paymentMethodValue)).title;
    const paymentMethod = props.PaymentMethod?.find((item: { id: number; }) => item.id === parseInt(paymentMethodValue))?.title;

    let offer = Data.offer;
    const allMWSTSame = CheckSingleMwst({ offer }) === true; // Call CheckSingleMwst and store the result

    let NoInvoicePer = <></>

    if(Data.invoicePerfStart === '' &&
    Data.invoicePerfEnd === '') {

        NoInvoicePer =  <Text style={styles.text}>
            Leistungszeitraum entspricht dem Rechnungsdatum
        </Text>
    }

    console.log(subject)


    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    style={[styles.row, {marginTop: -30}]}
                    fixed={true}
                >

                    <View
                        style={styles.columnsHeader}
                    >
                        <PdfCompanyInfo Data={Data} layout={layout}/>
                        <PdfDateDetails layout={layout} Data={Data} type={"invoice"} Store={props.Store} status={props.status} />
                    </View>
                </View>

                <Text style={styles.subject}>
                    {(Data.billingSubject !== null && Data.billingSubject !== undefined && Data.billingSubject !== '' ?
                    <>{subject}</> :
                    <></>)}
                </Text>
                <View style={styles.marginBottomStandard}>
                    <Text style={styles.text}>
                        {pretext}
                    </Text>
                </View>


                <View style={styles.table}>
                    {/*{BuildOfferTable()}*/}
                    <PdfProductTableHeader allMWSTSame={allMWSTSame}/>
                    <PdfProductTable fetchAllProducts={props.fetchAllProducts} Data={Data} allMWSTSame={allMWSTSame}/>
                </View>

                <PdfPricesOverview Data={Data} type={"invoice"}/>
                <PdfTextArea Data={Data} type={"invoice"} Store={props.Store} PaymentMethod={props.PaymentMethod}/>

                {NoInvoicePer}

                <PdfFooter2 layout={layout}/>
{/*
                <Image
                       src="/pdf-images/formb.png"
                       style={{
                           width: '595',
                           height: '842',
                           position: "absolute",
                           left: 0,
                           top: 0,
                           marginTop: -20,
                       }}/>
*/}
                <PdfDraftSvg status={props.status}/>

            </Page>
        </Document>
    )
};
export default InvoicePdf


//const CheckValue = (value:any) => {
//    return (value !== null && value !== undefined && value !== '' ? value : '')
//}

