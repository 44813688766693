import {initialStatePeopleOverview, PeopleInterface} from "../../interface/People.interface";
import {
    Responsebuytype,
    ResponseDeletePeople, ResponsefetchAllProducts,
    ResponseMwST,
    ResponsePeopleOverview,
    Responsepricetype, Responseservicetimetype
} from "../../API/response/people";
import PielersTable from '../../../../../@WUM/core/tablemaker';
import {useNavigate,useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useState} from "react";
import PaginationJSX from '../../../../../@WUM/core/form-serilizer/elements/pagination';
import {SpinnerJSX, SearchJSX, IntlMessage} from '../../../../../@WUM/core/Elements/index';
import {useLocalStorage, handleSucces} from "../../../../../@WUM/core/Function/index";
/**
 *
 * @param props
 * @constructor
 */
const PeopleOverview = (props:PeopleInterface = initialStatePeopleOverview) => {
    const Store = useSelector((state: any) => state)

    const {page} = useParams<{page: string}>();
    const history = useNavigate();
    const namespace_search = "order_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    let limit: any;
    const destination = 'order';

    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }


    let response = ResponsePeopleOverview(limit, searchValue);
    let MwST = ResponseMwST()
    let buytype = Responsebuytype()
    let pricetype = Responsepricetype()
    let servicetimetype = Responseservicetimetype()
    let fetchAllProducts = ResponsefetchAllProducts();


    const Configuration = {
        'SearchConfig': [{
            namespace: namespace_search,
            destination: destination,
            input: [
                {id: 'firstname', translation: 'common.firstname', param: 'vorname', method: 'like'},
                {id: 'lastname', translation: 'common.lastname', param: 'name', method: 'like'},
            ],
            button: ['common.search', 'common.del.search', 'Neue Produkt anlegen']
        }],
        'table': {
            'tableBody': {
                'data': response,
                'dataID': 'id',
                'underdata': true,
                'keys': ['id','date', 'client', 'orderVolume', 'paymentMethod'],
                'type': ['string','string', 'string', 'string', 'string'],
                'position':['left','left','left','left','left']
            },
            'tableHeader': [
                'ID',
                'Datum',
                'Auftraggeber',
                'Auftragssumme',
                'Zahlungsart'
                ],
            'tableHeaderWidth': ['5%','15%','15%%','5%','5%'],
            'tableHeaderposition': ['left','left','left','left','left'],
            'tablecolor': ['light'],
            'menu': {
                'data': response,
                'menuDisable': false,
                'menutriggerDelete': true,
                'menuItem': [<IntlMessage Store={Store} messageId="common.edit"/>,
                    <IntlMessage Store={Store} messageId="common.delete"/>],
                'menuroute': ['/'+destination+'/Detail', '/'+destination+'/delete'],
                'menuParams': ['', 'id', 'id'],
                'menuIcons': ['pencil', 'Delete'],
            },
        }
    };
    if (response !== undefined && response.length > 0
        && MwST !== undefined && MwST.length > 0
        && buytype !== undefined && buytype.length > 0
        && pricetype !== undefined && pricetype.length > 0
        && servicetimetype !== undefined && servicetimetype.length > 0
        && fetchAllProducts !== undefined && fetchAllProducts.length > 0) {
    return(
        <>
            <div className={'site'}>
                <SearchJSX setSearchValue={setSearchValue} Store={Store}
                           SearchConfig={Configuration['SearchConfig']}/>
                <br style={{clear: 'both'}}/>
                <PielersTable Configuration={Configuration['table']}/>
                <PaginationJSX response={response} history={history} to={'overview'}
                               handleSuccess={handleSucces} destination={destination}/>
            </div>
        </>
    )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default PeopleOverview;
