import {Rect, Svg, Text} from "@react-pdf/renderer";
import React from "react";

interface PdfDraftSvgInterface {
    status?: "draft" | "" | "final" | undefined | string;
}
export const PdfDraftSvg = (props:PdfDraftSvgInterface) => {
    return (
        <>
            {props.status !== 'final' ?
                <Svg viewBox="0 0 0 0" style={{position: "absolute", top: -40, left: -20}}>
                    <Rect
                        width="50"
                        height="500"
                        fill="#015b91"
                        transform="rotate(45 0 0)"
                        x="120"
                        y="-200"
                        opacity="0.8"
                    />
                    <Text x="-20" y="150" fill="#ffffff" text-anchor="middle" transform="rotate(-45 0 0)">
                        Entwurf
                    </Text>
                </Svg>:
                <></>}
        </>

    )
}
