import {initialStatePeopleNew, PeopleNewInterface} from "../../interface/People.interface";
import React, {useState} from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import {useSelector} from "react-redux";
import {customer} from "../../API/request/people";
import {peopleApiDetailType} from "../../API/request/peopleApiType";
import updateData from "../../../../../@WUM/core/component/core/components/updateData";
import TabsConfig from "../configuration/Tabs.config";
import FormCMS from "../../../../../@WUM/core/component/core/components/Form.cms";
import {useLocalStorage} from "../../../../../@WUM/core/Function/index";

const PeopleNew = (props:PeopleNewInterface=initialStatePeopleNew) => {

    const history = useNavigate();
    const Store = useSelector((state: any) => state)
    const [currentTutorial, setCurrentTutorial] = useState<peopleApiDetailType[]>([]);
    const [validated, setValidated] = useState(false);
    const [element, setElement] = React.useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    const [seo_title, setseo_title] = useLocalStorage("seo_title", "");
    const [seo_description, setseo_description] = useLocalStorage("seo_description", "");
    const [deleteElement, setdeleteElement] = React.useState(0);
    const [Index, setIndex] = React.useState(0);


    return(
        <>
            <div>
                <FormCMS history={history} element={element} setElement={setElement} id={'0'} Index={Index} setIndex={setIndex} currentTutorial={currentTutorial}
                         modalData={modalData} modalShow={modalShow} setModalShow={setModalShow} setModalData={setModalData} seo_title={seo_title}
                         setseo_title={setseo_title} setseo_description={setseo_description} seo_description={seo_description} response2={'new'}
                         part={'customer'} setdeleteElement={setdeleteElement} setValidated={setValidated} validated={validated} NowRoute={customer}
                         setCurrentTutorial={setCurrentTutorial} updateData={updateData} config={TabsConfig} Store={Store} nonPreview={true} request={'/customer/overview/1'}
                         isTab={true} />
            </div>
        </>
    )
}

export default PeopleNew;
