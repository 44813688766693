import {initialStatePeopleNew, PeopleNewInterface} from "../../interface/People.interface";
import React, {useState, useRef, useEffect} from 'react';
import {useSelector} from "react-redux";
import Table from 'react-bootstrap/Table';
import {
    SpinnerJSX,
    UniRow,
    UniButton,
    UniModal,
    UniCol,
    UniForm,
    IntlMessageValue, IntlMessage
} from "../../../../../@WUM/core/Elements/index";
import {
    Responsebuytype,
    ResponsefetchAllCustomers,
    ResponsefetchAllProducts,
    ResponseMwST,
    Responsepricetype,
    Responseservicetimetype
} from "../../API/response/people";
import UniChecks from "../../../../../@WUM/core/Elements/Form/Elements/Check";
import {UniModalHeader} from "../../../../../@WUM/core/Elements/Modal/Elements/UniModalHeader";
import {UniModalFooter} from "../../../../../@WUM/core/Elements/Modal/Elements/UniModalFooter";
import DigitalSignature, {SignaturePad} from 'react-digital-signature'
import CustomerDetailModal from "../elements/customerDetailModal";
import PaymentModalDetail from "../elements/paymentModal";
import {useNavigate} from "react-router-dom";
import setCustomer from "../../../../components/functions/setCustomer";
import {AddPosition} from "../../../../components/functions/addPosition";
import ProductTable from "../../../../components/Elements/productTable";
import {HandleSidebarCollapse} from "../../../../components/functions/CollapseSidebarIfSmall";
import Form from "react-bootstrap/Form";
import { FormControl } from "react-bootstrap";
import ToggleButton from "react-bootstrap/ToggleButton";
import {SetProduktHolder} from "../../../billing/modules/Pages/new";
import { ApiRoot } from "../../../../../@WUM/core/RestFullApi/api";


const PeopleNew = (props: PeopleNewInterface = initialStatePeopleNew) => {

    const [fetchData, setFetchData] = useState(0);
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [selectedValuePaymentType, setSelectedValuePaymentType] = useState('');
    const [selectedValuePaymentMethod, setSelectedValuePaymentMethod] = useState('');

    const storedData = localStorage.getItem('offer');
    const LSParse = JSON.parse(storedData as string)
    const jsonData = storedData ? JSON.parse(storedData) : {};

    const [checkedValuesAgb, setCheckedValuesAgb] = useState<string[]>([]);
    const [checkedValuesDataSafety, setCheckedValuesDataSafety] = useState<string[]>([]);

    useEffect(() => {

        // Extract default values for checkboxes (handle potential absence)
        const defaultCheckedAgb = jsonData['acceptAgb'] === 'on' ? ['agb'] : [];
        const defaultCheckedDataSafety = jsonData['acceptDataProtect'] === 'on' ? ['dataSafety'] : [];

        // Set initial state with defaults or empty arrays (using function-based update)
        setCheckedValuesAgb((prevState) => defaultCheckedAgb);
        setCheckedValuesDataSafety((prevState) => defaultCheckedDataSafety);
    }, []);

    const handleCheckboxChangeAgb = (event: { target: { checked: any; }; }) => {
        const newCheckedValuesAgb = [...checkedValuesAgb]; // Copy to avoid mutation
        if (event.target.checked) {
            newCheckedValuesAgb.push('agb');
        } else {
            const index = newCheckedValuesAgb.indexOf('agb');
            newCheckedValuesAgb.splice(index, 1);
        }
        setCheckedValuesAgb(newCheckedValuesAgb);
    };

    const handleCheckboxChangeDataSafety = (event: { target: { checked: any; }; }) => {
        const newCheckedValuesDataSafety = [...checkedValuesDataSafety]; // Copy to avoid mutation
        if (event.target.checked) {
            newCheckedValuesDataSafety.push('dataSafety');
        } else {
            const index = newCheckedValuesDataSafety.indexOf('dataSafety');
            newCheckedValuesDataSafety.splice(index, 1);
        }
        setCheckedValuesDataSafety(newCheckedValuesDataSafety);
    };



    const { HandleSidebarCollapse:any } = HandleSidebarCollapse({
        menuCollapse,
        setMenuCollapse,
    });

    // set a table to collapse or not using bootstrap
    const [isCollapsed, setIsCollapsed] = useState(true);

    const [validated, setValidated] = useState(false);
    const Store = useSelector((state: any) => state)
    const ref = useRef<SignaturePad>()
    const refs = useRef<SignaturePad>()
    const handleCLear = (e: any) => {
        e.preventDefault()
        /* @ts-ignore */
        ref.current?.clear()

        let signatureText = document.getElementById('clientSignature') as HTMLSelectElement;
        if (signatureText !== null && signatureText !== undefined) {
            signatureText.value = ''
        }

    }

    const handleCLears = (e: any) => {
        e.preventDefault()
        /* @ts-ignore */
        ref.current?.clear()
        let signatureTexts = document.getElementById('contractorSignature') as HTMLSelectElement;
        if (signatureTexts !== null && signatureTexts !== undefined) {
            signatureTexts.value = ''
        }
    }
    let MwST = ResponseMwST()
    let buytype = Responsebuytype(fetchData)
    let pricetype = Responsepricetype()
    let servicetimetype = Responseservicetimetype(fetchData)
    let fetchAllProducts = ResponsefetchAllProducts();
    let fetchAllCustomers = ResponsefetchAllCustomers();
    const [tableid, setTableid] = useState(2)

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/offer/preview/1`;
        navigate(path);
    }

    const trim = () => {
        let signatureText = document.getElementById('clientSignature') as HTMLSelectElement;
        if (signatureText !== null && signatureText !== undefined) {
            signatureText.value = (ref.current?.toDataURL() !== undefined ? ref.current?.toDataURL() : '')
        }
    }

    const trims = () => {
        let signatureTexts = document.getElementById('contractorSignature') as HTMLSelectElement;
        if (signatureTexts !== null && signatureTexts !== undefined) {
            signatureTexts.value = (refs.current?.toDataURL() !== undefined ? refs.current?.toDataURL() : '')
        }
    }

    if (MwST !== undefined && MwST.length > 0
        && buytype !== undefined && buytype.length > 0
        && pricetype !== undefined && pricetype.length > 0
        && servicetimetype !== undefined && servicetimetype.length > 0
        && fetchAllProducts !== undefined && fetchAllProducts.length > 0) {

        let ProductHolder: any = [];
        ProductHolder.push(
            {value: '', text: IntlMessageValue('de', 'billing.please.choose', Store)}
        )
        for (let p = 0; p < fetchAllProducts.length; p++) {
            ProductHolder.push(
                {value: fetchAllProducts[p].id, text: fetchAllProducts[p].bezeichnung},
            )
        }

        const setOfferNumber = (e: any) => {
            let offerNumber = (document.getElementById('offerNumber') as HTMLInputElement)
            let offerNumberCopy = (document.getElementById('copyOfferNumber') as HTMLInputElement)
            offerNumberCopy.value = `Angebot ` + offerNumber.value
        }


        let customerHolder: any = [];
        for (let c = 0; c < fetchAllCustomers.length; c++) {
            const customer = fetchAllCustomers[c];

            if(customer.address.id !== null && customer.address.id !== undefined && customer.address.id !== 0 && customer.address.id !== '0'){
                customerHolder.push({
                    value: customer.id,
                    text: customer.firma,
                    companyName: customer.firma,
                    companyOwner: customer.geschaeftsfuehrer,
                    companyAddressValue: customer.address?.strasse || "",
                    companyCityValue: customer.address?.location?.Ort || "",
                    companyZip: customer.address?.location?.PLZ || "",
                });
            }
        }

        //console.log(fetchAllCustomers)


        let buytypeHolder: any = [];
        buytypeHolder.push(

        )
        for (let p = 0; p < buytype.length; p++) {
            buytypeHolder.push(
                {value: buytype[p].id, text: buytype[p].bez, closing: buytype[p].schlusssatz}
            )
        }

        let buytypeClosing = [];
        buytypeClosing.push(

        )
        for (let p = 0; p < buytype.length; p++) {
            buytypeClosing.push(
                {value: buytype[p].id, text: buytype[p].schlusssatz}
            )
        }

        /*const setClosingSentence = (paymentType: any) => {

            let closingSentenceValue = (document.getElementById('closingSentence') as HTMLInputElement)
            let trigger = false;
            for (let b = 0; b < buytypeHolder.length; b++) {
                if (parseInt(paymentType.target.value) == buytype[b].id) {
                    closingSentenceValue.value = buytype[b].schlusssatz
                    trigger = true
                }
            }
            if (trigger === false) {
                closingSentenceValue.value = ''
            }
        }*/

        let servicetimetypeHolder = [];
        servicetimetypeHolder.push(

        )
        for (let p = 0; p < servicetimetype.length; p++) {
            servicetimetypeHolder.push(
                {value: servicetimetype[p].id, text: servicetimetype[p].title}
            )
        }

        const send = (e: any) => {


            e.preventDefault()
            let data = e.currentTarget

            var formData = new FormData(data)
            let entries = formData.entries();
            let formDataObject: any = {};

            for (const [key, value] of formData.entries()) {
                formDataObject[key] = value;
            }

            //console.log(formDataObject)

            let datas = MultiFormNameSpace({
                entries: entries,
                delimiter: ['offer', 'tffer'],
                lastPoint: 'invoiceTotal',
                part: 'offer'
            })
            formDataObject['offer'] = datas;
            /*console.log(formDataObject)*/
            localStorage.setItem('offer', JSON.stringify(formDataObject) as string)


            let pos = [];

            for(let f=0;f<formDataObject.offer.length;f++) {
                let PosData = formDataObject.offer[f]
                pos.push(
                    {
                        "posid": f,
                        "artikel": PosData.invoiceArticleNumber,
                        "menge": PosData.invoiceAmount,
                        "rabatt": PosData.invoiceDiscount,
                        "text": PosData.invoiceDescription,
                        "einzelpreis": PosData.invoicePrice,
                        "mwsttyp":PosData.invoiceMWST,
                        "total":PosData.invoiceTotal
                    }
                )
            }

            let raw = JSON.stringify(
                {
                    "id": 0,
                    "art": 3, // art = 1: normal 2: individuelle Position 3: Entwurf
                    "date": formDataObject.offerDate,
                    "valid": formDataObject.offerValid,
                    "paymentAim": formDataObject.offerValid,
                    "intro": formDataObject.offerIntro,
                    "billingComment": formDataObject.billingComment,
                    "comment": formDataObject.offerComment,
                    "paymentLocation": formDataObject.offerPaymentLocation,
                    "paymentSignatureDate": formDataObject.offerPaymentSignatureDate,
                    "clientSignature": formDataObject.clientSignature,
                    "contractorSignature": formDataObject.contractorSignature,
                    "zahlungsartId": formDataObject.offerPaymentType,
                    "kundendataid": formDataObject.customerNumber,
                    "pos": pos
                }
            )

           var requestOptions = {
               method: 'POST',
               headers: {
                   'Content-Type': 'application/json',
                   Authorization:
                       'Bearer ' +
                       JSON.parse(localStorage.getItem('token') as string)
               },
               body: raw
           }

           fetch(ApiRoot()+"pv/offer/0/", requestOptions)
               .then(response => response.json())
               .then(result => {
                   localStorage.setItem('offer', JSON.stringify(formDataObject) as string)
                   localStorage.setItem('OfferData',result)
               })
               .catch(error => console.log('error', error));

        }

        const sendPreview = () => {
            const data = document.getElementById('Form001') as HTMLFormElement;
            const formData = new FormData(data);
            let entries = formData.entries();
            let formDataObject: any = {};

            for (const [key, value] of formData.entries()) {
                formDataObject[key] = value;
            }


            let datas = MultiFormNameSpace({
                entries: entries,
                delimiter: ['offer', 'tffer'],
                lastPoint: 'invoiceTotal',
                part: 'offer'
            })
            formDataObject['offer'] = datas;

            let pos = [];

            for(let f=0;f<formDataObject.offer.length;f++) {
                let PosData = formDataObject.offer[f]
                pos.push(
                    {
                        "posid": f,
                        "artikel": PosData.invoiceArticleNumber,
                        "menge": PosData.invoiceAmount,
                        "rabatt": PosData.invoiceDiscount,
                        "text": PosData.invoiceDescription,
                        "einzelpreis": PosData.invoicePrice,
                        "mwsttyp":PosData.invoiceMWST,
                        "total":PosData.invoiceTotal
                    }
                )
            }

            let raw = JSON.stringify(
                {
                    "id": 0,
                    "art": 3, // art = 1: normal 2: individuelle Position 3: Entwurf
                    "date": formDataObject.offerDate,
                    "valid": formDataObject.offerValid,
                    "paymentAim": formDataObject.offerValid,
                    "intro": formDataObject.offerIntro,
                    "billingComment": formDataObject.billingComment,
                    "comment": formDataObject.offerComment,
                    "paymentLocation": formDataObject.offerPaymentLocation,
                    "paymentSignatureDate": formDataObject.offerPaymentSignatureDate,
                    "clientSignature": formDataObject.clientSignature,
                    "contractorSignature": formDataObject.contractorSignature,
                    "zahlungsartId": formDataObject.offerPaymentType,
                    "kundendataid": formDataObject.customerNumber,
                    "pos": pos
                }
            )

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot()+"pv/offer/0/", requestOptions)
                .then(response => response.text())
                .then(result => {
                    localStorage.setItem('offer', JSON.stringify(formDataObject) as string)
                    localStorage.setItem('OfferData',result)
                    navigate(`/offer/preview/1`)
                })
                .catch(error => console.log('error', error));

        }

        const companyName = jsonData['companyName']
        const companyAddress = jsonData['companyAddress']
        const companyOwner = jsonData['companyOwner']
        const companyCity = jsonData['companyCity']
        const customerNumber = jsonData['customerNumber']
        const offerNumber = jsonData['offerNumber']
        const copyOfferNumber = jsonData['copyOfferNumber']
        const offerDate = jsonData['offerDate']
        const offerValid = jsonData['offerValid']
        const offerSubject = jsonData['offerSubject']
        const offerIntro = jsonData['offerIntro']
        const offerPriceNetto = jsonData['offerPriceNetto']
        const offerVat = jsonData['offerVat']
        const offerPriceBrutto = jsonData['offerPriceBrutto']
        const billingComment = jsonData['billingComment']
        const offerPaymentMethod = jsonData['offerPaymentMethod']
        const offerPaymentType = jsonData['offerPaymentType']
        const offerPaymentLocation = jsonData['offerPaymentLocation']
        const offerPaymentSignatureDate = jsonData['offerPaymentSignatureDate']
        const clientSignature = jsonData['clientSignature']
        const contractorSignature = jsonData['contractorSignature']
        const offerComment = jsonData['offerComment']



        return (
            <>
                <div className={"mx-xxl-5 mx-1"}>
                    <h1><IntlMessage Store={Store} messageId="offer.headline"/></h1>
                    <UniForm id={'Form001'} validated={validated} Submit={(e) => send(e)}>
                        <UniRow class="justify-content-between pb-5">
                            <UniCol xxl={3} xl={4} lg={5}>
                                <UniRow class={"mb-3"}>
                                    <UniCol lg={10}>
                                        <UniForm.InputGroup class={"mb-0 h-100"}>
                                            <UniForm.InputGroupText
                                                text={IntlMessageValue('de', 'billing.customer', Store)}
                                                class={"mb-0"}/>
                                            <UniForm.Select freeValue={true} freeValueLabel={'Bitte Wählen'} onChange={(e) => setCustomer(e.target.value, customerHolder)} items={customerHolder}
                                                            class={"mb-0"}/>
                                        </UniForm.InputGroup>
                                    </UniCol>
                                    <UniCol lg={2} class={"p-0"}>
                                        <CustomerDetailModal/>
                                    </UniCol>
                                </UniRow>
                                <UniForm.FormControl
                                    type={'text'}
                                    id={'companyName'}
                                    name={'companyName'}
                                    placeholder={''}
                                    readOnly={true}
                                    defaultValue={companyName}
                                />
                                <UniForm.FormControl
                                    type={'text'}
                                    id={'companyOwner'}
                                    name={'companyOwner'}
                                    placeholder={''}
                                    readOnly={true}
                                    defaultValue={companyOwner}
                                />
                                <UniForm.FormControl
                                    type={'text'}
                                    id={'companyAddress'}
                                    name={'companyAddress'}
                                    placeholder={''}
                                    readOnly={true}
                                    defaultValue={companyAddress}
                                />
                                <UniForm.FormControl
                                    type={'text'}
                                    id={'companyCity'}
                                    name={'companyCity'}
                                    placeholder={''}
                                    readOnly={true}
                                    defaultValue={companyCity}
                                />
                            </UniCol>
                            <UniCol xxl={4} xl={5} lg={6}>
                                <UniRow>
                                    <UniForm.InputGroup class={"mb-0"}>
                                        <UniForm.InputGroupText text={IntlMessageValue('de', 'offer.number', Store)}/>
                                        <UniForm.FormControl name={'offerNumber'}
                                                             readOnly={true}
                                                             type={'text'}
                                                             id={'offerNumber'}
                                                             onChange={setOfferNumber}
                                                             defaultValue={offerNumber}
                                        />
                                    </UniForm.InputGroup>
                                    <UniForm.InputGroup class={"mb-0"}>
                                        <UniForm.InputGroupText
                                            text={IntlMessageValue('de', 'billing.customer.number', Store)}/>
                                        <UniForm.FormControl name={'customerNumber'}
                                                             required={true}
                                                             type={'text'}
                                                             readOnly={true}
                                                             id={'customerNumber'}
                                                             defaultValue={customerNumber}
                                        />
                                    </UniForm.InputGroup>
                                    <UniForm.InputGroup class={"mb-0"}>
                                        <UniForm.InputGroupText text={IntlMessageValue('de', 'offer.date', Store)}/>
                                        <UniForm.FormControl name={'offerDate'} required={true}
                                                             type={'date'}
                                                             id={'offerDate'}
                                                             defaultValue={offerDate}
                                        />
                                    </UniForm.InputGroup>
                                    <UniForm.InputGroup class={"mb-0"}>
                                        <UniForm.InputGroupText text={IntlMessageValue('de', 'offer.valid', Store)}/>
                                        <UniForm.FormControl name={'offerValid'} required={true}
                                                             type={'date'}
                                                             defaultValue={offerValid}
                                                             id={'offerValid'}
                                        />
                                    </UniForm.InputGroup>
                                    {/*<UniForm.InputGroup class={"mb-0"}>
                                        <UniForm.InputGroupText
                                            text={IntlMessageValue('de', 'billing.payment.aim', Store)}/>
                                        <UniForm.FormControl name={'offerPaymentAim'} required={true}
                                                             type={'date'}
                                                             defaultValue={offerPaymentAim}
                                        />
                                    </UniForm.InputGroup>*/}

                                </UniRow>
                            </UniCol>
                        </UniRow>
                        <UniForm.InputGroup class={"pb-5"}>
                            <UniForm.FormControl name={'copyOfferNumber'}
                                                 required={true}
                                                 type={'text'}
                                                 readOnly={true}
                                                 class={"col-2 h-100 h4"}
                                                 id={'copyOfferNumber'}
                                                 defaultValue={copyOfferNumber}
                            />
                            <UniForm.FormControl name={'offerSubject'}
                                                 required={true}
                                                 type={'text'}
                                                 placeholder={IntlMessageValue('de', 'billing.subject', Store)}
                                                 class={"h4 fw-bolder"}
                                                 defaultValue={offerSubject}
                                                 id={'offerSubject'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup>
                            <UniForm.FormControl name={'offerIntro'}
                                                 required={true}
                                                 type={'textarea'}
                                                 placeholder={IntlMessageValue('de', 'billing.start.text', Store)}
                                                 as="textarea" rows={4}
                                                 defaultValue={offerIntro}
                                                 id={'offerIntro'}
                            />
                        </UniForm.InputGroup>

                        <Table striped bordered hover responsive="xl" id={"offer-table"}>
                            <thead>
                            <tr>
                                <th><IntlMessage Store={Store} messageId="billing.position"/></th>
                                <th className={`col-1`}>
                                    <IntlMessage Store={Store} messageId="billing.article.number"/>
                                </th>

                                <th className={"col-4"}><IntlMessage Store={Store} messageId="billing.product.service"/>
                                </th>
                                <th className={"col-1"}><IntlMessage Store={Store} messageId="billing.number"/></th>
                                <th className={"col-1"}><IntlMessage Store={Store} messageId="billing.discount"/></th>
                                <th className={"col-1"}>
                                    <IntlMessage Store={Store} messageId="billing.unit"/>
                                </th>
                                <th className={"col-1"}><IntlMessage Store={Store} messageId="billing.singleprice"/>
                                </th>
                                <th className={"col-1"}><IntlMessage Store={Store} messageId="billing.vat.price"/></th>
                                <th className={"col-2"}><IntlMessage Store={Store} messageId="billing.sum"/></th>
                            </tr>
                            </thead>
                            <tbody id={"productTable2"}>

                            <SetProduktHolder Data={LSParse} Store={Store} setFetchData={setFetchData} fetchAllProducts={fetchAllProducts} MwST={MwST} isCollapsed={isCollapsed} />

                            </tbody>
                        </Table>
                        <UniRow>
                            <UniCol>
                                <UniButton class={"m-0"} onClick={(e) => AddPosition({
                                    tableid: 1,
                                    Store: Store,
                                    MwST: MwST,
                                    fetchAllProducts: fetchAllProducts,
                                    isCollapsed: isCollapsed,
                                    setFetchData:setFetchData
                                })}
                                           type={"button"}>
                                    <IntlMessage Store={Store} messageId="billing.button.addposition"/>
                                </UniButton>
                            </UniCol>
                            <UniCol xxl={3} lg={5} xl={4}>
                                <UniForm.InputGroup class={"pe-0 ms-auto"}>
                                    <UniForm.InputGroupText
                                        text={IntlMessageValue('de', 'billing.price.netto', Store)}/>
                                    <UniForm.FormControl class={"text-end"}
                                                         name={'offerPriceNetto'}
                                                         required={true}
                                                         type={'string'} id={'offerPriceNetto'}
                                                         readOnly={true}
                                                         defaultValue={offerPriceNetto}
                                    />
                                    <UniForm.InputGroupText text={IntlMessageValue('de', 'billing.euro', Store)}/>
                                </UniForm.InputGroup>
                                <UniForm.InputGroup class={"pe-0 ms-auto"}>
                                    <UniForm.InputGroupText text={IntlMessageValue('de', 'billing.vat', Store)}/>
                                    <UniForm.FormControl class={"text-end"} name={'offerVat'} required={true}
                                                         type={'string'}
                                                         readOnly={true}
                                                         id={'offerVat'}
                                                         defaultValue={offerVat}
                                    />
                                    <UniForm.InputGroupText text={IntlMessageValue('de', 'billing.euro', Store)}/>
                                </UniForm.InputGroup>
                                <UniForm.InputGroup class={"pe-0 ms-auto pb-5"}>
                                    <UniForm.InputGroupText class={"fw-bolder"}
                                                            text={IntlMessageValue('de', 'billing.price.brutto', Store)}/>
                                    <UniForm.FormControl class={"fw-bolder text-end"} name={'offerPriceBrutto'} required={true}
                                                         type={'string'}
                                                         readOnly={true}
                                                         id={'offerPriceBrutto'}
                                                         defaultValue={offerPriceBrutto}
                                    />
                                    <UniForm.InputGroupText class={"fw-bolder text-end"}
                                                            text={IntlMessageValue('de', 'billing.euro', Store)}/>
                                </UniForm.InputGroup>
                            </UniCol>
                        </UniRow>


                        <UniForm.InputGroup>
                            <UniForm.Label class={"col-12"} text={IntlMessageValue('de', 'billing.comment', Store)}/>
                            <UniForm.FormControl name={'billingComment'}
                                                 required={true}
                                                 type={'textarea'}
                                                 as="textarea" rows={4}
                                                 defaultValue={billingComment}
                            />
                        </UniForm.InputGroup>
                        <UniRow>
                            <UniCol lg={5}>
                                <UniForm.InputGroup class={"mb-0"}>
                                    <UniForm.InputGroupText
                                        text={IntlMessageValue('de', 'billing.payment.type', Store)}/>
                                    <UniForm.Select name={'offerPaymentType'} required={true} items={buytypeHolder}
                                                    /*onChange={setClosingSentence}*/
                                                    defaultValue={selectedValuePaymentType || offerPaymentType}
                                                    onChange={(e) => setSelectedValuePaymentType(e.target.value)}
                                    />
                                </UniForm.InputGroup>
                            </UniCol>
                            <UniCol lg={5}>
                                <UniForm.InputGroup class={"mb-0"}>
                                    <UniForm.InputGroupText
                                        text={IntlMessageValue('de', 'billing.payment.method', Store)}/>
                                    <UniForm.Select name={'offerPaymentMethod'} required={true}
                                                    items={servicetimetypeHolder} defaultValue={selectedValuePaymentMethod || offerPaymentMethod}
                                                    onChange={(e) => setSelectedValuePaymentMethod(e.target.value)}
                                    />
                                </UniForm.InputGroup>
                            </UniCol>
                            <UniCol lg={2}>
                                <PaymentModalDetail setFetchData={setFetchData}/>
                            </UniCol>
                        </UniRow>
                        <UniForm.InputGroup>
                            <UniForm.Label class={"col-12"} text={IntlMessageValue('de', 'billing.additional', Store)}/>
                            <UniForm.FormControl name={'offerComment'}
                                                 required={true}
                                                 type={'textarea'}
                                                 defaultValue={offerComment !== undefined ? offerComment : 'Vertragslaufzeit / Zusätzliche Anmerkungen / Ergänzungen: \n' +
                                                     'Der Vertragspartner stellt entsprechendes Bild- und Textmaterial (z.B. Logos) zur Verfugung. Hiermit bestelle ich oben gekennzeichnete Leistungen. Bitte buchen Sie die fälligen Beträge von o.g. Konto ab. Alle Preise verstehen sich zzgl. der gesetzlichen Mehrwertsteuer. \n' +
                                                     'Die Vertragslaufzeit beträgt 48 Monate. \n' +
                                                     'Es gelten ausschließlich unsere ausgehändigten Allgemeinen Geschaftsbedingungen, die wesentlicher Bestandteil des Vertrages sind und im Erstgesprach besprochen und akzeptiert worden sind.'}
                                                 as="textarea" rows={5}
                            />
                        </UniForm.InputGroup>
                        {/*<Form.Check
                            type={"checkbox"}
                            label={IntlMessageValue('de', 'offer.agb.accept', Store)}
                            id={"acceptAgb"}
                            name={'acceptAgb'}
                            checked={checkedValuesAgb.includes('agb')}
                            onChange={handleCheckboxChangeAgb}
                        />
                        <Form.Check
                            type={"checkbox"}
                            label={IntlMessageValue('de', 'offer.data.protect', Store)}
                            id={"acceptDataProtect"}
                            name={'acceptDataProtect'}
                            checked={checkedValuesDataSafety.includes('dataSafety')}
                            onChange={handleCheckboxChangeDataSafety}
                        />*/}
                       {/* <div className={"pb-5"}>
                            <h6><IntlMessage Store={Store} messageId="billing.final.sentence"/></h6>

                            <UniForm.FormControl
                                type={'text'}
                                id={'closingSentence'}
                                name={'closingSentence'}
                                placeholder={''}
                                readOnly={true}
                            />
                        </div>*/}

                        {/*<UniRow>
                            <UniCol lg={6}>
                                <UniForm.InputGroup class={"mb-0 pt-5"}>
                                    <UniForm.InputGroupText text={IntlMessageValue('de', 'offer.location', Store)}/>
                                    <UniForm.FormControl name={'offerPaymentLocation'} required={true}
                                                         type={'text'}
                                                         defaultValue={offerPaymentLocation}
                                    />
                                </UniForm.InputGroup>
                                <UniCol lg={6}>
                                </UniCol>
                                <UniForm.InputGroup class={"mb-0"}>
                                    <UniForm.InputGroupText
                                        text={IntlMessageValue('de', 'offer.date.signature', Store)}/>
                                    <UniForm.FormControl name={'offerPaymentSignatureDate'} required={true}
                                                         type={'date'}
                                                         defaultValue={offerPaymentSignatureDate}
                                    />
                                </UniForm.InputGroup>
                            </UniCol>
                        </UniRow>*/}
                        <UniRow>
                            <UniCol lg={6} xs={12} md={12}>
                                <div className="App">
                                    <UniRow>
                                        <UniCol sm={"auto"} class={"mx-auto"}>
                                           {/* <UniRow class={"pb-3"}>
                                                <UniCol>
                                                    <h4><IntlMessage Store={Store} messageId="offer.signature.vendor"/>
                                                    </h4>
                                                </UniCol>
                                                <UniCol class={"d-flex justify-content-end"}>
                                                    <UniButton variant="primary"
                                                               onClick={(e) => handleCLear(e)}><IntlMessage
                                                        Store={Store}
                                                        messageId="offer.signature.delete"/></UniButton>
                                                    <UniModal Button={{
                                                        Text: IntlMessageValue('de', 'offer.signature.confirm', Store),
                                                        OnClick: trim,
                                                        Variant: 'success',
                                                        Class: 'ml-3',
                                                        Type: "button"
                                                    }}>
                                                        <UniModalHeader elements={'Unterschrift zwischengespeichert'}/>
                                                        <UniModal.Body
                                                            elements={IntlMessageValue('de', 'offer.modal.signature', Store)}/>
                                                        <UniModalFooter elements={''}/>
                                                    </UniModal>
                                                </UniCol>
                                            </UniRow>

                                            <DigitalSignature
                                                signaturePadRef={ref}
                                                canvasProps={{
                                                    style: {
                                                        background: "white",
                                                        border: "1px solid black",
                                                        width: '500px',
                                                        height: '125px'
                                                    }
                                                }}
                                            />*/}
                                            <UniForm.InputGroup class="mb-3">
                                                <UniForm.FormControl as="textarea" id={'clientSignature'} name={'clientSignature'}
                                                                     rows={3}
                                                                     class={"d-none"}
                                                                     defaultValue={clientSignature}
                                                                    /*TODO Bild taucht nicht auf*/
                                                />
                                            </UniForm.InputGroup>

                                        </UniCol>
                                    </UniRow>
                                </div>
                            </UniCol>
                            <UniCol lg={6} xs={12} md={12}>
                                <div className="App">
                                    <UniRow>
                                        <UniCol sm={"auto"} class={"mx-auto"}>
                                            <UniRow class={"pb-3"}>
                                                <UniCol>
                                                    <h4><IntlMessage Store={Store}
                                                                     messageId="offer.signature.customer"/>
                                                    </h4>
                                                </UniCol>
                                                <UniCol class={"d-flex justify-content-end"}>
                                                    <UniButton variant="primary"
                                                               onClick={(e) => handleCLears(e)}>
                                                        <IntlMessage
                                                            Store={Store}
                                                            messageId="offer.signature.delete"/>
                                                    </UniButton>
                                                    <UniModal Button={{
                                                        Text: IntlMessageValue('de', 'offer.signature.confirm', Store),
                                                        OnClick: trims,
                                                        Variant: 'success',
                                                        Class: 'ml-3',
                                                        Type: "button"
                                                    }}>
                                                        <UniModalHeader elements={'Unterschrift zwischengespeichert'}/>
                                                        <UniModal.Body
                                                            elements={IntlMessageValue('de', 'offer.modal.signature.customer', Store)}/>
                                                        <UniModalFooter elements={''}/>
                                                    </UniModal>
                                                </UniCol>
                                            </UniRow>

                                            <DigitalSignature
                                                signaturePadRef={refs}
                                                canvasProps={{
                                                    style: {
                                                        background: "white",
                                                        border: "1px solid black",
                                                        width: '500px',
                                                        height: '125px'
                                                    }
                                                }}
                                            />
                                            <UniForm.InputGroup class="mb-3">
                                                <UniForm.FormControl as="textarea" id={'contractorSignature'} name={'contractorSignature'}
                                                                     rows={3}
                                                                     class={"d-none"}
                                                                     defaultValue={contractorSignature} //TODO Bild taucht nicht auf

                                                                     />
                                            </UniForm.InputGroup>
                                        </UniCol>
                                    </UniRow>
                                </div>
                            </UniCol>
                        </UniRow>
                        <div className={"col-6 ms-auto justify-content-end d-flex"}>
                            <UniButton class={"m-2"} type={"submit"}>
                                <IntlMessage Store={Store} messageId="billing.button.save"/>
                            </UniButton>
                            <UniButton class={"m-2"} type={"button"} onClick={() => sendPreview()}>
                                <IntlMessage Store={Store} messageId="billing.button.preview"/>
                            </UniButton>
                        </div>
                    </UniForm>
                </div>
            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default PeopleNew;

interface MultiForm {
    entries: any,
    delimiter: any,
    lastPoint: string,
    part: string,

}

export const MultiFormNameSpace = (props: MultiForm) => {

    const isIntString = (str: string) => {
        const parsedInt = parseInt(str, 10);
        return !isNaN(parsedInt) && str === parsedInt.toString();
    }

    const checkType = (value: any) => {
        if (!isIntString(value)) {
            return (value === '' ? null : `"${value}"`);
        } else {
            return (value === '' ? null : value)
        }
    }

    let integer = '0';
    let OldData = [];

    // For beginn

    let offerTMP = '[{'


    for (const [key, value] of props.entries) {
        for (let d = 0; d < props.delimiter.length; d++) {

            if (key.includes(`${props.delimiter[d]}[`)) {
                let first = key.split(']')[0]
                let second = key.split(']')[1]

                let firstsec = first.split('[')[1]
                let secondsec = second.split('[')[1]
                if (firstsec === integer) {
                    if (secondsec === props.lastPoint) {
                        offerTMP = offerTMP + `"${secondsec}":${checkType(value)}`
                    } else {
                        offerTMP = offerTMP + `"${secondsec}":${checkType(value)},`
                    }
                } else {
                    integer = firstsec;
                    if (secondsec === props.lastPoint) {
                        offerTMP = offerTMP + `"${secondsec}":${checkType(value)}`
                    } else {
                        offerTMP = offerTMP + `},{"${secondsec}":${checkType(value)},`
                    }

                }
            }

        }
    }

    offerTMP = offerTMP + '}]'


    return JSON.parse(offerTMP)

}
