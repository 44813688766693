import IntlMessage, {IntlMessageValue} from '../../../../../@WUM/core/component/const/IntlMessage';
import React from "react";

function configuration(conf: string,response:any,newElement:any = false,Store:any) {

  const dataColOne = [
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'id',
      'value': (newElement === false ? response.id : '0'),
    },
    {
      'formType': 'input',
      'label': 'Text',
      'type': 'text',
      'name': 'text',
      'value': (newElement === false ? response.text : ''),
    },
    {
      'formType': 'input',
      'label': 'Kürzel',
      'type': 'text',
      'name': 'textkurz',
      'value': (newElement === false ? response.textkurz : ''),
    }
  ];

    return dataColOne;

}

export default configuration;
