import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    // Rand für die gesamte Seite
    page: {
        paddingLeft: '25mm',
        paddingRight: '20mm',
        marginTop: 20, //-20
        fontFamily: 'Open Sans',
        height: 200,
        paddingBottom: 130
    },
    // Styled den Betreff und definiert den Abstand vom Betreff nach unten
    subject: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 30,
    },
    // Styled Standardtext
    text: {
        fontSize: 10,
        fontWeight: "normal",
    },
    // Styled Standardabstand nach unten
    marginBottomStandard: {
        marginBottom: 10
    },
    // Ermöglicht Darstellung einer Zeile mit weißem Hintergrund
    row: {
        flexDirection: 'row',
        backgroundColor: '#fff'
    },
    // Ermöglicht grundlegende Spalten der row
    columnsHeader: {
        marginTop: 10,
        paddingTop: 10,
        flexGrow: 1,
        justifyContent: "space-between",
        flexDirection: 'row', // Set flex direction to 'row' for horizontal arrangement
        fontSize: 10,
        marginBottom: '8.46mm'
    },
    // Positioniert Blöcke nebeneinander mit möglichst viel abstand zwischen einander
    data: {
        justifyContent: "space-between",
        flexDirection: 'row', // Set flex direction to 'row' for horizontal arrangement
        marginBottom: 0.5,
    },
    // Außencontainer für die Tabelle
    table: {
        width: "auto",
        borderStyle: "solid",
        borderWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    // Kein Abstand nach unten
    marginBottomZero: {
        marginBottom: 0,
        margin: 0,
        padding: 0
    },
    marginRightBasic: {
        marginRight: 5
    },
});
