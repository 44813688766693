import {IntlMessage, IntlMessageValue} from '../../../../../@WUM/core/Elements/index';
import React from "react";

function configuration(conf: string,response:any,newElement:any = false,Store:any) {

  const dataColOne = [
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'id',
      'value': (newElement === false ? response.id : '0'),
    },
    {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="form.label.salutation" />,
      'name': 'Geschlecht',
      'required': true,
      'selected': (newElement === false ? response.geschlecht : ''),
      'options': [
        {
          'label': '',
          'value': ''
        },
        {
          'label': IntlMessageValue('de',"common.missis",Store),
          'value': '1'
        },
        {
          'label': IntlMessageValue('de',"common.mister",Store),
          'value': '0'
        }]

    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="form.label.prefix" />,
      'type': 'text',
      'name': 'title',
      'placeholder': 'Vorsatz (z.B. Titel)',
      'value': (newElement === false ? response.title : ''),
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.name" />,
      'type': 'text',
      'required': true,
      'name': 'name',
      'placeholder': IntlMessageValue('de','name.last.provide',Store),
      'value': (newElement === false ? response.name : ''),
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.name.first" />,
      'type': 'text',
      'required': true,
      'name': 'vorname',
      'placeholder': IntlMessageValue('de','name.first.provide',Store),
      'value': (newElement === false ? response.vorname : ''),
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="form.label.name_suffix" />,
      'type': 'text',
      'name': 'zusatz',
      'placeholder': 'Zusatz (z.B. Titel)',
      'value': (newElement === false ? response.zusatz : ''),
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.birthday" />,
      'type': 'date',
      'name': 'source',
      'required': true,
      'placeholder': 'xx.xx.xxxx',
      'value': (newElement === false ? response.source : ''),
    }
  ];

    return dataColOne;

}

export default configuration;
