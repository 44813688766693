import {configureStore} from '@reduxjs/toolkit'
import PageReducer from './Reducers/Pages/PagesReducer'
import ProductReducer from './Reducers/Product/ProductReducer'
import LocaleReducer from "../@WUM/templates/default/localization/store/Reducers/Locale/LocaleReducer";
import UserReducer from "../@WUM/templates/default/Auth/store/reducer/User/UserReducer";
import SingleTasksReducer from "../modules/ticketsystem/modules/new/store/reducer/Singletask/SingleTask";


const store = configureStore({
  reducer: {
    page: PageReducer,
    product: ProductReducer,
    locale: LocaleReducer,
    user:UserReducer,
    SingleTasks: SingleTasksReducer
  },
})



export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
