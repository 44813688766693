import {requests} from '../../../../../@WUM/core/RestFullApi/api';
import {phoneApiDetailType, phoneApiType} from "./phoneApiType";

export const phone = {
    getPosts: (limit:any,searchValue:any): Promise<phoneApiType[]>=> requests.get(`pv/phone/All/${limit}/${searchValue}`),
    getAPost: (id: string): Promise<phoneApiType[]> => requests.get(`pv/phone/${id}/`),
    createPost: (put: any,id:number): Promise<phoneApiDetailType> =>
        requests.put(`pv/phone/${id}/`, put),
    updatePost: (post: any, id: number): Promise<phoneApiDetailType> =>
        requests.post(`pv/phone/${id}/`, JSON.stringify(post)),
    deletePost: (id: string): Promise<void> => requests.delete(`pv/phone/${id}/`)
}

