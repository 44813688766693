
import updateDataMappingProps from "../@WUM/core/component/core/interface/updateDataMappingProps.interface";
import {peopleApiDetailType} from "../modules/CMS/people/API/request/peopleApiType";
import {configPeople, outputPeople} from "../modules/CMS/people/modules/configuration/mapping";
import {configProduct, outputProduct} from "../modules/CMS/products/modules/configuration/mapping";
import {peopleApiDetailType as productApiDetailType} from "../modules/CMS/products/API/request/peopleApiType";
import {peopleApiDetailType as priceTypeApiDetailType} from "../modules/settings/priceType/API/request/peopleApiType";
import {peopleApiDetailType as mwstTypeApiDetailType} from "../modules/settings/MwSTType/API/request/peopleApiType";
import {peopleApiDetailType as szrTypeApiDetailType} from "../modules/settings/szrType/API/request/peopleApiType";
import {peopleApiDetailType as zahlungsartypeApiDetailType} from "../modules/settings/zahlungsartype/API/request/peopleApiType";
import {configPriceType, outputPriceType} from "../modules/settings/priceType/modules/configuration/mapping";
import {configMwStType, outputMwStType} from "../modules/settings/MwSTType/modules/configuration/mapping";
import {configszrType, outputszrType} from "../modules/settings/szrType/modules/configuration/mapping";
import {
    configszahlungsartype,
    outputzahlungsartype
} from "../modules/settings/zahlungsartype/modules/configuration/mapping";
import {confighone, outputPhone} from "../modules/CMS/telephone/modules/configuration/mapping";
import {phoneApiDetailType} from "../modules/CMS/telephone/API/request/phoneApiType";


const updateDataMapping = (props:updateDataMappingProps) => {

    switch (props.part) {
        case 'people':
            let requestDataSite: peopleApiDetailType[] & configPeople = Object.assign({}, props.currentTutorial, outputPeople(props.Data));
            return requestDataSite;
        case 'product':
            let requestDataProduct: productApiDetailType[] & configProduct = Object.assign({}, props.currentTutorial, outputProduct(props.Data));
            return requestDataProduct;
        case 'priceType':
            let requestDatapriceType: priceTypeApiDetailType[] & configPriceType = Object.assign({}, props.currentTutorial, outputPriceType(props.Data));
            return requestDatapriceType;
        case 'mwstType':
            let requestDatamwstType: mwstTypeApiDetailType[] & configMwStType = Object.assign({}, props.currentTutorial, outputMwStType(props.Data));
            return requestDatamwstType;
        case 'szrType':
            let requestDataszrType: szrTypeApiDetailType[] & configszrType = Object.assign({}, props.currentTutorial, outputszrType(props.Data));
            return requestDataszrType;
        case 'zahlungsartype':
            let requestDatazahlungsartype: zahlungsartypeApiDetailType[] & configszahlungsartype = Object.assign({}, props.currentTutorial, outputzahlungsartype(props.Data));
            return requestDatazahlungsartype;
        case 'phone':
            let requestDataphon: phoneApiDetailType[] & confighone = Object.assign({}, props.currentTutorial, outputPhone(props.Data));
            return requestDataphon;
        default:
            return;
    }
}

export default updateDataMapping;
