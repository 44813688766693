

export interface confighone {
  "id": number,
  "name":string;
  "location": string;
  "landline_number": string;
  "mobile_number": string;
  "speed_dialing": string;
  "email": string;
  "info": string;
}

export function outputPhone(Data: any) {
  let back = {
    id: Data.id,
    "name": Data.name,
    "location":  Data.location,
    "landline_number":  Data.landline_number,
    "mobile_number":  Data.mobile_number,
    "speed_dialing": Data.speed_dialing,
    "email": Data.email,
    "info": Data.info,
  }

  return back;
}
