import {Text, View} from "@react-pdf/renderer";
import {PdfDateDetails as styles} from "../css/PdfDateDetails.css";
import React from "react";
import {CheckValue} from "../../../functions/checkValue";
import {TodayGerman} from "../../today";
import {DocumentStatus} from "../interfaces/DocumentStatus";
import {DocumentType} from "../interfaces/Documenttypes";
import {dateFormat} from "../../../../../@WUM/core/component/const/dateFormat";

/**
 * @typedef {Object} PeriodOfPerformanceProps - Properties for the PeriodOfPerformance component
 * @property {DocumentType} type - The type of document
 * @property {Object} Data - An object containing document-specific data
 * @property {Any} Store - Data related to the store (usage unclear)
 * @property {DocumentStatus} [status] - Optional status of the document (e.g., "final")
 */
interface PeriodOfPerformanceProps {
    type: DocumentType;
    Data: any;
    Store: any;
    status?: DocumentStatus;
}

/**
 * React component that displays the performance period for invoices.
 *
 * @param {PeriodOfPerformanceProps} props - Component properties
 * @returns {JSX.Element} JSX element representing the performance period (if applicable)
 */
export const PeriodOfPerformance = (props: PeriodOfPerformanceProps) => {
    let HolderPDF = [];

    const invoicePerfStart = dateFormat(
        props.Data.invoicePerfStart,
        "de",
        "us",
        false,
        false,
        props.Store
    );
    const invoicePerfEnd = dateFormat(
        props.Data.invoicePerfEnd,
        "de",
        "us",
        false,
        false,
        props.Store
    );

    if (props.type === "invoice") {
        if (props.Data.invoicePerfStart !== "" && props.Data.invoicePerfEnd !== "") {
            HolderPDF.push(
                <View style={[styles.data]}>
                    <Text>Leistungszeitraum</Text>
                    <Text>
                        {CheckValue(invoicePerfStart)} - {CheckValue(invoicePerfEnd)}
                    </Text>
                </View>
            );
        } else {
            HolderPDF.push(<></>);
        }
    } else {
        HolderPDF.push(<></>);
    }
    return <>{HolderPDF}</>; // Return the single JSX element
};