import {Image, Text, View} from "@react-pdf/renderer";
import {PdfCompanyInfo as styles} from "./css/PdfCompanyInfo.css";
import React from "react";

interface PdfCompanyInfoInterface {
    layout: any
    Data: any
}

export const PdfCompanyInfo = (props:PdfCompanyInfoInterface) => {
    const companyName = props.Data.companyName
    const companyOwner = props.Data.companyOwner
    const companyAddress = props.Data.companyAddress
    const companyCity = props.Data.companyCity

    return(
        <>
            <View>
                <View style={styles.logoArea}>
                    <Image
                        src={props.layout.logo}
                        // src={'/pdf-images/webkomTest.png'}
                        //style={(props.layout.type === 'webkom' ? styles.logo : styles.logoLiceo)}
                        style={styles.logo}
                    />
                </View>
                <View style={styles.addressColumn}>
                    <Text>
                        {props.layout.ownCompany}
                    </Text>
                    <Text style={styles.marginBottomZero}>
                        {props.layout.address} {props.layout.plz} {props.layout.city}
                    </Text>
                    <View style={styles.customerAddress}>
                        <Text>
                            {companyName}
                        </Text>
                        <Text>
                            {companyOwner}
                        </Text>
                        <Text>
                            {companyAddress}
                        </Text>
                        <Text>
                            {companyCity}
                        </Text>
                    </View>
                </View>
            </View>

        </>
    )
}