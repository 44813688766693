import {Font} from "@react-pdf/renderer";
// @ts-ignore
import openSansLight from "./Fonts/OpenSans/open-sans-v40-latin-300.ttf";
// @ts-ignore
import openSansLightItalic from "./Fonts/OpenSans/open-sans-v40-latin-300italic.ttf";
// @ts-ignore
import openSansRegular from "./Fonts/OpenSans/open-sans-v40-latin-regular.ttf";
// @ts-ignore
import openSansRegularItalic from "./Fonts/OpenSans/open-sans-v40-latin-italic.ttf";
// @ts-ignore
import openSansMedium from "./Fonts/OpenSans/open-sans-v40-latin-500.ttf";
// @ts-ignore
import openSansMediumItalic from "./Fonts/OpenSans/open-sans-v40-latin-500italic.ttf";
// @ts-ignore
import openSansSemibold from "./Fonts/OpenSans/open-sans-v40-latin-600.ttf";
// @ts-ignore
import openSansSemiboldItalic from "./Fonts/OpenSans/open-sans-v40-latin-600italic.ttf";
// @ts-ignore
import openSansBold from "./Fonts/OpenSans/open-sans-v40-latin-700.ttf";
// @ts-ignore
import openSansBoldItalic from "./Fonts/OpenSans/open-sans-v40-latin-700italic.ttf";
// @ts-ignore
import openSansExtrabold from "./Fonts/OpenSans/open-sans-v40-latin-800.ttf";
// @ts-ignore
import openSansExtraboldItalic from "./Fonts/OpenSans/open-sans-v40-latin-800italic.ttf";




const FontsRegister = () => {
    Font.register({
        family: 'Open Sans',
        fonts: [
            {
                src: openSansLight,
                fontWeight: "light"
            },
            {
                src: openSansRegular,
                fontWeight: "normal"
            },
            {
                src: openSansMedium,
                fontWeight: "medium"
            },
            {
                src: openSansSemibold,
                fontWeight: "semibold"
            },
            {
                src: openSansBold,
                fontWeight: "bold"
            },
            {
                src: openSansExtrabold,
                fontWeight: "ultrabold"
            },
        ]
    })
}

export default FontsRegister;