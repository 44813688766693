export const CheckValue = (value: any) => {
    if (value === "undefined.undefined.") {
        // Handle the case where value is "undefined.undefined."
        return "";
    } else if (value !== null && value !== undefined && value !== "") {
        // Value is not null, undefined, or an empty string
        return value;
    } else {
        // Value is null, undefined, or an empty string
        return ""; // Or return any appropriate value based on your logic
    }
};



export const checkOffervalue = (value:any,part:any) => {

    if(value !== undefined && value !== null) {
        return value[part]
    } else {
        return ''
    }

}
