

export interface configszahlungsartype {
  "id": number,
  beschreibung:string;
  bez: string;
  schlusssatz:string;
}

export function outputzahlungsartype(Data: any) {
  let back = {
    id: Data.id,
    beschreibung:Data.beschreibung,
    bez: Data.bez,
    schlusssatz:Data.schlusssatz,
  }

  return back;
}
