import {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";

const dataColOneProduct = (newElement:any,response:any) =>  {
    return [
        {
            'formType': 'input',
            'type': 'hidden',
            'name': 'id',
            'value': (newElement === false ? response.id : '0'),
        },
        {
            'formType': 'select',
            'label': 'Im-Auftrag-Art',
            'br': true,
            'name': 'art',
            'selected': (newElement === false ? response.art : 0),
            'options': [
                {
                    'label': '',
                    'value': '0'
                },
                {
                    'label': 'Paket',
                    'value': '1'
                },
                {
                    'label': 'Option',
                    'value': '2'
                },
                {
                    'label': 'Domain',
                    'value': '3'
                },
                {
                    'label': 'E-Mail',
                    'value': '4'
                },
                {
                    'label': 'KK-Antrag',
                    'value': '5'
                },
                {
                    'label': 'individuelle Option',
                    'value': '6'
                },
                {
                    'label': 'wiederkehrende Option',
                    'value': '7'
                },
                {
                    'label': 'individueller Preis = Menge wird auf Preis umgelegt',
                    'value': '8'
                },
                {
                    'label': 'Option Menge im Auftrag festlegbar',
                    'value': '9'
                },
                {
                    'label': 'Konfigurierbarer Artikel',
                    'value': '10'
                }]

        },
        {
            'formType': 'select',
            'label': 'status',
            'br': true,
            'name': 'status',
            'selected': (newElement === false ? response.status : 1),
            'options': [
                {
                    'label': 'Aktiviert',
                    'value': '1'
                },
                {
                    'label': 'Deaktiviert',
                    'value': '0'
                }]

        },

        {
            'formType': 'fetchselect',
            'local': 'AllProducts',
            'valueState': 'id',
            'labelState': 'bezeichnung',
            'beginn': 'true',
            'start': 0,
            'name': 'parent',
            'selected': (newElement === false ?  response.parent : '' ),
            'label': 'Übergeordnetes Produkt',
        },
        {
            'formType': 'input',
            'label': 'Artikelnummer',
            'type': 'text',
            'name': 'artikelnummer',
            'value': (newElement === false ? response.artikelnummer : ''),
        },
        {
            'formType': 'input',
            'label': 'Kürzel',
            'type': 'text',
            'name': 'kuerzel',
            'value': (newElement === false ? response.kuerzel : ''),
        },
        {
            'formType': 'input',
            'label': 'Int. Bezeichnung',
            'type': 'text',
            'name': 'intbez',
            'value': (newElement === false ? response.intbez : ''),
        },
        {
            'formType': 'textarea',
            'label': 'Bezeichnung',
            'type': 'textarea',
            'row': 3,
            'name': 'bezeichnung',
            'value': (newElement === false ? response.bezeichnung : ''),
        },
        {
            'formType': 'textarea',
            'label': 'Leistungsbeschreibung',
            'type': 'textarea',
            'row': 3,
            'name': 'leistungsbeschreibung',
            'value': (newElement === false ? response.leistungsbeschreibung : ''),
        },
        {
            'formType': 'fetchselect',
            'local': 'pricetype',
            'valueState': 'id',
            'labelState': 'text',
            'beginn': 'true',
            'start': 0,
            'name': 'preistyp',
            'selected': (newElement === false ?  response.preistyp  : ''),
            'label': 'PreisTyp',
        },
        {
            'formType': 'fetchselect',
            'local': 'MwST',
            'valueState': 'id',
            'labelState': 'Text',
            'beginn': 'true',
            'start': 0,
            'name': 'mwsttyp',
            'selected': (newElement === false ? response.mwsttyp : '' ),
            'label': 'MwSt-Typ',
        },
        {
            'formType': 'fetchselect',
            'local': 'servicetimetype',
            'valueState': 'id',
            'labelState': 'title',
            'beginn': 'true',
            'start': 0,
            'name': 'szrtyp',
            'selected': (newElement === false ?  response.szr_type : '' ),
            'label': 'Preis gilt für (nur Vorgabe)',
        },
        {
            'formType': 'input',
            'label': 'Einzelpreis (nur Vorgabe)',
            'type': 'number',
            'name': 'einzelpreisvorgabe',
            'value': (newElement === false ? response.einzelpreisvorgabe : ''),
        }
    ];
}

export default dataColOneProduct;
