import {checkDuplicates} from "./checkDuplicates";

interface CheckSingleMwstProps {
    offer: any
}

interface Offer {
    invoiceMWST: number;
}

export const CheckSingleMwst = (props: CheckSingleMwstProps) => {
    const invoiceMWSTValues = props.offer.map((offerItem: Offer) => offerItem.invoiceMWST);

    const duplicateCheckResult = checkDuplicates({
        data: invoiceMWSTValues, // Pass invoiceMWSTValues array to 'data' prop
        testFor: "boolean" // Specify 'value' test to check for value uniqueness
    });
    return duplicateCheckResult === true;
};
