import {AuthPagesConfig} from "../@WUM/templates/default/Auth/modules";
import {CMSRouteConfig} from "../modules/CMS";
import {TicketPagesConfig} from "../modules/ticketsystem/modules";
import {SettingsRouteConfig} from "../modules/settings";

export const RegisterRoute = (value: any) => {
    return [
        ...AuthPagesConfig(value),
        ...CMSRouteConfig(value),
        ...TicketPagesConfig(value),
        ...SettingsRouteConfig(value)
    ]
}
