import {StyleSheet} from "@react-pdf/renderer";

export const stylesTable = StyleSheet.create({
    // Tabellenzeile
    tableRow: {
        margin: 1,
        flexDirection: "row",
        borderStyle: "solid",
        borderBottomWidth: 1,
    },
    // Tabellenzeile
    tableRowHeader: {
        margin: 1,
        flexDirection: "row",
        borderStyle: "solid",
        borderBottomWidth: 1,
        fontSize: 8

    },
    // Tabellenspalte - definiert die Standardbreite von 12,5%. Muss jede Zelle im View-Container haben
    tableCol: {
        width: "12.5%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    // Tabellenspalte - definiert die extra Tabellenbreite von 5%. Muss jede Zelle im View-Container haben
    tableColExtraSmall: {
        width: "5%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    // Tabellenspalte - definiert die kleinen Tabellenbreite von 7,5%. Muss jede Zelle im View-Container haben
    tableColSmall: {
        width: "7.5%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    // Tabellenspalte - definiert die große Tabellenbreite von 35%. Muss jede Zelle im View-Container haben
    tableColBig: {
        width: "35%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    // Tabellenspalte - definiert die große Tabellenbreite von 42.5%. Muss jede Zelle im View-Container haben
    tableColBigMod: {
        width: "47.5%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },

    // Definiert Standardzellen, Abstände und Textgrößen
    tableCell: {
        margin: 2,
        marginTop: 5,
        fontSize: 8
    },
    // Definiert Zellen mit Zahlen drin
    tableCellValues: {
        margin: 2,
        marginTop: 5,
        fontSize: 8,
        textAlign: "right"
    },
    // Definiert Zellen mit Abständen, aber hervorgehobenem Text (größere Schrift, fett)
    tableCellHighlight: {
        margin: 2,
        marginTop: 5,
        fontSize: 10,
        fontWeight: "bold"
    },

});
