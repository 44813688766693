import {StyleSheet} from "@react-pdf/renderer";

export const stylesTextArea = StyleSheet.create({
    // Styled Standardtext
    text: {
        fontSize: 10,
        fontWeight: "normal",
    },
    //Abstand nach oben für die Überschriften
    marginTopSignature: {
        marginTop: 20
    },
    // Ermöglicht Darstellung einer Zeile mit weißem Hintergrund
    row: {
        flexDirection: 'row',
        backgroundColor: '#fff'
    },
    signature: {
        width: 150
    }

});
