import {peopleApiType} from "../request/peopleApiType";
import {people} from "../request/people";
import {useEffect, useState} from 'react';
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {error} from "../../../../../@WUM/core/Elements/index";

export const ResponseLayoutOverview = (limit: any, searchValue: any,fetchData:any) => {
    const [posts, setPosts] = useState<peopleApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await people.getPosts(limit,searchValue)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [limit,searchValue,fetchData])


    return isError ? (posts ) : (posts);

}

export const ResponseDetailPeople = (id: any) => {
    const [posts, setPosts] = useState<peopleApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await people.getAPost(id)
                .then((data) => {
                    checkLogin(data);
                    return setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (posts ) : (posts);

}

export const ResponseDeletePeople = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await people.deletePost(id)
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    error(err);
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (isError ) : (isError);

}

