import {Text, View} from "@react-pdf/renderer";
import {stylesTable as styles} from "./css/PdfProductTable.css";
import {INTLNumberFormat} from "../../functions/IntlNumberFormat";
import React from "react";

interface PdfProductTableInterface {
    Data: any
    fetchAllProducts: any
    allMWSTSame: boolean
}

interface PdfProductTableHeaderProps {
    allMWSTSame: boolean
    Data?: any
}




export const PdfProductTableHeader = (props:PdfProductTableHeaderProps) => {
    return(
        <>
            <View style={styles.tableRow}>
                {/*<View style={styles.tableColExtraSmall}>
                            <Text style={styles.tableCell}>Pos</Text>
                        </View>*/}
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Art.Nr.</Text>
                </View>
                <View style={props.allMWSTSame ? styles.tableColBigMod : styles.tableColBig}>
                    <Text style={styles.tableCell}>Produkt/Service</Text>
                </View>
                <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellValues}>Anzahl</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCellValues}>Einzelpreis</Text>
                </View>
                <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellValues}>Einheit</Text>
                </View>
                {/*<View style={styles.tableCol}>
                    <Text style={styles.tableCellValues}>Rabatt</Text>
                </View>*/}
                {/*<View style={styles.tableCol}>
                    <Text style={styles.tableCellValues}>MwSt.</Text>
                </View>*/}
                {
                    !props.allMWSTSame && (
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellValues}>MwSt.</Text>
                        </View>
                    )
                }
                <View style={styles.tableCol}>
                    <Text style={styles.tableCellValues}>Gesamt</Text>
                </View>
            </View>

        </>
    )
}

export const PdfProductTable = (props:PdfProductTableInterface) => {
    let offer = props.Data.offer;

    //check if all mwst-values are similar or not
    //console.log("Are all MWST same?", allMWSTSame)

    let offerHolder = [];

    for (let o = 0; o < offer.length; o++) {
        offerHolder.push(
            <View style={styles.tableRowHeader} wrap={false}>
                {/*<View style={styles.tableColExtraSmall}>
                        <Text style={styles.tableCell}>{o + 1}</Text>
                    </View>*/}
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{offer[o].invoiceArticleNumber}</Text>
                </View>
                <View style={props.allMWSTSame ? styles.tableColBigMod : styles.tableColBig}>
                    <Text style={styles.tableCell}>
                        {props.fetchAllProducts?.find((item: { id: number; }) => item.id === parseInt(offer[o].invoiceProduct))?.bezeichnung}
                    </Text>
                    <Text style={styles.tableCell}>
                        {offer[o].invoiceDescription}
                    </Text>
                </View>
                <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellValues}>{offer[o].invoiceAmount}</Text>
                </View>

                <View style={styles.tableCol}>
                    <Text style={styles.tableCellValues}>
                        {INTLNumberFormat.format(offer[o].invoicePrice)}
                    </Text>
                </View>
                <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellValues}>
                        {offer[o].productUnit}
                    </Text>
                </View>
                {/*<View style={styles.tableCol}>
                    <Text style={styles.tableCellValues}>
                        {offer[o].invoiceDiscount} %
                    </Text>
                </View>*/}
                {
                    !props.allMWSTSame && (
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellValues}>{offer[o].invoiceMWST} %</Text>
                        </View>
                    )
                }
                <View style={styles.tableCol}>
                    <Text style={styles.tableCellValues}>
                        {offer[o].invoiceTotal} €
                    </Text>
                </View>
            </View>
        )
    }

    return (
        <>{offerHolder}</>
    )

}
