import {initialStatePeopleNew, PeopleNewInterface} from "../../interface/People.interface";
import React, {useState, useRef} from 'react';
import {useSelector} from "react-redux";
import {
    SpinnerJSX,
    UniRow,
    UniButton,
    UniCol,
    IntlMessage, UniForm
} from "../../../../../@WUM/core/Elements/index";
import {
    Responsebuytype,
    ResponsefetchAllProducts, ResponsefetchQR,
    ResponseMwST,
    Responsepricetype,
    Responseservicetimetype
} from "../../API/response/people";
/* @ts-ignore */
import {PDFDownloadLink, PDFViewer} from '@react-pdf/renderer';
import EmailCanvas from "../elements/emailCanvas"
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OfferPDF from "../elements/pdf/offerPdf";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import LayoutCanvas from "../../../../components/Elements/layoutCanvas";
import {ResponseLayoutOverview} from "../../../../settings/layouts/API/response/people";

const DetailPreview = (props:PeopleNewInterface=initialStatePeopleNew) => {

    const [validated, setValidated] = useState(false);
    const Store = useSelector((state: any) => state)
    const LSParse = JSON.parse(localStorage.getItem('OfferData') as string)
    const QR = ResponsefetchQR(LSParse.id)


    let MwST = ResponseMwST()
    let buytype = Responsebuytype('')
    let pricetype = Responsepricetype()
    let servicetimetype = Responseservicetimetype('')
    let fetchAllProducts = ResponsefetchAllProducts();
    let Layout = ResponseLayoutOverview('0', '','');
    const [data, setData] = useState(''); // State to hold initial data (empty string)
    const [layout, setLayout] = useState({
        logo:"/webkom.png",
        phone: "+49 171 7751453",
        address: "Zwickauer Straße 480",
        plz: "09117",
        ownCompany: "WEBKOMMUNIKATION24 GmbH",
        bankName: "Sparkasse Chemnitz",
        IBAN: "DE18 8705 0000 0714 9839 93",
        BIC: "CHEKDE81XXX",
        web: "www.weko24.de",
        mail: "info@weko24.de",
        ceo: "Dr. Karin Rabe",
        city: "Chemnitz",
        register: "HRB 27243",
        taxNumber: "215/122/04551",
        taxId: "DE 281479345",
        courtLoc: "Chemnitz",
        type:'webkom'
    }); // State to hold initial layout 640x491

    const handleClick = (Layout: any) => {
        setLayout({ ...layout,
            logo:Layout.logo,
            phone: Layout.phone,
            address: Layout.address,
            plz: Layout.plz,
            ownCompany: Layout.ownCompany,
            bankName: Layout.bankName,
            IBAN: Layout.IBAN,
            BIC: Layout.BIC,
            web: Layout.web,
            mail: Layout.mail,
            ceo: Layout.ceo,
            city: Layout.city,
            register: Layout.register,
            taxNumber: Layout.taxNumber,
            taxId: Layout.taxId,
            courtLoc: Layout.courtLoc,
            type:'liceo'

        }); // Update layout with a new plz value
        setData(''); // Reset data to trigger a re-render
    };

    if (MwST !== undefined && MwST.length > 0
        && buytype !== undefined && buytype.length > 0
        && pricetype !== undefined && pricetype.length > 0
        && servicetimetype !== undefined && servicetimetype.length > 0
        && fetchAllProducts !== undefined && fetchAllProducts.length > 0 && Layout.length > 0) {

        let LayoutHolder = [];

        for(let l=1;l<Layout.length;l++) {
            LayoutHolder.push(
                <UniCol xl={4} lg={4}>
                <UniButton variant="outline-primary" class={"h-100"} onClick={() => handleClick(Layout[l])}>
                    <img
                        src={Layout[l].logo}
                        height="100px"
                        alt="Logo"
                    />
                </UniButton>
                </UniCol>
            )
        }
        return(
            <>
                <h2 className={"col-12"}>
                        <UniButton variant="outline-success" type={'button'} href={'/offer/new/1'}>
                    <span className="material-symbols-sharp" style={{verticalAlign:'bottom'}}>
                        arrow_back
                    </span>
                    <IntlMessage Store={Store} messageId="offer.preview.header"/>
                    </UniButton>
                </h2>
                <UniRow>
                    <UniCol xl={7} lg={6} class={"h-100"}>
                        <PDFViewer
                            width={"auto"}
                            height={"750px"}
                            className={"col-12"}
                        >
                            <OfferPDF data={''} layout={layout} fetchAllProducts={fetchAllProducts} Store={Store} PaymentMethod={servicetimetype}/>
                        </PDFViewer>
                    </UniCol>
                    <UniCol>
                        <h3>

                            <IntlMessage Store={Store} messageId="offer.preview.layout"/>
                        </h3>
                        <UniRow class={"gx-lg-1 gx-xl-3 pb-4"}>
                            {LayoutHolder}
                        </UniRow>
                        <LayoutCanvas/>
                        <br />
                        <div style={{position: 'absolute', bottom: 0}}>
                            <img src={
                                /* @ts-ignore */
                                QR[0].src as string} width={'200'} height={'200'}/>
                            <UniForm.FormControl readOnly={true}
                                                 name={''}
                                                 type={'text'}
                                                 defaultValue={LSParse.SignProcessCode}
                            />
                        </div>


                        <Dropdown as={ButtonGroup} className={'float-end'}
                                  style={{position: 'absolute', bottom: 0, right: 15}}>
                            <UniButton class={''}>
                                <EmailCanvas fetchAllProducts={fetchAllProducts}/>
                            </UniButton>

                            <Dropdown.Toggle split variant="success" id="dropdown-split-basic"/>

                            <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1"><IntlMessage Store={Store} messageId="billing.button.save"/></Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                                <PDFDownloadLink className={""} document={
                                    <OfferPDF data={''} layout={layout} fetchAllProducts={fetchAllProducts} Store={Store}/>
                                } fileName="Angebot.pdf">
                                    Als Entwurf exportieren
                                </PDFDownloadLink>
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                                <PDFDownloadLink className={""} document={
                                    <OfferPDF data={''} layout={layout} fetchAllProducts={fetchAllProducts} Store={Store} status={"final"}/>
                                } fileName="Angebot.pdf">
                                    Abschließen und als PDF exportieren
                                </PDFDownloadLink>

                        </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    </UniCol>
                </UniRow>

            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default DetailPreview;

const CreateQR = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo3NywiZXhwIjoxNzE5OTkxMjQ3fQ.kAbXhaIytWGe1Z_8QOTj2vG1r0oDAvwk9SMQl59_7OM");
    myHeaders.append("Cookie", "PHPSESSID=klctnsvnancdjeqt2g5o4s59ge");

    const raw = "{\r\n    \"url\":\"https://projektverwaltung.wum-solution.tech/sign/offer/4/\"\r\n}";

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw
    };

    fetch("https://projektverwaltung.wum-solution.tech/api/qr/code/generator/new/", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
}