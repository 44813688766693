import {StyleSheet} from "@react-pdf/renderer";

export const stylesFooter = StyleSheet.create({
    // Positioniert Blöcke nebeneinander mit möglichst viel abstand zwischen einander
    data: {
        justifyContent: "space-between",
        flexDirection: 'row', // Set flex direction to 'row' for horizontal arrangement
        marginBottom: 0.5,
    },
    // Kein Abstand nach unten
    marginBottomZero: {
        marginBottom: 0,
        margin: 0,
        padding: 0
    },
    footerText: {
        fontSize: 6,
        borderTopWidth: 1,
        borderStyle: "solid",
        width: "165mm"
    },
    dataFooter: {
        justifyContent: "space-between",
        flexDirection: 'row', // Set flex direction to 'row' for horizontal arrangement
        marginBottom: 3,
        width: "165mm"
    },
    marginRightBasicHighlight: {
        alignItems: "flex-start",
        textAlign: "left",
        fontWeight: "bold"
    },
    footerWidthBoxWider: {
        width: '554mm',
        textAlign: 'left'
    },
    footerColumns: {
        flexGrow: 1,
        justifyContent: "space-between",
        flexDirection: 'row', // Set flex direction to 'row' for horizontal arrangement
        fontSize: '2pt',
        position: "absolute",
        bottom: 10,
        left: "25mm",
        width: "165mm",
        paddingBottom: "8mm"

    },
    center: {
        alignItems: "center"
    }
});
