

export const initialStatePeopleOverview ={
    value:null
}

export interface PeopleInterface {
    value:any,
}

export const initialStatePeoplePagesConfig ={
    value:null
}

export interface PeoplePagesConfigInterface {
    value:any,
}

export const initialStatePeopledMenuConfig ={
    value:null
}

export interface PeopledMenuConfigInterface {
    value:any,
}

export const initialStatePeopleNew ={
    value:null
}

export interface PeopleNewInterface {
    value:any,
}

export const initialStatePeopleDetail ={
    value:null
}

export interface PeopleDetailInterface {
    value:any,
}
