import setTotalPrice from "./setTotalPrice";

interface setProductInfoProps {
    e: any,
    fetchAllProducts:any,
    MwST: any,
    tableIdRef: number
}



export const SetProductInfo = (props:setProductInfoProps) => {
    console.log(props.e)
    console.log(props.e.target.object + 'object')
    console.log(props.e.target.value + 'value')
    //  console.log(props.fetchAllProducts)
    // console.log(props.MwST)
    console.log(props.tableIdRef + 'tableIdRef')

    const selectedProductId = props.e.target.value

    for(let p=0;p<props.fetchAllProducts.length;p++) {
        if(props.fetchAllProducts[p].id == selectedProductId) {
            console.log(selectedProductId)
            const selectedMwstID = props.fetchAllProducts[p].mwsttyp
            const matchingMwst = props.MwST.find((MwST: { id: any; }) => MwST.id === selectedMwstID);

            let invoicePriceValue = (document.getElementById('invoicePrice'+props.tableIdRef) as HTMLInputElement)
            invoicePriceValue.value = props.fetchAllProducts[p].einzelpreisvorgabe
            let invoiceTotalValue = (document.getElementById('invoiceTotal'+props.tableIdRef) as HTMLInputElement)
            invoiceTotalValue.value = invoicePriceValue.value
            let invoiceAmountValue = (document.getElementById('invoiceAmount'+props.tableIdRef) as HTMLInputElement)
            invoiceAmountValue.value = '1'
            let invoiceMWSTValue:HTMLInputElement = (document.getElementById('invoiceMWST'+props.tableIdRef) as HTMLInputElement)
            let invoiceArticleNumber = (document.getElementById('invoiceArticleNumber'+props.tableIdRef) as HTMLInputElement)
            invoiceArticleNumber.value = props.fetchAllProducts[p].artikelnummer
            let productUnit = (document.getElementById('productUnit'+props.tableIdRef) as HTMLInputElement)
            productUnit.value = props.fetchAllProducts[p].einheit

            setTotalPrice({
                tableIdRef: props.tableIdRef
            })
            // invoiceMWSTValue.value = MwST[fetchAllProducts[selectedProductId].mwsttyp].standartwert
            if (matchingMwst) {
                invoiceMWSTValue.value = matchingMwst.standartwert;
            }else {
                console.warn("Matching MwSt. not found. Attention!")
            }
        }
    }

}