import {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";

const dataColOnePriceType = (newElement:any,response:any,Store:any) =>  {
    return [

        {
            'formType': 'input',
            'type': 'hidden',
            'name': 'id',
            'value': (newElement === false ? response.id : '0'),
        },
        {
            formType: 'header',
            value:'Logo',
            type:'h6'
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    id: 'image_title_image',
                    path:
                        newElement === false
                            ? response.logo !== undefined &&
                            response.logo !== null
                                ? response.logo
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 2,
                        md: 2,
                        sm: 2
                    }
                },
                {
                    formType: 'formFileBucket',
                    onclick: true,
                    onclick_context: 'pielers_element_text',
                    append: 'preview_media',
                    method:'cdn',
                    experiment: true,
                    src: 'image_title_image_',
                    type: 'file',
                    size: {
                        xl: 10,
                        md: 10,
                        sm: 10
                    }
                }
            ]
        },{
            formType: 'input',
            name: 'logo',
            id: 'AttachDocumentUrl',
            type: 'hidden',
            placeholder: 'imageID',
            value: newElement === false ? response.logo : ''
        },
        {
            formType: 'header',
            value:'Unternehmensinformationen',
            type:'h5'
        },
        {
            'formType':'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText':IntlMessageValue('de', 'layout.ownCompany', Store),
            'type': 'text',
            'name': 'Firma',
            'value': (newElement === false ? response.Firma : '')
        },
        {
            'formType':'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText':IntlMessageValue('de', 'layout.ceo', Store),
            'type': 'GF',
            'name': 'ceo',
            'value': (newElement === false ? response.GF : '')
        },{
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': 'KTO',
            'type': 'text',
            'name': 'KTO',
            'value': (newElement === false ? response.KTO : ''),
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': 'Name',
            'type': 'text',
            'name': 'name',
            'value': (newElement === false ? response.name : ''),
        },
        {
            formType: 'header',
            value:'Bankinformationen',
            type:'h5'
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.bankName', Store),
            'type': 'text',
            'name': 'Kreditinstitut',
            'value': (newElement === false ? response.Kreditinstitut : ''),
        },{
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.iban', Store),
            'type': 'text',
            'name': 'BIC',
            'value': (newElement === false ? response.BIC : ''),
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.bic', Store),
            'type': 'text',
            'name': 'SWIFT',
            'value': (newElement === false ? response.SWIFT : ''),
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': 'Gläubiger Id',
            'type': 'text',
            'name': 'glaeubigerid',
            'value': (newElement === false ? response.glaeubigerid : ''),
        },
        {
            formType: 'header',
            value:'Web-Infos',
            type:'h5'
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.web', Store),
            'type': 'text',
            'name': 'Web',
            'value': (newElement === false ? response.Web : ''),
        },{
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.mail', Store),
            'type': 'text',
            'name': 'email',
            'value': (newElement === false ? response.email : ''),
        },{
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': 'reMail',
            'type': 'text',
            'name': 'reMail',
            'value': (newElement === false ? response.reMail : ''),
        },
        {
            formType: 'header',
            value:'Rechtsinfos und Steuern',
            type:'h5'
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.register', Store),
            'type': 'text',
            'name': 'Handesregister',
            'value': (newElement === false ? response.Handesregister : ''),
        },{
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.taxNumber', Store),
            'type': 'text',
            'name': 'Steuernummer',
            'value': (newElement === false ? response.Steuernummer : ''),
        },{
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.taxId', Store),
            'type': 'text',
            'name': 'UstId',
            'value': (newElement === false ? response.UstId : ''),
        },
        {
            'formType': 'inputGroup',
            classNameFormGroup:'mb-0',
            classNameInputGroup:'mb-0',
            'preText': IntlMessageValue('de', 'layout.courtLoc', Store),
            'type': 'text',
            'name': 'Gerichtsstand',
            'value': (newElement === false ? response.Gerichtsstand : ''),
        },
        {
            formType: 'header',
            value:'Dokumente',
            type:'h5'
        },
        {
            formType: 'header',
            value:'TOM',
            type:'h6'
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    id: 'image_title_imageTOM',
                    path:
                        newElement === false
                            ? response.logo !== undefined &&
                            response.logo !== null
                                ? response.logo
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 2,
                        md: 2,
                        sm: 2
                    }
                },
                {
                    formType: 'formFileBucket',
                    onclick: true,
                    onclick_context: 'pielers_element_text',
                    append: 'preview_mediaTOM',
                    method:'cdn',
                    experiment: true,
                    src: 'image_title_imageTOM_',
                    type: 'file',
                    size: {
                        xl: 10,
                        md: 10,
                        sm: 10
                    }
                }
            ]
        },{
            formType: 'input',
            name: 'Media[TOM][url]',
            id: 'preview_mediaTOM',
            type: 'text',
            value: newElement === false && response !== undefined && response.medias !== undefined && response.medias !== null && response.medias.TOM !== undefined  ? response.medias.TOM.url : ''
        },
        {
            formType: 'input',
            name: 'Media[TOM][type]',
            type: 'hidden',
            placeholder: 'imageID',
            value: 'TOM'
        },
        {
            formType: 'header',
            value:'AGB',
            type:'h6'
        },{
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    id: 'image_title_imageAGB',
                    path:
                        newElement === false
                            ? response.logo !== undefined &&
                            response.logo !== null
                                ? response.logo
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 2,
                        md: 2,
                        sm: 2
                    }
                },
                {
                    formType: 'formFileBucket',
                    onclick: true,
                    onclick_context: 'pielers_element_text',
                    append: 'preview_mediaAGB',
                    method:'cdn',
                    experiment: true,
                    src: 'image_title_imageAGB_',
                    type: 'file',
                    size: {
                        xl: 10,
                        md: 10,
                        sm: 10
                    }
                }
            ]
        },{
            formType: 'input',
            name: 'Media[AGB][url]',
            id: 'preview_mediaAGB',
            type: 'text',
            value: newElement === false && response !== undefined && response.medias !== undefined && response.medias !== null && response.medias.AGB !== undefined  ? response.medias.AGB.url : ''
        },
        {
            formType: 'input',
            name: 'Media[AGB][type]',
            type: 'hidden',
            placeholder: 'imageID',
            value: 'AGB'
        },{
            formType: 'header',
            value:'DSV',
            type:'h6'
        },{
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    id: 'image_title_imageDSV',
                    path:
                        newElement === false
                            ? response.logo !== undefined &&
                            response.logo !== null
                                ? response.logo
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 2,
                        md: 2,
                        sm: 2
                    }
                },
                {
                    formType: 'formFileBucket',
                    onclick: true,
                    onclick_context: 'pielers_element_text',
                    append: 'preview_mediaDSV',
                    method:'cdn',
                    experiment: true,
                    src: 'image_title_imageDSV_',
                    type: 'file',
                    size: {
                        xl: 10,
                        md: 10,
                        sm: 10
                    }
                }
            ]
        },{
            formType: 'input',
            name: 'Media[DSV][url]',
            id: 'preview_mediaDSV',
            type: 'text',
            value: newElement === false && response !== undefined && response.medias !== undefined && response.medias !== null && response.medias.DSV !== undefined  ? response.medias.DSV.url : ''
        },
        {
            formType: 'input',
            name: 'Media[DSV][type]',
            type: 'hidden',
            placeholder: 'imageID',
            value: 'DSV'
        },
        {
            formType: 'header',
            value:'ADV',
            type:'h6'
        }
        ,{
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    id: 'image_title_imageADV',
                    path:
                        newElement === false
                            ? response.logo !== undefined &&
                            response.logo !== null
                                ? response.logo
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 2,
                        md: 2,
                        sm: 2
                    }
                },
                {
                    formType: 'formFileBucket',
                    onclick: true,
                    onclick_context: 'pielers_element_text',
                    append: 'preview_mediaADV',
                    method:'cdn',
                    experiment: true,
                    src: 'image_title_imageADV_',
                    type: 'file',
                    size: {
                        xl: 10,
                        md: 10,
                        sm: 10
                    }
                }
            ]
        },{
            formType: 'input',
            name: 'Media[ADV][url]',
            id: 'preview_mediaADV',
            type: 'text',
            value: newElement === false && response !== undefined && response.medias !== undefined && response.medias !== null && response.medias.ADV !== undefined ? response.medias.ADV.url : ''
        },
        {
            formType: 'input',
            name: 'Media[ADV][type]',
            type: 'hidden',
            placeholder: 'imageID',
            value: 'ADV'
        }
    ];
}

export default dataColOnePriceType;


/*
{
    "id": 1,
    "logo": "/webkom.png",
    "type": "webkom",
    "created_at": "2024-05-26 13:23:48",
    "updated_at": "2024-05-26 13:23:48"
}
 */
