import React from 'react';
import ReactDOM from "react-dom";
import ProductTable from "../Elements/productTable";

interface AddPositionProps  {
    tableid:number
    Store:any
    MwST:any,
    fetchAllProducts: any,
    isCollapsed: any,
    type?: string
    setFetchData?:any
}

export const AddPosition = (props:AddPositionProps) => {
    let count = document.getElementsByClassName('offertd')
    let newElement = <><ProductTable tableid={count.length + 1} new={true} Store={props.Store} setFetchData={props.setFetchData} fetchAllProducts={props.fetchAllProducts} MwST={props.MwST} isCollapsed={props.isCollapsed} type={props.type}/></>
        let temp = document.createElement('tr')
        temp.id = count.length + 1+'tr'
        temp.classList.add("offertd");

    let ele = document.getElementById('productTable2') as HTMLDivElement
    if(ele !== null) {
        ele.append(temp)
    }

    ReactDOM.render(newElement, temp)

}
