import {useEffect, useState} from "react";
import {useProSidebar} from "react-pro-sidebar";

// necessary to collapse the navi - TODO Button is not treated perfectly

export interface SidebarProps {
    menuCollapse: boolean;
    setMenuCollapse: (menuCollapse: boolean) => void;
}
export const HandleSidebarCollapse = (props: SidebarProps) => {
    const { collapseSidebar } = useProSidebar();
    const { setMenuCollapse } = props;

    const windowResizeHandler = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 1700) {
            setMenuCollapse(true);
            collapseSidebar();
        } else {
            setMenuCollapse(false);
        }
    };

    useEffect(() => {
        windowResizeHandler();
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);


    return {
        HandleSidebarCollapse,
    };
};
