import {
    UniButton,
    UniForm,
    UniModal, IntlMessage, ApiURL, error
} from "../../../../../@WUM/core/Elements";
import {UniModalHeader} from "../../../../../@WUM/core/Elements/Modal/Elements/UniModalHeader";
import {UniModalFooter} from "../../../../../@WUM/core/Elements/Modal/Elements/UniModalFooter";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {getToken} from "../../../../../@WUM/core/component/const/CardJSX";
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {notify_error, notify_save} from "../../../../../@WUM/core/component/const/notification.service";

const PaymentModalDetail = (props:{setFetchData:any}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const Store = useSelector((state: any) => state)

    const send = (e:any) => {
        e.preventDefault()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());
        let form = e.currentTarget

        var formData = init2(form)

        let raw = JSON.stringify({
            "id":0,
            "beschreibung":formData.beschreibung,
            "bez":formData.bez,
            "schlusssatz":formData.schlusssatz
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"pv/zahlungsart/0/", requestOptions)
            .then(response => response.text())
            .then(result => {successForm(result)})
            .catch(error => errorForm(error));
    }

    const sendSZR = (e:any)=> {
        e.preventDefault()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());
        let form = e.currentTarget

        var formData = init2(form)

        let raw = JSON.stringify({
            "id":0,
            "duration":formData.duration,
            "durationtype":formData.durationtype,
            "title":formData.title
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"pv/serivezeitraumtype/0/", requestOptions)
            .then(response => response.text())
            .then(result => {successForm(result)})
            .catch(error => errorForm(error));
    }

    const successForm = (result:any) => {
        props.setFetchData(Date.now())
        notify_save(Store)
        handleClose()
    }

    const errorForm = (error:any) => {
        notify_error(Store)
        console.log(error)
    }



    return (
        <>
                <UniModal Button={{
                    Text: <IntlMessage Store={Store} messageId="billing.button.set.payment"/>,
                    Variant: 'primary',
                    Class: 'm-0 w-100',
                    Type: "button"
                }} setShow={setShow} show={show} handleShow={handleShow} handleClose={handleClose}>
                    <UniModalHeader elements={'Zahlungsart- und Methoden'}/>
                    <UniModal.Body
                        elements={
                            <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="home" title="Zahlungsart">
                                    <UniForm validated={false} Submit={(e) => send(e)}>
                                        <UniForm.InputGroup class={"mb-0 h-100"}>
                                            <UniForm.InputGroupText
                                                text={'Beschreibung'}/>
                                            <UniForm.FormControl name={'beschreibung'} required={true}
                                                                 type={'text'}/>
                                        </UniForm.InputGroup>
                                        <UniForm.InputGroup class={"mb-0 h-100"}>
                                            <UniForm.InputGroupText
                                                text={'Bezeichnung'}/>
                                            <UniForm.FormControl name={'bez'} required={true}
                                                                 type={'text'}/>
                                        </UniForm.InputGroup>
                                        <UniForm.InputGroup class={"mb-0 h-100"}>
                                            <UniForm.InputGroupText
                                                text={'Schlusssatz'}/>
                                            <UniForm.FormControl name={'schlusssatz'} required={true}
                                                                 type={'text'}/>
                                        </UniForm.InputGroup>
                                        <UniButton class={"m-2"} type={"button"} variant={'danger'} onClick={handleClose}>
                                            <IntlMessage Store={Store} messageId="Schließen"/>
                                        </UniButton>
                                        <UniButton class={"m-2 float-end"} type={"submit"} variant={'success'} onClick={handleClose}>
                                            <IntlMessage Store={Store} messageId="Zahlungsart hinzufügen"/>
                                        </UniButton>

                                    </UniForm>
                                </Tab>
                                <Tab eventKey="profile" title="Zahlungsweise">
                                    <UniForm validated={false} Submit={(e) => sendSZR(e)}>
                                        <UniForm.InputGroup class={"mb-0 h-100"}>
                                            <UniForm.InputGroupText
                                                text={'Dauer'}/>
                                            <UniForm.FormControl name={'duration'}  required={true}
                                                                 type={'number'}/>
                                        </UniForm.InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Dauertyp</InputGroup.Text>
                                        <Form.Select aria-label="durationtype" name={'durationtype'}>
                                            <option>Open this select menu</option>
                                            <option value="0"></option>
                                            <option value="1">Jahr</option>
                                            <option value="2">Monat</option>
                                            <option value="3">Tag</option>
                                            <option value="4">Woche</option>
                                        </Form.Select>
                                        </InputGroup>
                                        <UniForm.InputGroup class={"mb-0 h-100"}>
                                        <UniForm.InputGroupText
                                                text={'Titel'}/>
                                            <UniForm.FormControl name={'title'} required={true}
                                                                 type={'text'}/>
                                        </UniForm.InputGroup>
                                        <UniButton class={"m-2"} type={"button"} variant={'danger'} onClick={handleClose}>
                                            <IntlMessage Store={Store} messageId="Schließen"/>
                                        </UniButton>
                                        <UniButton class={"m-2 float-end"} type={"submit"} variant={'success'} onClick={handleClose}>
                                            <IntlMessage Store={Store} messageId="Zahlungsweise hinzufügen"/>
                                        </UniButton>
                                    </UniForm>
                                </Tab>
                            </Tabs>

                        }/>

                    <UniModalFooter elements={''}/>
                </UniModal>
        </>
    )
}

export default PaymentModalDetail