import React from "react";
import {IntlMessage} from "../../../../../@WUM/core/Elements/index";
import home from "../elements/home";
import TabsConfigProps from "../../interface/TabsConfigProps.interface";

const TabsConfig = (props:TabsConfigProps) => {

    let config = [{
        tab: {defaultActiveKey:'home',id:'uncontrolled-tab-example',className:'mb-3',key:'tabindex'},
        tabs:[
            {eventKey:'home',title:<IntlMessage Store={props.Store} messageId="common.general" />,key:'home',id:'home',class:'TabPanel',content:home(
                {response:props.response2,Store:props.Store})},
        ]
    }]

    return config;

}

export default TabsConfig;
