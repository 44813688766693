import React, {useState} from 'react';
import {DynamikOffCanvas} from "../../../../../@WUM/core/component/const/DynamikModal";

import {useSelector} from "react-redux";
import {
    UniForm,
    IntlMessageValue, IntlMessage, InfoButton
} from "../../../../../@WUM/core/Elements/index";
import OfferPDF from "./pdf/offerPdf";
import {PDFDownloadLink } from '@react-pdf/renderer';
import UniFormControl from "../../../../../@WUM/core/Elements/Form/Elements/FormControl";

interface EmailCanvas {
    fetchAllProducts?: any
}

const EmailCanvas = (props:EmailCanvas) => {
    const Store = useSelector((state: any) => state)

    const handlesubmit = (e:any) => {
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);


    const handleShow = () => setShow(true);
    const CloseDynmaikModal = () => {
        handleClose();
    }




    return (
        <>
            <DynamikOffCanvas
                openButtonLabel={
                    <>
                        <IntlMessage Store={Store} messageId="billing.button.send"/>
                    </>
                    }
                saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                SaveID={'CreateNewProjects'}
                title={IntlMessageValue( 'de', 'offer.send.email', Store)}
                saveDisabled={true}
                ClassOpenButton={'float-end'}
                ClassSaveButton={'float-end'}
                body={
                    <>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'offer.send.receiver', Store)} />
                            <UniForm.FormControl name={'receiver'}
                                                 required={true}
                                                 type={'text'}
                            />
                            <InfoButton
                                text={IntlMessageValue('de', 'offer.send.receiver.explanation', Store)}
                                icon={'info'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'offer.send.cc', Store)}/>
                            <UniForm.FormControl name={'CC'}
                                                 required={true}
                                                 type={'text'}
                            />
                            <InfoButton
                                text={IntlMessageValue('de', 'offer.send.receiver.explanation', Store)}
                                icon={'info'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'offer.send.bcc', Store)}/>
                            <UniForm.FormControl name={'BCC'}
                                                 required={true}
                                                 type={'text'}
                            />
                            <InfoButton
                                text={IntlMessageValue('de', 'offer.send.receiver.explanation', Store)}
                                icon={'info'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'offer.email.template', Store)}/>
                            <UniForm.Select name={'template'} items={[{value: '', text: IntlMessageValue('de', 'billing.please.choose', Store)},
                                {
                                    value: '1',
                                    text: 'Standardvorlage'
                                }, {
                                    value: '2',
                                    text: 'Weko24'
                                }, {
                                    value: '3',
                                    text: 'Rabenbusiness'
                                }
                            ]}/>
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"mb-0"}>
                            <UniForm.InputGroupText text={IntlMessageValue('de', 'billing.subject', Store)}/>
                            <UniForm.FormControl name={'subject'}
                                                 required={true}
                                                 type={'text'}
                            />
                        </UniForm.InputGroup>
                        <UniForm.InputGroup class={"border rounded mb-3"}>
                            <UniForm.Label class={"px-3 p-1 m-0 bg-light w-100"} text={IntlMessageValue('de', 'offer.send.text', Store)}/>
                            <UniForm.FormControl name={'subject'}
                                                 required={true}
                                                 type={'textarea'}
                                                 as="textarea"
                                                 rows={10}
                                                 class={"w-100"}
                            />
                        </UniForm.InputGroup>

                        <UniForm.InputGroup class={"border rounded mb-3"}>
                            <UniForm.Label class={"px-3 p-1 m-0 bg-light w-100"} text={IntlMessageValue('de', 'offer.send.signature.personal', Store)}/>
                            <UniForm.FormControl name={'signaturePersonal'}
                                                 required={true}
                                                 type={'textarea'}
                                                 as="textarea"
                                                 rows={3}
                                                 class={"w-100"}
                            />
                        </UniForm.InputGroup>

                        <UniForm.InputGroup class={"border rounded mb-3"}>
                            <UniForm.Label class={"px-3 p-1 m-0 bg-light w-100"} text={IntlMessageValue('de', 'offer.send.signature.company', Store)}/>
                            <UniForm.FormControl name={'signatureCompany'}
                                                 required={true}
                                                 type={'textarea'}
                                                 as="textarea"
                                                 rows={3}
                                                 class={"w-100"}
                            />
                        </UniForm.InputGroup>

                        <div className={"w-100 border rounded  mb-3 "}>
                            <div>
                                <h6 className={"bg-light px-3 py-2 mb-2"}>Anhang</h6>
                                <PDFDownloadLink className={"px-3 py-2"} document={
                                    <OfferPDF data={''} layout={
                                        {
                                            "logo":"/webkom.png",
                                            "phone": "+49 171 7751453",
                                            "address": "Zwickauer Straße 480",
                                            "plz": "09117",
                                            "ownCompany": "WEBKOMMUNIKATION24 GmbH",
                                            "bankName": "Sparkasse Chemnitz",
                                            "IBAN": "DE18 8705 0000 0714 9839 93",
                                            "BIC": "CHEKDE81XXX",
                                            "web": "www.weko24.de",
                                            "mail": "info@weko24.de",
                                            "ceo": "Dr. Karin Rabe",
                                            "city": "Chemnitz",
                                            "register": "HRB 27243",
                                            "taxNumber": "215/122/04551",
                                            "taxId": "DE 281479345",
                                            "courtLoc": "Chemnitz",
                                        }
                                    } fetchAllProducts={props.fetchAllProducts} Store={Store} status={"final"}/>
                                } fileName="Angebot.pdf">Angebot.pdf</PDFDownloadLink>
                                <UniFormControl class={"px-3 py-2 mt-3"} id={'fileuploadAttachment1'} name={'file1'} type="file" accept=".pdf" required={false}/>
                            </div>

                        </div>


                    </>
                }
                handlesubmit={handlesubmit}
                handleShow={handleShow}
                show={show}
                setShow={setShow}
                handleClose={CloseDynmaikModal}

                />

            </>
    )
}

export default EmailCanvas