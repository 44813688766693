const dataColOneMwSTType = (newElement:any,response:any) =>  {
    return [
            {
                'formType': 'input',
                'type': 'hidden',
                'name': 'id',
                'value': (newElement === false ? response.id : '0'),
            },
            {
                'formType': 'input',
                'label': 'Schluessel',
                'type': 'text',
                'name': 'Schluessel',
                'value': (newElement === false ? response.Schluessel : ''),
            },
            {
                'formType': 'input',
                'label': 'Text',
                'type': 'text',
                'name': 'Text',
                'value': (newElement === false ? response.Text : ''),
            },
            {
                'formType': 'input',
                'label': 'standartwert',
                'type': 'text',
                'name': 'standartwert',
                'value': (newElement === false ? response.standartwert : ''),
            },
    ];
}

export default dataColOneMwSTType;
