import React from "react";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import home from "../elements/home";
import TabsConfigProps from "../../interface/TabsConfigProps.interface";

const TabsConfig = (props:TabsConfigProps) => {

    let config = [{
        tab: {defaultActiveKey:'home',id:'uncontrolled-tab-example',className:'mb-3',key:'tabindex'},
        tabs:[
            {eventKey:'home',title:'Einstellungen',key:'home',id:'home',class:'TabPanel',content:home(
                {response:props.response2,Store:props.Store})},
            {eventKey:'leistungen',title:'Leistungen',key:'leistungen',id:'leistungen',class:'TabPanel',content:home(
                    {response:props.response2,Store:props.Store})},
            {eventKey:'kundeninformationen',title:'Kundeninformationen',key:'kundeninformationen',id:'kundeninformationen',class:'TabPanel',content:home(
                    {response:props.response2,Store:props.Store})},
            {eventKey:'verlauf',title:'Verlauf',key:'verlauf',id:'verlauf',class:'TabPanel',content:home(
                    {response:props.response2,Store:props.Store})},
            {eventKey:'rechnungen',title:'Rechnungen',key:'rechnungen',id:'rechnungen',class:'TabPanel',content:home(
                    {response:props.response2,Store:props.Store})},
        ]
    }]

    return config;

}

export default TabsConfig;
