import '../css/dashboard.css'
import {useSelector} from "react-redux";
import {initialStatePeopleOverview, PeopleInterface} from "../../../products/interface/People.interface";
import {useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import {SearchJSX, SpinnerJSX} from "../../../../../@WUM/core/Elements/index";
import PaginationJSX from "../../../../../@WUM/core/form-serilizer/elements/pagination";
import {handleSucces} from "../../../../../@WUM/core/Function/index";
import {ResponsephoneOverview} from "../../API/response/phone";
import {DynamikOffCanvaspv} from "../../../../../@WUM/core/component/const/DynamikModal";
import PielersForm from "../../../../../@WUM/core/form-serilizer";
import Button from 'react-bootstrap/Button';
import Delete from "../../helper/Delete";
import BusinessCard from "../../helper/BusinessCard";
import AddedFunc from "../../helper/AddedFunc";
import errorForm from "../../helper/errorForm";
import Edit from "../../helper/Edit";
import dataColOne from "../configuration/dataColOne";
import AddDialogdynamik from "../../helper/AddDialogdynamik";
import Configuration from "../configuration/Configuration";
import handlesubmit from "../configuration/handlesubmit";
import sendEdit from "../../helper/sendEdit";
import successForm from "../../helper/successForm";
import successDeleted from "../../helper/succesDeleted";
import sendDeleted from "../../helper/sendDeleted";

/**
 *
 * @param props
 * @constructor
 */
const PhoneOverview = (props:PeopleInterface = initialStatePeopleOverview) => {
    const Store = useSelector((state: any) => state)
    const {page} = useParams<{page: string}>();
    const history = useNavigate();
    const namespace_search = "Phone_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    let limit: any;
    const destination = 'phone';
    if (typeof page === "string") { limit = (parseFloat(page) * 10) - 10; }
    const [fetchData, setFetchData] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [makeDelete, setMakeDelete] = useState(null);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const CloseDynmaikModal = () => { handleClose(); }
    const saveRoute = 'pv/phone/[param]/'
    const deletedRoute = 'pv/phone/[param]/'
    const editRoute = 'pv/phone/[param]/'
    let response = ResponsephoneOverview(limit, searchValue,fetchData);

    const successFormDynamik = (result:any) => {
        setFetchData(Date.now())
        handleClose() }

    const errorFormDynamik = (error:any)=> {
        handleClose()
    }

    const loadedDelete = (data:any) => {setMakeDelete(data);handleShowDelete(); }

    if (response !== undefined && response.length > 0) {
        return(
            <>
                <div className={'site'}>
                    <SearchJSX setSearchValue={setSearchValue}
                               Store={Store}
                               SearchConfig={Configuration(namespace_search,destination)}
                               indiButton={<Button variant="primary" className={'float-end'} onClick={handleShow}>
                                   <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                                        add
                                    </span>
                                    Telefonkontakt anlegen
                                </Button>}
                    />

                    <DynamikOffCanvaspv openButtonLabel={<></>}
                                      saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                                      closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                                      SaveID={'newphone'}
                                      title={'Neuen Telefonkontakt anlegen'}
                                      saveDisabled={false}
                                      ClassOpenButton={'float-end'} indiButton={''}
                                      ClassSaveButton={'float-end'}
                                      body={<>{PielersForm(dataColOne(true,response))}</>}
                                      handlesubmit={handlesubmit}
                                      errorForm={errorFormDynamik}
                                      successForm={successFormDynamik}
                                      setFetchData={setFetchData}
                                      handleShow={handleShow}
                                      saveRoute={saveRoute}
                                      show={show}
                                      setShow={setShow}
                                      handleClose={CloseDynmaikModal}
                    />
                    <br style={{clear: 'both'}}/>
                     <BusinessCard response={response}
                                   Edit={Edit}
                                   AddedFunc={AddedFunc}
                                   loadedDelete={loadedDelete}
                                   sendEdit={sendEdit}
                                   successForm={successForm}
                                   errorForm={errorForm}
                                   editRoute={editRoute}
                                   setFetchData={setFetchData}
                                   navigate={navigate}
                                   dataColOne={dataColOne} />
                    <Delete sendDeleted={sendDeleted}
                            makeDelete={makeDelete}
                            successDeleted={successDeleted}
                            handleCloseDelete={handleCloseDelete} deletedRoute={deletedRoute}
                            setFetchData={setFetchData} FieldMakeDelete={['name','id']}
                            showDelete={showDelete} />
                    <AddDialogdynamik />
                    <PaginationJSX response={response}
                                   history={history}
                                   to={'overview'}
                                   handleSuccess={handleSucces}
                                   destination={destination}/>

                </div>
            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default PhoneOverview;

