import React from 'react';
import {Page, Text, View, Document,Image} from '@react-pdf/renderer';
import {styles} from './css/offerPdf.css'
import FontsRegister from "../../../../../../PDFFonts/FontsRegister";
import {OfferPDFProps} from "./interface/OfferPDFProps.interface";
import {Layout} from "./interface/Layout.interface";
import {Data} from "./interface/Data.interface"
import {PdfFooter2} from "../../../../../components/Elements/pdf/PdfFooter2";
import {PdfProductTable, PdfProductTableHeader} from "../../../../../components/Elements/pdf/PdfProductTable";
import {PdfPricesOverview} from "../../../../../components/Elements/pdf/PdfPriceOverview";
import {PdfTextArea} from "../../../../../components/Elements/pdf/PdfTextArea";
import {PdfCompanyInfo} from "../../../../../components/Elements/pdf/PdfCompanyInfo";
import {PdfDateDetails} from "../../../../../components/Elements/pdf/PdfDateDetails";
import {PdfDraftSvg} from "../../../../../components/Elements/pdf/PdfDraftSvg";
import {CheckNull} from "../../../../billing/modules/Pages/new";
import {CheckSingleMwst} from "../../../../../components/functions/CheckSingleMwst";
import {formatNumberToDigits} from "../../../../../../@WUM/core/Function";

const options = {
    style: 'currency', // Währungsstil
    currency: 'EUR', // Währung (z. B. 'EUR', 'USD', 'CHF')
    minimumFractionDigits: 2, // Minimale Anzahl der Dezimalstellen
    maximumFractionDigits: 2, // Maximale Anzahl der Dezimalstellen
};
export const formatter = new Intl.NumberFormat('de-DE', options); // Formatierer für Deutsch (Deutschland)


const OfferPDF = (props: OfferPDFProps) => {

    FontsRegister(); //adds fonts

    let Data: Data = JSON.parse(localStorage.getItem('offer') as string)

    const layout:Layout = {
        logo: props.layout.logo,
        phone: props.layout.phone,
        address: props.layout.address,
        ownCompany: props.layout.ownCompany,
        plz: props.layout.plz,
        bankName: props.layout.bankName,
        IBAN: props.layout.IBAN,
        BIC: props.layout.BIC,
        web: props.layout.web,
        mail: props.layout.mail,
        city: props.layout.city,
        register: props.layout.register,
        taxNumber: props.layout.taxNumber,
        taxId: props.layout.taxId,
        ceo: props.layout.ceo,
        courtLoc: props.layout.courtLoc,
        type:props.layout.type
    }



    if(props.mode === 'detail') {

        let Offer = []



        let netto = 0
        let mwst =0
        let brutto = 0;
        if(props.data.pos !== undefined) {
            for(let pos = 0;pos < props.data.pos.length;pos++) {

                netto = netto + (props.data.pos[pos].einzelpreis * props.data.pos[pos].menge * (100-props.data.pos[pos].rabatt)/100)
                let total:any = (props.data.pos[pos].einzelpreis * props.data.pos[pos].menge * (100-props.data.pos[pos].rabatt)/100).toFixed(2)
                mwst = mwst  + (total/100*(props.fetchAllMwST?.find((item: { id: number; }) => item.id === parseInt(props.data.pos[pos].article.mwsttyp))?.standartwert))
                    Offer.push(
                    {
                        invoiceAmount: props.data.pos[pos].menge,
                        invoiceProduct: props.data.pos[pos].artikelId,
                        invoiceDescription: props.data.pos[pos].text,
                        invoiceDiscount: props.data.pos[pos].rabatt,
                        invoicePrice: props.data.pos[pos].einzelpreis,
                        invoiceMWST: props.fetchAllMwST?.find((item: { id: number; }) => item.id === parseInt(props.data.pos[pos].article.mwsttyp))?.standartwert,
                        invoiceArticleNumber: props.data.pos[pos].artikelNr,
                        invoiceTotal: total,
                        productUnit: props.data.pos[pos].article.einheit
                    }
                )
            }
        }

        brutto = (netto + mwst);
        // shows brutto value
        // console.log(formatter.format(brutto))
        Data  = {
            companyName: props.data.customer.customer.firma,
            companyAddress:  props.data.customer.customer.address.strasse,
            companyCity:  props.data.customer.customer.address.location.PLZ + ' '+ props.data.customer.customer.address.location.Ort ,
            companyOwner:  props.data.customer.customer.geschaeftsfuehrer.trim(),
            offerNumber:  formatNumberToDigits(props.data.offerNumber),
            customerNumber:  formatNumberToDigits(props.data.customer.customer.id),
            offerDate:  props.data.date,
            offerValid:  props.data.valid,
            offerPaymentAim:  props.data.paymentAim,
            copyOfferNumber: 'Angebot ' + props.data.offerNumber,
            offerSubject:  props.data.offerSubject,
            offerIntro: props.data.intro,
            offerPriceNetto:  formatter.format(netto),
            offerVat:  formatter.format(mwst),
            offerPriceBrutto:  formatter.format(brutto),
            offerComment:  props.data.comment,
            offerPaymentType:  CheckNull(props.data.zahlungsart,'id'),
            offerPaymentMethod:  '',
            closingSentence:  CheckNull(props.data.zahlungsart,'schlusssatz'),
            offerPaymentLocation:  props.data.paymentLocation,
            offerPaymentSignatureDate:  props.data.date,
            clientSignature:  props.data.clientSignature,
            contractorSignature:  props.data.contractorSignature,
            offer: Offer,
            acceptAgb:  props.data.acceptAgbe,
            acceptDataProtect:  props.data.acceptDataProtect,
        }

    }
    const subject = Data.offerSubject;
    const pretext = Data.offerIntro;
    const copyOfferNumber = Data.copyOfferNumber;

    console.log(subject)

    //console.log(Data.closingSentence) //shows closing sentence
    //console.log(companyOwner) //shows company owner from layout
    //console.log(companyName) //shows company name from layout
    let offer = Data.offer;
    const allMWSTSame = CheckSingleMwst({ offer }) === true; // Call CheckSingleMwst and store the result

    return (
        <Document>

            <Page size="A4" style={styles.page}>

                <View
                    style={[styles.row, {marginTop: -30}]}
                    fixed={true}
                >
                    <View
                        style={styles.columnsHeader}
                    >
                        <PdfCompanyInfo Data={Data} layout={layout}/>
                        <PdfDateDetails layout={layout} Data={Data} type={"offer"} Store={props.Store} status={props.status}/>
                    </View>
                </View>

                <Text style={styles.subject}>
                    {(Data.offerSubject !== null && Data.offerSubject !== undefined && Data.offerSubject !== '' ?
                    <>{subject}</> :
                    <></>)}
                </Text>
                <View style={styles.marginBottomStandard}>
                    <Text style={styles.text}>
                        {pretext}
                    </Text>
                </View>

                <View style={styles.table}>
                    {/*{BuildOfferTable()}*/}
                    <PdfProductTableHeader Data={Data} allMWSTSame={allMWSTSame}/>
                    <PdfProductTable fetchAllProducts={props.fetchAllProducts} Data={Data} allMWSTSame={allMWSTSame}/>
                </View>

                <PdfPricesOverview Data={Data} type={"offer"} mode={props.mode}/>
                <PdfTextArea Data={Data} type={"offer"} Store={props.Store} PaymentMethod={props.PaymentMethod}/>

                <PdfFooter2 layout={layout}/>
                {/*<Image
                       src="/pdf-images/formb.png"
                       style={{
                           width: '595',
                           height: '842',
                           position: "absolute",
                           left: 0,
                           top: 0,
                           marginTop: -20,
                       }}/>*/}
                <PdfDraftSvg status={props.status}/>
            </Page>
        </Document>
    )
};
export default OfferPDF


//const CheckValue = (value:any) => {
//    return (value !== null && value !== undefined && value !== '' ? value : '')
//}

