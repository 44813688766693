/**
 * Returns the current date formatted in German locale (DD-MM-YYYY).
 *
 * @returns The formatted date string in DD-MM-YYYY format with leading zeros.
 */
export const TodayGerman = () => {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = today.getFullYear();

    return `${day}.${month}.${year}`;
};