

export interface configszrType {
  "id": number,
  duration:string;
    Text: string;
  title:string;
}

export function outputszrType(Data: any) {
  let back = {
    id: Data.id,
    duration:Data.duration,
    durationtype: Data.durationtype,
    title:Data.title
  }

  return back;
}
