const dataColOneszrType = (newElement:any,response:any) =>  {
    return [
        {
            'formType': 'input',
            'type': 'hidden',
            'name': 'id',
            'value': (newElement === false ? response.id : '0'),
        },
        {
            'formType': 'multiply',
            'cols': [
                {
                    'formType': 'input',
                    'label': 'Dauer',
                    'type': 'number',
                    'name': 'duration',
                    'value': (newElement === false ? response.duration : ''),
                },
                {
                    'formType': 'select',
                    'label': 'Einheit',
                    'name': 'durationtype',
                    'selected': (newElement === false ? response.durationtype : 0),
                    'options': [
                        {
                            'label': '',
                            'value': '0'
                        },
                        {
                            'label': 'Jahr',
                            'value': '1'
                        },
                        {
                            'label': 'Monat',
                            'value': '2'
                        },
                        {
                            'label': 'Tag',
                            'value': '3'
                        },
                        {
                            'label': 'Woche',
                            'value': '4'
                        }]
                }
            ],

        },
        {
            'formType': 'input',
            'label': 'Bezeichnung',
            'type': 'text',
            'name': 'title',
            'value': (newElement === false ? response.title : ''),
        }
    ];
}

export default dataColOneszrType;
