const CloseMember = (mode?:any,modeID?:any) => {
    if(mode !== undefined && mode !== null) {
        let Dialogs = document.getElementById((modeID !== undefined && modeID !== null && modeID !== '' ? modeID :'addDialogInternalConnection')) as HTMLDivElement;
        Dialogs.classList.remove("d-none");
        Dialogs.style.display = 'none';
    } else {
        let Dialog = document.getElementById('addDialogMember') as HTMLDivElement;
        Dialog.classList.remove("d-none");
        Dialog.style.display = 'none';
    }

}

export default CloseMember;
