import ReactDOM from "react-dom";

const AddedFunc = (form?:any,footer?:any,mode?:any,modeID?:any) => {



    if(mode !== undefined && mode !== null) {
        let Dialogs = document.getElementById((modeID !== undefined && modeID !== null && modeID !== '' ? modeID :'addDialogInternalConnection')) as HTMLDivElement;
        Dialogs.classList.add("show");
        Dialogs.style.display = 'block';
    } else {
        let Dialog = document.getElementById('addDialogdynamik') as HTMLDivElement;
        Dialog.classList.add("show");
        Dialog.style.display = 'block';
        if(form !== undefined) {
            let newElement = form
            let temp = document.createElement('div')
            ReactDOM.render(newElement, temp)
            let ele = document.getElementById('injectModalTask') as HTMLDivElement
            if(ele !== null) {
                ele.innerHTML = ''
                ele.prepend(temp)
            }
        }
    }
}

export default AddedFunc;
