import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {stylesOverview as styles} from "./css/PdfPricesOverview.css";
import {formatFloattoCurrency} from "../../functions/IntlNumberFormat";

interface PdfPricesOverviewInterface {
    Data: any
    type: string | "invoice" | "offer"
    mode?:'detail' | 'new'
}



export const PdfPricesOverview = (props:PdfPricesOverviewInterface) => {


    /*const priceData = props.type === 'offer' ? props.Data.offerPrice : props.Data.invoicePrice;

    const priceNetto = priceData.priceNetto;
    const mwst = priceData.vat;
    const priceBrutto = priceData.priceBrutto;*/

    let priceNetto;
    let mwst;
    let priceBrutto;


    if (props.type === 'offer') {
        priceNetto = props.Data.offerPriceNetto;
        mwst = props.Data.offerVat;
        priceBrutto = props.Data.offerPriceBrutto;
    } else if (props.type === 'invoice') {
        priceNetto = props.Data.invoicePriceNetto;
        mwst = props.Data.invoiceVat;
        priceBrutto = props.Data.invoicePriceBrutto;
    } else {
        // Handle the case where props.type is neither 'offer' nor 'invoice'
        console.warn('Unknown type for pricing data. Please specify "offer" or "invoice" in props.type');
        // You can also set default values for priceNetto, mwst, and priceBrutto here in this else block
    }

    return(
        <>

            <View style={[styles.overviewParent, {marginTop: 20}]}>
                <Text style={styles.overview}>
                    Preis Netto:
                </Text>
                <Text style={styles.overview}>
                    {props.mode === 'detail' ? priceNetto : formatFloattoCurrency(priceNetto)}
                </Text>
            </View>
            <View style={styles.overviewParent}>
                <Text style={styles.overview}>
                    MwSt. 19%:
                </Text>
                <Text style={styles.overview}>
                    {props.mode === 'detail' ? mwst : mwst+' €'}

                </Text>
            </View>
            <View style={[styles.overviewParent, {marginBottom: 20}]}>
                <Text style={styles.overview}>
                    Preis Brutto:
                </Text>
                <Text style={styles.highlight}>
                    {props.mode === 'detail' ? priceBrutto : formatFloattoCurrency(priceBrutto)}
                </Text>
            </View>

        </>
    )
}