import {DocumentTitleProps} from "./DocumentTitle.interface";
import { DocumentType } from "../interfaces/Documenttypes";
import { DocumentStatus } from "../interfaces/DocumentStatus";


/**
 * Function that generates the document title based on its type and status.
 *
 * @param {Props} props - The component props containing document type and status information.
 *
 * @returns {string} The generated document title.
 */
export const getDocumentTitle = (props: DocumentTitleProps): string => {
    const baseTitles: Record<DocumentType, string> = {
        invoice: "Rechnung",
        storno: "Storno",
        secondCopy: "Zweitschrift",
        secondCopyCopy: "Zweitschriftkopie",
        copy: "Rechnungskopie",
        offer: "Angebot",
    };

    const statusSuffixes: Record<DocumentStatus, string> = {
        final: "",
        draft: "entwurf",
        "": "", // Empty string key-value pair
    };

    // Check if both type and status are present
    if (!props.type || !props.status) {
        return ""; // Return empty string if missing props
    }

    return `${baseTitles[props.type]}${statusSuffixes[props.status]}`;
};
