interface CheckDuplicatesProps {
    data: any;
    testFor: string;
}

export const checkDuplicates = (props: CheckDuplicatesProps) => {
    if (props.testFor === "boolean") {
        const uniqueValues = new Set(Object.values(props.data));
        return uniqueValues.size === 1;
    } else if (props.testFor === "value") {
        const values = Object.values(props.data);
        const uniqueValues = new Set(values); // Create Set to check for duplicates
        // print all unique values
        //console.log("unique values", uniqueValues)
        return uniqueValues.size === 1 ? uniqueValues : values; // Check if there is only one value
    } else {

        throw new Error(`Invalid testFor value: ${props.testFor}`); // Handle invalid testFor
    }
};
