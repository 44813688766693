import {initialStatePeopleNew, PeopleNewInterface} from "../../interface/People.interface";
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import Table from 'react-bootstrap/Table';
import {
    SpinnerJSX,
    UniRow,
    UniButton,
    UniCol,
    UniForm,
    IntlMessageValue, IntlMessage
} from "../../../../../@WUM/core/Elements/index";
import {
    Responsebuytype,
    ResponsefetchAllProducts,
    ResponseMwST,
    Responsepricetype,
    Responseservicetimetype,
    ResponsefetchAllCustomers
} from "../../API/response/people";
import ReactDOM from "react-dom";
import { FormControl, InputGroup } from 'react-bootstrap'
import setCustomer from "../../../../components/functions/setCustomer";
import {AddPosition} from "../../../../components/functions/addPosition";
import ProductTable from "../../../../components/Elements/productTable";
import {HandleSidebarCollapse} from "../../../../components/functions/CollapseSidebarIfSmall";
import {useNavigate} from "react-router-dom";


const PeopleNew = (props:PeopleNewInterface=initialStatePeopleNew) => {

    const Data = JSON.parse(localStorage.getItem('invoice') as string)

    const [menuCollapse, setMenuCollapse] = useState(false);

    const { HandleSidebarCollapse:any } = HandleSidebarCollapse({
        menuCollapse,
        setMenuCollapse,
    });

    // set a table to collapse or not using bootstrap
    const [isCollapsed, setIsCollapsed] = useState(true);


    const [validated, setValidated] = useState(false);
    const Store = useSelector((state: any) => state)
    let MwST = ResponseMwST()
    let buytype = Responsebuytype()
    let pricetype = Responsepricetype()
    let servicetimetype = Responseservicetimetype()
    let fetchAllProducts = ResponsefetchAllProducts();
    let fetchAllCustomers = ResponsefetchAllCustomers();
    const [tableid, setTableid] = useState(2)

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/invoices/preview/1`;
        navigate(path);
    }

    if (MwST !== undefined && MwST.length > 0
        && buytype !== undefined && buytype.length > 0
        && pricetype !== undefined && pricetype.length > 0
        && servicetimetype !== undefined && servicetimetype.length > 0
        && fetchAllProducts !== undefined && fetchAllProducts.length > 0) {

        let ProductHolder:any = [];
        ProductHolder.push(
            {value: '', text: IntlMessageValue('de', 'billing.please.choose', Store)}
        )
        for(let p=0;p<fetchAllProducts.length;p++) {
            ProductHolder.push(
                {value: fetchAllProducts[p].id, text: fetchAllProducts[p].bezeichnung}
            )
        }

        const setInvoiceNumber = (e: any) => {
            let offerNumber = (document.getElementById('invoiceNumber') as HTMLInputElement)
            let offerNumberCopy = (document.getElementById('copyInvoiceNumber') as HTMLInputElement)
            offerNumberCopy.value = offerNumber.value
        }

        let customerHolder: any = [];
        for (let c = 0; c < fetchAllCustomers.length; c++) {
            const customer = fetchAllCustomers[c];

            customerHolder.push({
                value: customer.id,
                text: customer.firma,
                companyName: customer.firma,
                companyOwner: customer.geschaeftsfuehrer,
                companyAddressValue: customer.address?.strasse || "",
                companyCityValue: customer.address?.location?.Ort || "",
                companyZip: customer.address?.location?.PLZ || "",
            });
        }

        let buytypeHolder:any = [];
        buytypeHolder.push(

        )
        for(let p=0;p<buytype.length;p++) {
            buytypeHolder.push(
                {value: buytype[p].id, text: buytype[p].bez, closing: buytype[p].schlusssatz}
            )
        }

        let buytypeClosing = [];
        buytypeClosing.push(

        )
        for(let p=0;p<buytype.length;p++) {
            buytypeClosing.push(
                {value: buytype[p].id, text: buytype[p].schlusssatz}
            )
        }

        const setClosingSentence = (paymentType:any) => {

            let closingSentenceValue = (document.getElementById('closingSentence') as HTMLInputElement)
            let trigger = false;
            for(let b =0;b<buytypeHolder.length;b++) {
                if(parseInt(paymentType.target.value) == buytype[b].id) {
                    console.log(buytype[b])
                    closingSentenceValue.value = buytype[b].schlusssatz
                    trigger = true
                }
            }
            if(trigger === false) {
                closingSentenceValue.value = ''
            }

        }


        let servicetimetypeHolder = [];
        servicetimetypeHolder.push(

        )
        for(let p=0;p<servicetimetype.length;p++) {
            servicetimetypeHolder.push(
                {value: servicetimetype[p].id, text: servicetimetype[p].title}
            )
        }

        const send = (e: any) => {


            e.preventDefault()
            let data = e.currentTarget

            var formData = new FormData(data)
            let entries = formData.entries();
            let formDataObject: any = {};

            for (const [key, value] of formData.entries()) {
                formDataObject[key] = value;
            }

            let datas = MultiFormNameSpace({
                entries: entries,
                delimiter: ['offer', 'tffer'],
                lastPoint: 'invoiceTotal',
                part: 'offer'
            })
            formDataObject['offer'] = datas;
            /*console.log(formDataObject)*/
            localStorage.setItem('offer', JSON.stringify(formDataObject) as string)

            /*let raw = JSON.stringify(formDataObject)

           var requestOptions = {
               method: 'POST',
               headers: {
                   'Content-Type': 'application/json',
                   Authorization:
                       'Bearer ' +
                       JSON.parse(localStorage.getItem('token') as string)
               },
               body: raw
           }

           fetch(ApiRoot()+"yoptimize/Task/dashboard/?like=webkom24", requestOptions)
               .then(response => response.text())
               .then(result => {
                   localStorage.setItem('offer', JSON.stringify(formDataObject) as string)
                   navigate(`/offer/preview/1`)
               })
               .catch(error => console.log('error', error));*/

        }

        const sendPreview = () => {
            const data = document.getElementById('Form001') as HTMLFormElement;
            const formData = new FormData(data);
            let entries = formData.entries();
            let formDataObject: any = {};

            for (const [key, value] of formData.entries()) {
                formDataObject[key] = value;
            }
            let datas = MultiFormNameSpace({
                entries: entries,
                delimiter: ['offer', 'tffer'],
                lastPoint: 'invoiceTotal',
                part: 'offer'
            })
            formDataObject['offer'] = datas;

            /*let raw = JSON.stringify(formDataObject)

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot()+"yoptimize/Task/dashboard/?like=webkom24", requestOptions)
                .then(response => response.text())
                .then(result => {
                    localStorage.setItem('offer', JSON.stringify(formDataObject) as string)
                    navigate(`/offer/preview/1`)
                })
                .catch(error => console.log('error', error));*/

            localStorage.setItem('invoice', JSON.stringify(formDataObject) as string)
            navigate(`/invoices/preview/1`)

        }


        return(
        <>
            <div className={"mx-5"}>
                <UniForm id={'Form001'}  validated={validated} Submit={(e) => send(e)}>
                    <UniRow class="justify-content-between pb-5">
                        <UniCol xxl={3} xl={4} lg={5}>
                            <img
                                src={process.env.REACT_APP_LOGO_IMG}
                                height="100px"
                                alt="Logo"
                            />
                            <div>
                                <UniForm.InputGroup>
                                    <UniForm.InputGroupText
                                        text={IntlMessageValue('de', 'billing.customer', Store)}
                                        class={"mb-0"}/>
                                    <UniForm.Select freeValue={true} freeValueLabel={'Bitte Wählen'} onChange={(e) => setCustomer(e.target.value, customerHolder)}
                                                    items={customerHolder}
                                                    class={"mb-0"}/>
                                </UniForm.InputGroup>
                                <UniForm.FormControl
                                    type={'text'}
                                    id={'companyName'}
                                    name={'companyName'}
                                    defaultValue={CheckLocalisSet(Data,'companyName')}
                                    placeholder={''}
                                    readOnly={true}
                                />
                                <UniForm.FormControl
                                    type={'text'}
                                    id={'companyOwner'}
                                    name={'companyOwner'}
                                    defaultValue={CheckLocalisSet(Data,'companyOwner')}
                                    placeholder={''}
                                    readOnly={true}
                                />
                                <UniForm.FormControl
                                    type={'text'}
                                    id={'companyAddress'}
                                    name={'companyAddress'}
                                    defaultValue={CheckLocalisSet(Data,'companyAddress')}
                                    placeholder={''}
                                    readOnly={true}
                                />
                                <UniForm.FormControl
                                    type={'text'}
                                    id={'companyCity'}
                                    name={'companyCity'}
                                    defaultValue={CheckLocalisSet(Data,'companyCity')}
                                    placeholder={''}
                                    readOnly={true}
                                />
                            </div>
                        </UniCol>
                        <UniCol xxl={4} xl={5} lg={6}>
                            <UniRow class={"pt-5"}>
                                <UniForm.InputGroup class={"mb-0"}>
                                    <UniForm.InputGroupText
                                        text={IntlMessageValue('de', 'billing.invoice.number', Store)}/>
                                    <UniForm.FormControl name={'invoiceNumber'} readOnly={true}
                                                         type={'text'} id={'invoiceNumber'}
                                                         defaultValue={CheckLocalisSet(Data,'invoiceNumber')}
                                                         onChange={setInvoiceNumber}/>
                                </UniForm.InputGroup>
                                <UniForm.InputGroup class={"mb-0"}>
                                    <UniForm.InputGroupText
                                        text={IntlMessageValue('de', 'billing.customer.number', Store)}/>
                                    <UniForm.FormControl id={'customerNumber'} name={'customerNumber'} required={true}
                                                         readOnly={true} defaultValue={CheckLocalisSet(Data,'customerNumber')}
                                                         type={'text'}/>
                                </UniForm.InputGroup>
                                <UniForm.InputGroup class={"mb-0"}>
                                    <UniForm.InputGroupText text={IntlMessageValue('de', 'billing.date', Store)}/>
                                    <UniForm.FormControl name={'invoiceDate'} required={true} defaultValue={CheckLocalisSet(Data,'invoiceDate')}
                                                         type={'date'} id={'invoiceDate'}/>
                                </UniForm.InputGroup>
                                <UniForm.InputGroup class={"mb-0"}>
                                    <UniForm.InputGroupText
                                        text={IntlMessageValue('de', 'billing.periodofperformance', Store)}/>
                                    <UniForm.FormControl name={'invoicePerfStart'} required={true}
                                                         type={'date'} id={'invoicePerfStart'} defaultValue={CheckLocalisSet(Data,'invoicePerfStart')}/>
                                    <UniForm.FormControl name={'invoicePerfEnd'} required={true}
                                                         type={'date'} id={'invoicePerfEnd'} defaultValue={CheckLocalisSet(Data,'invoicePerfEnd')}/>
                                </UniForm.InputGroup>
                                <UniForm.InputGroup class={"mb-0"}>
                                    <UniForm.InputGroupText
                                        text={IntlMessageValue('de', 'billing.payment.aim', Store)}/>
                                    <UniForm.FormControl name={'billingPaymentAim'} required={true}
                                                         type={'date'} id={'billingPaymentAim'} defaultValue={CheckLocalisSet(Data,'billingPaymentAim')}/>
                                </UniForm.InputGroup>

                            </UniRow>
                        </UniCol>
                    </UniRow>
                    <UniForm.InputGroup class={"pb-5"}>
                        <UniForm.FormControl name={'copyInvoiceNumber'}
                                             required={true}
                                             type={'text'}
                                             readOnly={true}
                                             class={"col-2 h-100 h4"}
                                             id={'copyInvoiceNumber'}
                                             defaultValue={CheckLocalisSet(Data,'copyInvoiceNumber')}
                        />
                        <UniForm.FormControl name={'billingSubject'}
                                             required={true}
                                             type={'text'}
                                             placeholder={IntlMessageValue('de', 'billing.subject', Store)}
                                             class={"h4 fw-bolder"}
                                             id={'billingSubject'}
                                             defaultValue={CheckLocalisSet(Data,'billingSubject')}
                        />
                    </UniForm.InputGroup>
                    <UniForm.InputGroup>
                        <UniForm.FormControl name={'billingIntro'}
                                             required={true}
                                             type={'textarea'}
                                             placeholder={IntlMessageValue('de', 'billing.start.text', Store)}
                                             as="textarea" rows={4}
                                             id={'billingIntro'}
                                             defaultValue={CheckLocalisSet(Data,'billingIntro')}
                        />
                    </UniForm.InputGroup>

                    <Table striped bordered hover responsive="xl" id={"invoice-table"}>
                        <thead>
                        <tr>
                            <th><IntlMessage Store={Store} messageId="billing.position"/></th>
                            <th className={`col-1`}>
                                <IntlMessage Store={Store} messageId="billing.article.number"/>
                            </th>

                            <th className={"col-4"}><IntlMessage Store={Store} messageId="billing.product.service"/>
                            </th>
                            <th className={"col-1"}><IntlMessage Store={Store} messageId="billing.number"/></th>
                            <th className={"col-1"}><IntlMessage Store={Store} messageId="billing.discount"/></th>
                            <th className={"col-1"}>
                                <IntlMessage Store={Store} messageId="billing.unit"/>
                            </th>
                            <th className={"col-1"}><IntlMessage Store={Store} messageId="billing.singleprice"/>
                            </th>
                            <th className={"col-1"}><IntlMessage Store={Store} messageId="billing.vat.price"/></th>
                            <th className={"col-2"}><IntlMessage Store={Store} messageId="billing.sum"/></th>
                        </tr>
                        </thead>
                        <tbody id={"productTable2"}>
                        <SetProduktHolder Data={Data} Store={Store} setFetchData={''} fetchAllProducts={fetchAllProducts} MwST={MwST} isCollapsed={isCollapsed} />
                        </tbody>
                    </Table>
                    <UniRow>
                        <UniCol>
                            <UniButton class={"m-0"} onClick={(e) => AddPosition({
                                tableid: 1,
                                Store: Store,
                                MwST: MwST,
                                fetchAllProducts: fetchAllProducts,
                                isCollapsed: isCollapsed,
                                type: "invoice"
                            })}
                                       type={"button"}>
                                < IntlMessage Store={Store} messageId="billing.button.addposition"/>
                            </UniButton>
                        </UniCol>
                        <UniCol lg={3}>
                            <UniForm.InputGroup class={"pe-0 ms-auto"}>
                                <UniForm.InputGroupText
                                    text={IntlMessageValue('de', 'billing.price.netto', Store)}/>
                                <UniForm.FormControl class={"text-end"} name={'invoicePriceNetto'} required={true}
                                                     id={'offerPriceNetto'} readOnly={true}
                                                     defaultValue={CheckLocalisSet(Data,'invoicePriceNetto')}
                                                     type={'string'}/>
                                <UniForm.InputGroupText text={IntlMessageValue('de', 'billing.euro', Store)}/>
                            </UniForm.InputGroup>
                            <UniForm.InputGroup class={"pe-0 ms-auto"}>
                                <UniForm.InputGroupText text={IntlMessageValue('de', 'billing.vat', Store)}/>
                                <UniForm.FormControl class={"text-end"} name={'invoiceVat'} required={true}
                                                     defaultValue={CheckLocalisSet(Data,'invoiceVat')}
                                                     type={'string'} id={'offerVat'} readOnly={true}/>
                                <UniForm.InputGroupText text={IntlMessageValue('de', 'billing.euro', Store)}/>
                            </UniForm.InputGroup>
                            <UniForm.InputGroup class={"pe-0 ms-auto pb-5"}>
                                <UniForm.InputGroupText class={"fw-bolder"}
                                                        text={IntlMessageValue('de', 'billing.price.brutto', Store)}/>
                                <UniForm.FormControl class={"fw-bolder text-end"} name={'invoicePriceBrutto'}
                                                     required={true}
                                                     defaultValue={CheckLocalisSet(Data,'invoicePriceBrutto')}
                                                     type={'string'} readOnly={true}
                                                     id={'offerPriceBrutto'}/>
                                <UniForm.InputGroupText class={"fw-bolder text-end"}
                                                        text={IntlMessageValue('de', 'billing.euro', Store)}/>
                            </UniForm.InputGroup>
                        </UniCol>
                    </UniRow>

                    <UniForm.InputGroup>
                        <UniForm.Label class={"col-12"} text={IntlMessageValue('de', 'billing.comment', Store)}/>
                        <UniForm.FormControl name={'billingComment'}
                                             required={true}
                                             type={'textarea'}
                                             defaultValue={CheckLocalisSet(Data,'billingComment')}
                                             as="textarea" rows={4}
                                             id={'billingComment'}
                        />
                    </UniForm.InputGroup>
                    <UniRow>
                        <UniCol>
                            <UniForm.InputGroup class={"mb-0"}>
                                <UniForm.InputGroupText text={IntlMessageValue('de', 'billing.payment.type', Store)}/>
                                <UniForm.Select name={'invoicePaymentType'} required={true} items={buytypeHolder}
                                                onChange={setClosingSentence} id={'invoicePaymentType'}
                                                defaultValue={CheckLocalisSet(Data,'invoicePaymentType')}
                                />
                            </UniForm.InputGroup>
                        </UniCol>
                        <UniCol>
                            <UniForm.InputGroup class={"mb-0"}>
                                <UniForm.InputGroupText text={IntlMessageValue('de', 'billing.payment.method', Store)}/>
                                <UniForm.Select name={'invoicePaymentMethod'} required={true}
                                                items={servicetimetypeHolder} id={'invoicePaymentMethod'}
                                                defaultValue={CheckLocalisSet(Data,'invoicePaymentMethod')}/>
                            </UniForm.InputGroup>
                        </UniCol>
                    </UniRow>
                    <UniForm.InputGroup>
                        <UniForm.Label class={"col-12"} text={IntlMessageValue('de', 'billing.additional', Store)}/>
                        <UniForm.FormControl name={'billingExtraComment'}
                                             required={true}
                                             type={'textarea'}
                                             defaultValue={CheckLocalisSet(Data,'billingExtraComment') !== '' ? CheckLocalisSet(Data,'billingExtraComment') : 'Vertragslaufzeit / Zusätzliche Anmerkungen / Ergänzungen: \n' +
                                                 'Der Vertragspartner stellt entsprechendes Bild- und Textmaterial (z.B. Logos) zur Verfugung. Hiermit bestelle ich oben gekennzeichnete Leistungen. Bitte buchen Sie die fälligen Beträge von o.g. Konto ab. Alle Preise verstehen sich zzgl. der gesetzlichen Mehrwertsteuer. \n' +
                                                 'Die Vertragslaufzeit beträgt 48 Monate. \n' +
                                                 'Es gelten ausschließlich unsere ausgehändigten Allgemeinen Geschaftsbedingungen, die wesentlicher Bestandteil des Vertrages sind und im Erstgesprach besprochen und akzeptiert worden sind.'}
                                             as="textarea" rows={5}
                        />
                    </UniForm.InputGroup>

                    <div className={"pb-5"}>
                        <h6><IntlMessage Store={Store} messageId="billing.final.sentence"/></h6>

                        <UniForm.FormControl
                            type={'text'}
                            id={'closingSentence'}
                            name={'closingSentence'}
                            placeholder={''}
                            readOnly={true}
                        />
                    </div>
                    <div className={"col-6 ms-auto justify-content-end d-flex"}>
                        <UniButton class={"m-2"} type={"submit"}>
                            <IntlMessage Store={Store} messageId="billing.button.save"/>
                        </UniButton>
                        <UniButton class={"m-2"} onClick={() => sendPreview()}>
                            <IntlMessage Store={Store} messageId="billing.button.preview"/>
                        </UniButton>
                    </div>
                </UniForm>
            </div>
        </>
    )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default PeopleNew;

interface MultiForm {
    entries: any,
    delimiter: any,
    lastPoint: string,
    part: string,

}

export const MultiFormNameSpace = (props: MultiForm) => {

    const isIntString = (str: string) => {
        const parsedInt = parseInt(str, 10);
        return !isNaN(parsedInt) && str === parsedInt.toString();
    }

    const checkType = (value: any) => {
        if (!isIntString(value)) {
            return (value === '' ? null : `"${value}"`);
        } else {
            return (value === '' ? null : value)
        }
    }

    let integer = '0';
    let OldData = [];

    // For beginn

    let offerTMP = '[{'


    for (const [key, value] of props.entries) {
        for (let d = 0; d < props.delimiter.length; d++) {

            if (key.includes(`${props.delimiter[d]}[`)) {
                let first = key.split(']')[0]
                let second = key.split(']')[1]

                let firstsec = first.split('[')[1]
                let secondsec = second.split('[')[1]
                if (firstsec === integer) {
                    if (secondsec === props.lastPoint) {
                        offerTMP = offerTMP + `"${secondsec}":${checkType(value)}`
                    } else {
                        offerTMP = offerTMP + `"${secondsec}":${checkType(value)},`
                    }
                } else {
                    integer = firstsec;
                    if (secondsec === props.lastPoint) {
                        offerTMP = offerTMP + `"${secondsec}":${checkType(value)}`
                    } else {
                        offerTMP = offerTMP + `},{"${secondsec}":${checkType(value)},`
                    }

                }
            }

        }
    }

    offerTMP = offerTMP + '}]'


    return JSON.parse(offerTMP)

}

export const CheckNull = (part:any,value:any) => {
    if(part !== undefined && part !== null ) {
        return part[value]
    } else {
        return ''
    }
}


export const CheckLocalisSet = (LS:any,value:any,value2?:any,value3?:any) => {



    if(LS !== undefined && LS !== null ) {
        if(value2 !== undefined && value3 !== undefined) {

            if (LS[value][value2][value3] !== undefined) {
                return LS[value][value2][value3]
            } else {
                return '';
            }
        } else if(value2 !== undefined && value3 === undefined){
            if (LS[value][value2] !== undefined) {
                return LS[value][value2]
            } else {
                return '';
            }
        } else if(value2 === undefined && value3 === undefined) {
            if (LS[value] !== undefined) {
                return LS[value]
            } else {
                return '';
            }
        }
    } else {
        return ''
    }
}

export const SetProduktHolder = (props:{Data:any,Store:any,fetchAllProducts:any,MwST:any,isCollapsed:any,setFetchData:any}) =>{
    let ProductTableHolder = [];

    if(props.Data === null) {
        ProductTableHolder.push(
            <> <tr className={'offertd'}>
                <ProductTable tableid={1} LS={props.Data} Store={props.Store} fetchAllProducts={props.fetchAllProducts}
                              MwST={props.MwST} isCollapsed={props.isCollapsed} setFetchData={props.setFetchData}/>
            </tr>
            </>
        )
    }else {
        for(let x=0;x < props.Data.offer.length;x++) {
            ProductTableHolder.push(
                <>
                    <tr id={x+1+"tr"} className={'offertd'}>
                        <ProductTable tableid={x+1} LS={props.Data} Store={props.Store} fetchAllProducts={props.fetchAllProducts}
                                      MwST={props.MwST} isCollapsed={props.isCollapsed} setFetchData={props.setFetchData}/>
                    </tr>
                </>
            )
        }
    }

    return(
        <>
            {ProductTableHolder}
        </>
    )
}