import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import {ResponseAllCPS, ResponseAllLabels, ResponseAllPrio} from "../../../API/response/ticket";
import React, {useState} from "react";
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import {notify_save} from "../../../../../@WUM/core/component/const/notification.service";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {AddedColor, savearchiv} from "./MyTask";
import ReactDOM from "react-dom";
import {FetchwithMattermostOnlysetFetchData} from "../components/Mattermost";
import setNavi from "../../../../../@WUM/core/component/const/setNavi";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {collapse} from "./SingleTask";
import Card from 'react-bootstrap/Card';
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import {useSelector} from "react-redux";
const CPS = (value:any) => {
    const [fetchData, setFetchData] = useState(0);
    const Store = useSelector((state: any) => state)
    const AllCPS = ResponseAllCPS(fetchData);
    setNavi({
        route:'/cps/overview'
    })
    let ColorHolder:any = [];
    if (typeof AllCPS !== "boolean" && AllCPS?.length > 0) {
        for (let c = 0; c < AllCPS.length; c++) {
            ColorHolder.push({color:AllCPS[c].color,title:AllCPS[c].title})
        }
    }

    const [state, setState] = useState({
        background: '#fff',
    });

    let AllCPSHoldder= [];


    if (typeof AllCPS !== "boolean" && AllCPS?.length > 0) {
        for (let c = 0; c < AllCPS.length; c++) {

            AllCPSHoldder.push(
                <>
                    <tr>
                        <td style={{background: AllCPS[c].color, width: '20px'}}></td>
                        <td style={{width: '150px'}}>{AllCPS[c].title}</td>
                        <td style={{width: '40px'}}><Button variant="primary" onClick={()=>AddedFunc3(EditCPS,{id:AllCPS[c].id,title:AllCPS[c].title,color:AllCPS[c].color})}>Edit</Button></td>
                        <td style={{width: '40px'}}><Button variant="danger" onClick={()=>deleteCPS(AllCPS[c].id)}>Delete</Button></td>
                    </tr>
                </>
            )
        }
    }
    const Prio = ResponseAllPrio(fetchData)
    let AllPrioHoldder= [];
    if (typeof Prio !== "boolean" && Prio?.length > 0) {
        for (let c = 0; c < Prio.length; c++) {
            AllPrioHoldder.push(
                <>
                    <tr>
                        <td style={{background: Prio[c].color, width: '20px'}}></td>
                        <td style={{width: '150px'}}>{Prio[c].name}</td>
                        <td style={{width: '150px'}}>{Prio[c].underline}</td>
                        <td style={{width: '40px'}}><Button variant="primary" onClick={()=>AddedFunc3(EditPrio,{id:Prio[c].id,name:Prio[c].name,color:Prio[c].color,underline:Prio[c].underline})}>Edit</Button></td>
                        <td style={{width: '40px'}}><Button variant="danger" onClick={()=>deletePrio(Prio[c].id)}>Delete</Button></td>
                    </tr>
                </>
            )
        }
    }

    const Labels = ResponseAllLabels(fetchData)
    let AllLabelsHoldder= [];
    if (typeof Labels !== "boolean" && Labels?.length > 0) {
        for (let c = 0; c < Labels.length; c++) {
            AllLabelsHoldder.push(
                <>
                    <tr>
                        <td style={{background: Labels[c].color, width: '20px'}}></td>
                        <td style={{width: '150px'}}>{Labels[c].name}</td>
                        <td style={{width: '40px'}}><Button variant="primary" onClick={()=>AddedFunc3(EditLabel,{id:Labels[c].id,name:Labels[c].name,color:Labels[c].color})}>Edit</Button></td>
                        <td style={{width: '40px'}}><Button variant="danger" onClick={()=>deleteLabel(Labels[c].id)}>Delete</Button></td>
                    </tr>
                </>
            )
        }
    }

    const CloseMember = (indiId?:any,indclear?:any) => {
        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogdynamik')) as HTMLDivElement;
        Dialog.style.display = 'none';

        if(indclear !== null && indclear !== undefined) {
            for(let i =0;i<indclear.length;i++) {
                let tmp = document.getElementById(indclear[i].id) as HTMLInputElement;

                if(tmp !== null) {
                    tmp.value = indclear[i].value;
                }
            }
        }

        let Task = document.getElementById('TaskId') as HTMLInputElement;

        if(Task !== null) {
            Task.value = '';
        }

        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(Project !== null){
            Project.value = ''
        }
    }

    const AddedFunc3 = (form:any,formParam:any) => {

        let Dialog = document.getElementById('addDialogdynamik') as HTMLDivElement;
        Dialog.classList.add("show");
        Dialog.style.display = 'block';
        if(form !== undefined) {
            let newElement = form(formParam)
            let temp = document.createElement('div')
            ReactDOM.render(newElement, temp)
            let ele = document.getElementById('injectModalTask') as HTMLDivElement
            if(ele !== null) {
                ele.innerHTML = ''
                ele.prepend(temp)
            }
        }
    }

    const EditCPS = (data:any) => {
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editCPS(e,CloseMember,'addDialogdynamik')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">CPS</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember()}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <Row>
                                    <Col className={'col-10'}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" id={'editSwimelanename'} name={'title'} required={true}
                                                      defaultValue={data.title}/>
                                    </Form.Group></Col>
                                    <Col className={'col-2'}> <Form.Label htmlFor="exampleColorInput">Color</Form.Label>
                                        <Form.Control
                                            type="color"
                                            name={'color'}
                                            id={'editSwimelanecolor'}
                                            defaultValue={data.color}
                                        /></Col>
                                </Row>
                                <input name={'id'} type="hidden" id="id" className="form-control" defaultValue={data.id} />
                                <AddedColor state={state} cid={'editSwimelanecolor'} color={ColorHolder} setState={setState} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember()}>Close
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >Save CPS</button>
                            </div>


                        </div>
                    </Form>
                </div>
            </>
        )}

    const editCPS = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            title: formdata.get('title'),
            color: formdata.get('color')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'ColorPaletteStandard/create/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))
    }

    const deleteCPS = (Lid:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        fetch(ApiRoot()+'ColorPaletteStandard/delete/yoptimize/'+Lid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))

    }

    const EditPrio = (data:any) => {
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editPrio(e,CloseMember,'addDialogdynamik')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Prio</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember()}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <Row>
                                    <Col className={'col-10'}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" id={'editSwimelanename'} name={'name'} required={true}
                                                      defaultValue={data.name}/>
                                    </Form.Group></Col>
                                    <Col className={'col-2'}> <Form.Label htmlFor="exampleColorInput">Color</Form.Label>
                                        <Form.Control
                                            type="color"
                                            name={'color'}
                                            id={'editSwimelanecolor'}
                                            defaultValue={data.color}
                                        /></Col>
                                </Row>
                                <input name={'id'} type="hidden" id="id" className="form-control" defaultValue={data.id} />
                                <AddedColor state={state} cid={'editSwimelanecolor'} color={ColorHolder} setState={setState} />
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Underline</Form.Label>
                                    <Form.Control as="textarea" rows={3} name={'underline'} defaultValue={data.underline}/>
                                </Form.Group>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember()}>Close
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >Save Prio</button>
                            </div>


                        </div>
                    </Form>
                </div>
            </>
        )}

    const editPrio = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            name: formdata.get('name'),
            underline: formdata.get('underline'),
            color: formdata.get('color')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'prioritaeten/create/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))
    }

    const deletePrio = (Lid:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        fetch(ApiRoot()+'prioritaeten/delete/yoptimize/'+Lid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))

    }

    const EditLabel = (data:any) => {
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editLabel(e,CloseMember,'addDialogdynamik')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Label</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember()}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <Row>
                                    <Col className={'col-10'}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" id={'editSwimelanename'} name={'name'} required={true}
                                                      defaultValue={data.name}/>
                                    </Form.Group></Col>
                                    <Col className={'col-2'}> <Form.Label htmlFor="exampleColorInput">Color</Form.Label>
                                        <Form.Control
                                            type="color"
                                            name={'color'}
                                            id={'editSwimelanecolor'}
                                            defaultValue={data.color}
                                        /></Col>
                                </Row>
                                <input name={'id'} type="hidden" id="id" className="form-control" defaultValue={data.id} />
                                <AddedColor state={state} cid={'editSwimelanecolor'} color={ColorHolder} setState={setState} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember()}>Close
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >Save Label</button>
                            </div>


                        </div>
                    </Form>
                </div>
            </>
        )}

    const editLabel = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            name: formdata.get('name'),
            underline: formdata.get('underline'),
            color: formdata.get('color')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'label/create/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))
    }

    const deleteLabel = (Lid:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        fetch(ApiRoot()+'label/delete/yoptimize/'+Lid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))

    }

    const successForm = (result:any,close:any,mode?:any,indiclear?:any) => {
        setFetchData(Date.now())
        notify_save()
        if(mode !== undefined && mode !== null) {
            close(mode,indiclear)
        } else {
            close()
        }

    }

    const errorForm = (error:any,close:any,mode?:any)=> {
        if(mode !== undefined && mode !== null) {
            close(mode)
        } else {
            close()
        }
    }

    const collapseGeneralSettings = (e:any,id:any,arrowShow:any=true,noneShow:any = [],withLabel:any=false,setFetchData?:any) => {

            let Collapse = document.getElementById(id) as HTMLDivElement;
            if (Collapse.classList.contains("d-none") === true) {
                Collapse.classList.remove("d-none");
                Collapse.classList.add("d-block");
                let CollapseInfo = document.getElementById(id+'_info') as HTMLDivElement;
                CollapseInfo.classList.remove("d-none");
                CollapseInfo.classList.add("d-block");

                for(let ns=0;ns<noneShow.length;ns++) {
                    let CollapseClose = document.getElementById(noneShow[ns]) as HTMLDivElement;
                    CollapseClose.classList.add("d-none");
                    CollapseClose.classList.remove("d-block");
                    let CollapseInfo = document.getElementById(noneShow[ns]+'_info') as HTMLDivElement;
                    CollapseInfo.classList.add("d-none");
                    CollapseInfo.classList.remove("d-block");

                }
            }
        }


    return (
        <>
            <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
            <Row>
                    <Col xs={2} md={2} xl={2} xxl={2}>
                        <h4 onClick={(e) => collapse(e, 'actions')}>Actions
                            <span id={'actions_arrow'} className="material-icons" style={{verticalAlign: 'bottom'}}>
                            expand_less
                        </span>
                        </h4>
                        <div id={'actions'} className={'d-block'}>
                        <Navbar className="bg-body-transparent">
                            <Container>
                                <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}}  onClick={(e) => collapseGeneralSettings(e, 'cps',false,['Priority','Label'])} >
                            <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                palette
                            </span>
                                    Color Standard Palette
                                </Navbar.Brand>
                            </Container>
                        </Navbar>
                        <Navbar className="bg-body-transparent">
                            <Container>
                                <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => collapseGeneralSettings(e, 'Priority',false,['cps','Label'])} >
                                  <span className="material-icons" style={{verticalAlign: 'middle'}} >
                                low_priority
                            </span>
                                    Priority</Navbar.Brand>
                            </Container>
                        </Navbar>
                        <Navbar className="bg-body-transparent">
                            <Container>
                                <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => collapseGeneralSettings(e, 'Label',false,['cps','Priority'])} >
                                  <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                label
                            </span>
                                    Ticket Label</Navbar.Brand>
                            </Container>
                        </Navbar>
                    </div>
                </Col>
                <Col xs={4} md={4} xl={4} xxl={4}>
                    <Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px'}} className={'d-block'} id={'cps'}>
                        <Col style={{width:'max-content'}}>
                            <div style={{width:'450px',paddingRight:'0px',marginBottom:'10px'}}>
                                <Button variant="success" className={'float-end'}
                                        onClick={()=>AddedFunc3(EditCPS,{id:0,title:'',color:'#ffffff'})}>New Card Background Color</Button>
                                <br style={{clear:'both'}}/>
                            </div>

                            <Table striped bordered hover style={{width:'450px'}}>
                                <thead>
                                <tr>
                                    <th style={{width:'20px'}}>Color</th>
                                    <th style={{width:'150px'}}>Title</th>
                                    <th style={{width:'40px'}}>Edit</th>
                                    <th style={{width:'40px'}}>Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                {AllCPSHoldder}
                                </tbody>
                            </Table>
                        </Col>
                    </Row><Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px'}} className={'d-none'} id={'Priority'}>
                        <Col style={{width:'max-content'}}>
                            <div style={{width:'450px',paddingRight:'0px',marginBottom:'10px'}}>
                                <Button variant="success" className={'float-end'}
                                        onClick={()=>AddedFunc3(EditPrio,{id:0,name:'',color:'#ffffff'})}>New Priority</Button>
                                <br style={{clear:'both'}}/>
                            </div>
                            <Table striped bordered hover style={{width:'450px'}}>
                                <thead>
                                <tr>
                                    <th style={{width:'20px'}}>Color</th>
                                    <th style={{width:'50px'}}>Title</th>
                                    <th style={{width:'350px'}}>Description</th>
                                    <th style={{width:'40px'}}>Edit</th>
                                    <th style={{width:'40px'}}>Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                {AllPrioHoldder}
                                </tbody>
                            </Table>
                        </Col>
                </Row><Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px'}} className={'d-none'}  id={'Label'}>
                        <Col style={{width:'max-content'}}>
                            <div style={{width:'450px',paddingRight:'0px',marginBottom:'10px'}}>
                                <Button variant="success" className={'float-end'}
                                        onClick={()=>AddedFunc3(EditLabel,{id:0,name:'',color:'#ffffff',underline:''})}>New Ticket Label</Button>
                                <br style={{clear:'both'}}/>
                            </div>
                            <Table striped bordered hover style={{width:'450px'}}>
                                <thead>
                                <tr>
                                    <th style={{width:'20px'}}>Color</th>
                                    <th style={{width:'50px'}}>Name</th>
                                    <th style={{width:'40px'}}>Edit</th>
                                    <th style={{width:'40px'}}>Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                {AllLabelsHoldder}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
                <Col xs={6} md={6} xl={6} xxl={6}>
                    <Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px'}} className={'d-block'} id={'cps'}>
                        <Col style={{width:'max-content'}}>
                            <Card id={'cps_info'} className={'d-block'}>
                                <Card.Body>
                                    <div dangerouslySetInnerHTML={{__html: IntlMessageValue('','cps.info',Store)}} className={'text-justify'} />
                                   </Card.Body>
                            </Card>
                            <Card id={'Priority_info'} className={'d-none'}>
                                <Card.Body>
                                    <div dangerouslySetInnerHTML={{__html: IntlMessageValue('','cps.info',Store)}} className={'text-justify'} />
                                </Card.Body>
                            </Card>
                            <Card id={'Label_info'} className={'d-none'}>
                                <Card.Body>
                                    <div dangerouslySetInnerHTML={{__html: IntlMessageValue('','cps.info',Store)}} className={'text-justify'} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>






            </div>
            <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                 id="addDialogdynamik"
                 style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>

                <div className="offcanvas-body">
                    <div id="injectModalTask"></div>
                </div>
            </div>
        </>
    )
}

export default CPS;
