import {requests} from '../../../../../@WUM/core/RestFullApi/api';
import {peopleApiDetailType, peopleApiType} from "./peopleApiType";

export const people = {
    getPosts: (limit:any,searchValue:any): Promise<peopleApiType[]>=> requests.get(`pv/order/All/${limit}/${searchValue}`),
    getAPost: (id: string): Promise<peopleApiType[]> => requests.get(`pv/order/${id}/`),
    createPost: (put: any,id:number): Promise<peopleApiDetailType> =>
        requests.put(`pv/order/${id}/`, put),
    updatePost: (post: any, id: number): Promise<peopleApiDetailType> =>
        requests.post(`pv/order/${id}/`, JSON.stringify(post)),
    deletePost: (id: string): Promise<void> => requests.delete(`pv/order/${id}/`),
    fetchMwSt: (): Promise<peopleApiType[]>=> requests.get(`pv/mwst/All/0/`),
    fetchbuytype: (): Promise<peopleApiType[]>=> requests.get(`pv/zahlungsart/All/0/`),
    fetchpricetype: (): Promise<peopleApiType[]>=> requests.get(`pv/preistyp/All/0/`),
    fetchservicetimetype: (): Promise<peopleApiType[]>=> requests.get(`pv/serivezeitraumtype/All/0/`),
    fetchAllProducts: (): Promise<peopleApiType[]>=> requests.get(`pv/prodcut/fetch/All/`),
}
