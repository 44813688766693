import {init2} from "../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {ApiRoot} from "../../../../@WUM/core/RestFullApi/api";

const sendEdit = (e:any,close:any,id:any,divid:any,successForm:any,errorForm:any,setFetchData:any,navigate:any,editRoute:any) => {
    e.preventDefault()
    const form = e.currentTarget
    let Data = init2(form)
    console.log(Data)
    var raw = JSON.stringify(Data);

    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('token') as string)
        },
        body: raw
    }

    let route = (editRoute as string).replace('[param]',id)

    fetch(ApiRoot()+''+route, requestOptions)
        .then((response) => response.text())
        .then((result) => successForm(result,close,true,divid,setFetchData,navigate))
        .catch((error) => errorForm(error,close,divid))

}

export default sendEdit;
