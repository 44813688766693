import {initialStatePeopleOverview, PeopleInterface} from "../../interface/People.interface";
import {
    ResponsePeopleOverview,
} from "../../API/response/people";
import PielersTable from '../../../../../@WUM/core/tablemaker';
import {useNavigate,useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useState} from "react";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import SearchJSX from "../../../../../@WUM/core/component/const/SearchJSX";
import {handleSucces} from '../../../../../@WUM/core/component/const/HandleSucces';
import PaginationJSX from '../../../../../@WUM/core/form-serilizer/elements/pagination';
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {useLocalStorage} from "../../../../../@WUM/core/component/const/useLocalStoreage";
import {DynamikOffCanvaspv} from "../../../../../@WUM/core/component/const/DynamikModal";
import PielersForm from "../../../../../@WUM/core/form-serilizer";
import dataColOnePriceType from "../../../priceType/modules/configuration/dataColOne";
import handlesubmit from "../../../../CMS/telephone/modules/configuration/handlesubmit";
import {BusinessCardMwStType, BusinessCardPriceType} from "../../../../CMS/telephone/helper/BusinessCard";
import Edit from "../../../../CMS/telephone/helper/Edit";
import AddedFunc from "../../../../CMS/telephone/helper/AddedFunc";
import sendEdit from "../../../../CMS/telephone/helper/sendEdit";
import successForm from "../../../../CMS/telephone/helper/successForm";
import errorForm from "../../../../CMS/telephone/helper/errorForm";
import Delete from "../../../../CMS/telephone/helper/Delete";
import sendDeleted from "../../../../CMS/telephone/helper/sendDeleted";
import successDeleted from "../../../../CMS/telephone/helper/succesDeleted";
import AddDialogdynamik from "../../../../CMS/telephone/helper/AddDialogdynamik";
import dataColOneMwSTType from "../configuration/dataColOne";
import Button from "react-bootstrap/Button";
/**
 *
 * @param props
 * @constructor
 */
const PeopleOverview = (props:PeopleInterface = initialStatePeopleOverview) => {
    const Store = useSelector((state: any) => state)

    const {page} = useParams<{page: string}>();
    const history = useNavigate();
    const namespace_search = "mwstType_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    let limit: any;
    const destination = 'mwstType';

    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }
    const [fetchData, setFetchData] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [makeDelete, setMakeDelete] = useState(null);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const CloseDynmaikModal = () => { handleClose(); }
    const saveRoute = 'pv/mwst/[param]/'
    const deletedRoute = 'pv/mwst/[param]/'
    const editRoute = 'pv/mwst/[param]/'

    const successFormDynamik = (result:any) => {
        setFetchData(Date.now())
        handleClose() }

    const errorFormDynamik = (error:any)=> {
        handleClose()
    }

    let response = ResponsePeopleOverview(limit, searchValue,fetchData);

    const loadedDelete = (data:any) => {setMakeDelete(data);handleShowDelete(); }


    const Configuration = {
        'SearchConfig': [{
            namespace: namespace_search,
            destination: destination,
            input: [
                {id: 'Schluessel', translation: 'Schlüssel', param: 'Schluessel', method: 'like'},
                {id: 'Text', translation: 'Text', param: 'Text', method: 'like'},
                {id: 'standartwert', translation: 'Standartwert', param: 'standartwert', method: 'like'},

            ],
            button: ['common.search', 'common.del.search', 'Neuen MwSt-Type anlegen']
        }],
        'table': {
            'tableBody': {
                'data': response,
                'dataID': 'id',
                'underdata': true,
                'keys': ['id','Schluessel', 'Text','standartwert'],
                'type': ['string','string', 'string','string'],
                'position':['left','left','left','left']
            },
            'tableHeader': [
                'ID',
                'Schluessel',
                'Text',
                'standartwert'],
            'tableHeaderWidth': ['10%','10%','70%%','10%'],
            'tableHeaderposition': ['left','left','left','left'],
            'tablecolor': ['light'],
            'menu': {
                'data': response,
                'menuDisable': false,
                'menutriggerDelete': true,
                'menuItem': [<IntlMessage Store={Store} messageId="common.edit"/>,
                    <IntlMessage Store={Store} messageId="common.delete"/>],
                'menuroute': ['/'+destination+'/Detail', '/'+destination+'/delete'],
                'menuParams': ['', 'id', 'id'],
                'menuIcons': ['pencil', 'Delete'],
            },
        }
    };
    if (response !== undefined && response.length > 0) {
    return(
        <>
            <div className={'site'}>
                <SearchJSX setSearchValue={setSearchValue} Store={Store}
                           SearchConfig={Configuration['SearchConfig']}
                           indiButton={<Button variant="primary" className={'float-end'} onClick={handleShow}>
                                   <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                                        add
                                    </span>
                               MwSt-Type anlegen
                           </Button>}
                />
                <br style={{clear: 'both'}}/>
                <DynamikOffCanvaspv openButtonLabel={<></>}
                                    saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                                    closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                                    SaveID={'newphone'}
                                    title={'Anlage eines neuen Mehrwertsteuertyps'}
                                    saveDisabled={false}
                                    ClassOpenButton={'float-end'} indiButton={''}
                                    ClassSaveButton={'float-end'}
                                    body={<>{PielersForm(dataColOneMwSTType(true,response))}</>}
                                    handlesubmit={handlesubmit}
                                    errorForm={errorFormDynamik}
                                    successForm={successFormDynamik}
                                    setFetchData={setFetchData}
                                    handleShow={handleShow}
                                    saveRoute={saveRoute}
                                    show={show}
                                    setShow={setShow}
                                    handleClose={CloseDynmaikModal}
                />
                <br style={{clear: 'both'}}/>
                <BusinessCardMwStType response={response}
                                       Edit={Edit}
                                       AddedFunc={AddedFunc}
                                       loadedDelete={loadedDelete}
                                       sendEdit={sendEdit}
                                       successForm={successForm}
                                       errorForm={errorForm}
                                       editRoute={editRoute}
                                       setFetchData={setFetchData}
                                       navigate={navigate}
                                       dataColOne={dataColOneMwSTType} />
                <Delete sendDeleted={sendDeleted}
                        makeDelete={makeDelete}
                        successDeleted={successDeleted}
                        handleCloseDelete={handleCloseDelete}
                        deletedRoute={deletedRoute}
                        FieldMakeDelete={['Schluessel','id']}
                        setFetchData={setFetchData}
                        showDelete={showDelete} />
                <AddDialogdynamik />
                <PaginationJSX response={response} history={history} to={'overview'}
                               handleSuccess={handleSucces} destination={destination}/>
            </div>
        </>
    )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default PeopleOverview;
