

export interface configPriceType {
  "id": number,
  "text":string;
  "textkurz": string;
}

export function outputPriceType(Data: any) {
  let back = {
    id: Data.id,
    text:Data.text,
    textkurz: Data.textkurz
  }

  return back;
}
