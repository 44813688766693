import React from 'react';
import './@WUM/templates/default/assets/css/App.css';
import TemplateSwitcher from "./@WUM/templates";
import {useSelector} from "react-redux";
import MediaGalerie from "./@WUM/core/component/const/mediaGalerie";
import TooltipModal from "./@WUM/core/component/const/Tooltip";




const App = () => {
    const Store = useSelector((state: any) => state)

    return (

        <div className="App">
            <TemplateSwitcher layout={'default'} Store={Store}/>
            <MediaGalerie />
            <TooltipModal />
        </div>
    );
}

export default App;
