const successForm = (result:any,close:any,mode:any,modeID:any,setFetchData:any,navigate:any,rewrite?:any) => {
    setFetchData(Date.now())
    if(mode !== undefined && mode !== null) {
        if(modeID !== undefined && modeID !== null) {
            close(mode,modeID)
        }else{
            close(mode)
        }

    } else {
        close()
    }

    if(rewrite !== undefined && rewrite !== null) {
        navigate(result.url)
    }

}

export default successForm;
