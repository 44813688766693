
/* @ts-ignore */
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useState} from "react";


const CreateGrantDiagramm = (props:{ressource:any,id:any,setFetchData:any,className:any,GrantClick:any}) => {
    const [mode, setMode] = useState('Day');
    let TaskHolder = [];
    let ListHolder = [];
    for(let re=0;re<props.ressource.length;re++) {

        let due_on = new Date((props.ressource[re].due_on === null ? props.ressource[re].created_at : props.ressource[re].due_on))
        if(props.ressource[re].due_on === null) {
            due_on = new Date(due_on.getTime() + 86400000); // + 1 day in ms
            due_on.toLocaleDateString();
        }
        ListHolder.push(
            <div className="_2B2zv" style={{height:'50px',border: '1px solid #e0e0e0',paddingLeft:'10px',paddingTop:'5px',background: 'white',overflow:'hidden'}}>
                <strong>{props.ressource[re].task_id}</strong> {props.ressource[re].regard}
            </div>
        )
        TaskHolder.push(
            {
                start: new Date(props.ressource[re].created_at),
                end: due_on,
                name: props.ressource[re].regard,
                id: props.ressource[re].task_id ,
                type: 'task',
                progress: 100,
                isDisabled: true,
                styles: {progressColor: props.ressource[re].color, progressSelectedColor: '#ff9e0d',backgroundColor:props.ressource[re].color}
            }
        )
    }
    /* @ts-ignore */
    let tasks: Task[] = TaskHolder;
    let columnWidth = 65;
    if (mode === ViewMode.Year) {
        columnWidth = 350;
    } else if (mode === ViewMode.Month) {
        columnWidth = 300;
    } else if (mode === ViewMode.Week) {
        columnWidth = 250;
    }
    if(tasks.length === 0) {
        return (
            <>
                <div id={props.id} className={props.className} style={{overflow:'hidden'}}>

                </div>
            </>
        )
    } else {
        return (
            <>
                <div id={props.id} className={props.className} style={{overflow:'hidden'}}>

                    <br/><br/>
                    <Row>
                        <Col className={'col-2 pr-0'}>
                            <div>
                                <div className="_3_ygEs" >
                                    <div className="_1nBOt" >
                                        <div className="_WuQ0f" ></div>
                                        <ButtonGroup aria-label="Basic example">
                                            <Button variant={(mode === 'Day' ? "primary" : "secondary")} onClick={()=>setMode('Day')}>Day</Button>
                                            <Button variant={(mode === 'Week' ? "primary" : "secondary")} onClick={()=>setMode('Week')}>Week</Button>
                                            <Button variant={(mode === 'Month' ? "primary" : "secondary")} onClick={()=>setMode('Month')}>Month</Button>
                                            <Button variant={(mode === 'Year' ? "primary" : "secondary")} onClick={()=>setMode('Year')}>Year</Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                                {ListHolder}
                            </div>
                        </Col>
                        <Col className={'col-10 pl-0'}>
                            {/* @ts-ignore */}
                            <Gantt tasks={tasks} viewMode={ViewMode[mode]} locale="de-DE"  onViewListChange={true} columnWidth={columnWidth}  onDoubleClick={props.GrantClick}/>
                        </Col>
                    </Row>

                </div>
            </>
        )
    }

}

export default CreateGrantDiagramm;
