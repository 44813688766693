import {Text, View} from "@react-pdf/renderer";
import {stylesFooter as styles} from "./css/PdfFooter2.css";
import React from "react";


interface PdfFooterInterface {
    layout: any
}

export const PdfFooter2 = (props:PdfFooterInterface) => {
    return(
        <>
            <View
                style={styles.footerColumns}
                fixed={true}
            >
                <View style={[styles.footerText]}>
                    <View style={[styles.dataFooter, {marginTop: 10}]}>
                        <View style={styles.footerWidthBoxWider} debug={false}>
                            <View style={styles.center}>
                                <View>
                                    <Text style={styles.marginRightBasicHighlight}>
                                        {props.layout.ownCompany}
                                    </Text>
                                    <Text>
                                        {props.layout.address}
                                    </Text>
                                   <Text>
                                        {props.layout.plz} {props.layout.city}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.footerWidthBoxWider}>
                            <View style={styles.center}>
                                <View>
                                    <Text>
                                        Geschäftsführung: {props.layout.ceo}
                                    </Text>
                                    <Text>
                                        {props.layout.courtLoc}
                                    </Text>
                                    <Text>
                                        Ust-ID: {props.layout.taxId}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.footerWidthBoxWider} debug={false}>
                            <View style={styles.center}>
                                <View>
                                    <Text>
                                        {props.layout.bankName}
                                    </Text>
                                    <Text>
                                        BIC: {props.layout.BIC}
                                    </Text>
                                    <Text>
                                        IBAN: {props.layout.IBAN}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

            </View>

        </>
    )
}