import React from "react";
import PeopleOverview from "./Pages/overview";
import {
    initialStatePeopledMenuConfig,
    initialStatePeoplePagesConfig, PeopledMenuConfigInterface,
    PeoplePagesConfigInterface
} from "../interface/People.interface";
import {LanguageSwitcherValue} from "../../../../@WUM/core/component/core/components/LanguageSwitcher";
import PeopleNew from "./Pages/new";
import DetailPeople from "./Pages/detail";
import Peopledeleted from "./Pages/deleted";

export const ProductPagesConfig = (props: PeoplePagesConfigInterface = initialStatePeoplePagesConfig) => {


    return [
        {
            path: '/product/overview/:page',
            elements: <PeopleOverview value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/product/new/:page',
            elements: <PeopleNew value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/product/detail/:id',
            elements: <DetailPeople value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/product/delete/:deleteID',
            elements: <Peopledeleted value={props.value}/>,
            roles: ['superadmin','admin']
        },
    ];
}

export const ProductMenuConfig = (props: PeopledMenuConfigInterface = initialStatePeopledMenuConfig) => {
    return [
        {
            name: 'Produkte & Leistungen',
            route: '/product/overview/1',
            icon: 'production_quantity_limits',
            roles: ['superadmin','admin']
        },
    ]
}
