import {Image, Text, View} from "@react-pdf/renderer";
import React from "react";
import {stylesTextArea as styles} from "./css/PdfTextArea.css";
import {dateFormat} from "../../../../@WUM/core/component/const/dateFormat";
import {CheckValue} from "../../functions/checkValue";

interface PdfTextAreaInterface {
    Data: any
    type: string | "offer" | "invoice"
    PaymentMethod: any
    Store: any
}



export const PdfTextArea = (props:PdfTextAreaInterface) => {

    console.log(props.PaymentMethod)
    console.log(props.Data.invoicePaymentType)

    let paymentMethodValue: string
    let paymentMethod
    let posttext
    let closingSentence

    if (props.type === "offer") {
        paymentMethodValue = props.Data.offerPaymentMethod;
        const paymentMethodTitle = props.PaymentMethod?.find((item: { id: number; }) => item.id === parseInt(paymentMethodValue))?.title;
        paymentMethod = paymentMethodTitle ? "Zahlungsweise: " + paymentMethodTitle : ""; // Set default text if undefined

        posttext = props.Data.offerComment;
    } else if ( props.type === "invoice") {
        paymentMethodValue = props.Data.invoicePaymentMethod;

        const paymentMethodTitle = props.PaymentMethod?.find((item: { id: number; }) => item.id === parseInt(paymentMethodValue))?.title;
        paymentMethod = paymentMethodTitle ? "Zahlungsweise: " + paymentMethodTitle : ""; // Set default text if undefined

        posttext = props.Data.billingComment;
        closingSentence = props.Data.closingSentence;

    }

    const offerPaymentSignatureDate = dateFormat(
        props.Data.offerPaymentSignatureDate,
        'de',
        'us',
        false,
        false,
        props.Store
    )
    const offerPaymentLocation = props.Data.offerPaymentLocation

    return(
        <>
            <Text style={styles.text}>
                {posttext}
            </Text>
            <View style={styles.marginTopSignature}>
                <Text style={styles.text}>
                        {closingSentence}
                    </Text>
                <Text style={styles.text}>
                    {/*{(paymentMethodValue !== null && paymentMethodValue !== undefined && paymentMethodValue !== '' ?<>Zahlungsweise: {paymentMethod}</> : <></>)}*/}
                    {paymentMethod}
                </Text>
            </View>
            <View style={[styles.text, {marginTop: "5"}]}>
                {(props.Data.acceptAgb === "on" ?  <Text>Ich habe die AGB der Webkommunikation24 GmbH gelesen und stimme zu.</Text>:<></>)}
                {(props.Data.acceptDataProtect === "on" ?  <Text>Ich habe die Datenschutzerklärung der Webkommunikation24 GmbH gelesen und stimme zu.</Text>:<></>)}



            </View>
            <View style={[styles.row, { justifyContent: "space-between" }]} wrap={false}>
                {(props.Data.clientSignature !== null && props.Data.clientSignature !== undefined && props.Data.clientSignature !== '' ?
                    <View style={styles.marginTopSignature}>
                        {(props.Data.offerPaymentLocation !== null && props.Data.offerPaymentLocation !== undefined && props.Data.offerPaymentLocation !== '' ?
                            <Text style={styles.text}>
                                {offerPaymentLocation}, den {offerPaymentSignatureDate}
                            </Text> :
                            <></>)}
                        <View>
                            <Image
                                src={props.Data.clientSignature}
                                style={styles.signature}
                            />
                            <Text style={styles.text}>
                                Auftraggeber
                            </Text>
                        </View>
                    </View> :
                    <></>)}
                {(props.Data.contractorSignature !== null && props.Data.contractorSignature !== undefined && props.Data.contractorSignature !== '' ?
                    <View style={styles.marginTopSignature}>
                        <Text style={styles.text}> </Text>
                        <View>
                            <Image
                                src={props.Data.contractorSignature}
                                style={styles.signature}
                            />
                            <Text style={styles.text}>Auftragnehmer</Text>
                        </View>
                    </View> :
                    <></>)}
            </View>
        </>
    )
}