import {IntlMessage, IntlMessageValue} from "../../../../../@WUM/core/Elements/index";
import React from "react";

const dataColOnePeople = (newElement:any,response:any, Store:any) =>  {
    return [
        {
            'formType': 'input',
            'type': 'hidden',
            'name': 'id',
            'value': (newElement === false ? response.id : '0'),
        },
        {
            'formType': 'select',
            'label': <IntlMessage Store={Store} messageId="form.label.gender" />,
            'name': 'Geschlecht',
            'required': true,
            'selected': (newElement === false ? response.geschlecht : ''),
            'options': [
                {
                    'label': '',
                    'value': ''
                },
                {
                    'label': 'Weiblich',
                    'value': '1'
                },
                {
                    'label': 'Männlich',
                    'value': '0'
                },{
                    'label': 'Divers',
                    'value': '2'
                }]

        },
        {
            'formType': 'input',
            'label': <IntlMessage Store={Store} messageId="form.label.prefix" />,
            'type': 'text',
            'name': 'title',
            'placeholder': 'Vorsatz (z.B. Titel)',
            'value': (newElement === false ? response.title : ''),
        },
        {
            'formType': 'input',
            'label': <IntlMessage Store={Store} messageId="form.label.name" />,
            'type': 'text',
            'required': true,
            'name': 'name',
            'value': (newElement === false ? response.name : ''),
        },
        {
            'formType': 'input',
            'label': <IntlMessage Store={Store} messageId="form.label.firstname" />,
            'type': 'text',
            'required': true,
            'name': 'vorname',
            'value': (newElement === false ? response.vorname : ''),
        },
        {
            'formType': 'input',
            'label': <IntlMessage Store={Store} messageId="form.label.name_suffix" />,
            'type': 'text',
            'name': 'zusatz',
            'placeholder': 'Zusatz (z.B. Titel)',
            'value': (newElement === false ? response.zusatz : ''),
        },
        {
            'formType': 'input',
            'label': <IntlMessage Store={Store} messageId="form.label.birthday" />,
            'type': 'date',
            'name': 'source',
            'required': true,
            'placeholder': 'xx.xx.xxxx',
            'value': (newElement === false ? response.source : ''),
        }
    ];
}

export default dataColOnePeople;
