const dataColOnePriceType = (newElement:any,response:any) =>  {
    return [
        {
            'formType': 'input',
            'type': 'hidden',
            'name': 'id',
            'value': (newElement === false ? response.id : '0'),
        },
        {
            'formType': 'input',
            'label': 'Text',
            'type': 'text',
            'name': 'text',
            'value': (newElement === false ? response.text : ''),
        },
        {
            'formType': 'input',
            'label': 'Kürzel',
            'type': 'text',
            'name': 'textkurz',
            'value': (newElement === false ? response.textkurz : ''),
        }
    ];
}

export default dataColOnePriceType;
