

export interface configProduct {
  "id": number,
  "art":string;
  "status": string;
  "parent": string;
  "artikelnummer": string;
  "kuerzel": string;
  "intbez": string;
  "bezeichnung": string;
  "leistungsbeschreibung": string;
  "preistyp": number,
  "mwsttyp": number,
  "szr_type": number,
  "einzelpreisvorgabe": string;
}

export function outputProduct(Data: any) {
  let back = {
    id: Data.id,
    art:Data.art,
    status: Data.status,
    parent: Data.parent,
    artnr: Data.artikelnummer,
    kuerzel: Data.kuerzel,
    intbez: Data.intbez,
    bezeichnung: Data.bezeichnung,
    leistungsbeschreibung: Data.leistungsbeschreibung,
    preistyp: Data.preistyp,
    mwsttyp: Data.mwsttyp,
    szr_type: Data.szr_type,
    preisjeeinheit: Data.einzelpreisvorgabe,
    waehrung:1,
    einheit:""
  }

  return back;
}
