const dataColOnesztType = (newElement:any,response:any) =>  {
    return [
        {
            'formType': 'input',
            'type': 'hidden',
            'name': 'id',
            'value': (newElement === false ? response.id : '0'),
        },
        {
            'formType': 'input',
            'label': 'Beschreibung',
            'type': 'text',
            'name': 'beschreibung',
            'value': (newElement === false ? response.beschreibung : ''),
        },
        {
            'formType': 'input',
            'label': 'Bezeichnung',
            'type': 'text',
            'name': 'bez',
            'value': (newElement === false ? response.bez : ''),
        },

        {
            'formType': 'input',
            'label': 'Schlusssatz',
            'type': 'text',
            'name': 'schlusssatz',
            'value': (newElement === false ? response.schlusssatz : ''),
        },
    ];
}

export default dataColOnesztType;
