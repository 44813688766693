import {initialStatePeopleOverview, PeopleInterface} from "../../interface/People.interface";
import {
    Responsebuytype,
    ResponseDeletePeople, ResponsefetchAllProducts,
    ResponseMwST,
    ResponsePeopleOverview,
    Responsepricetype, Responseservicetimetype
} from "../../API/response/people";
import PielersTable from '../../../../../@WUM/core/tablemaker';
import {useNavigate,useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useState} from "react";
import {IntlMessage, SearchJSX, SpinnerJSX} from "../../../../../@WUM/core/Elements/index";
import PaginationJSX from '../../../../../@WUM/core/form-serilizer/elements/pagination';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useLocalStorage, handleSucces} from "../../../../../@WUM/core/Function/index";
/**
 *
 * @param props
 * @constructor
 */
const PeopleOverview = (props:PeopleInterface = initialStatePeopleOverview) => {
    const Store = useSelector((state: any) => state)
    localStorage.removeItem('offer')
    localStorage.removeItem('OfferData')
    const {page} = useParams<{page: string}>();
    const history = useNavigate();
    const namespace_search = "offer_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    let limit: any;
    const destination = 'offer';

    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }


    let response = ResponsePeopleOverview(limit, searchValue);
    let MwST = ResponseMwST()
    let buytype = Responsebuytype('')
    let pricetype = Responsepricetype()
    let servicetimetype = Responseservicetimetype('')
    let fetchAllProducts = ResponsefetchAllProducts();


    const Configuration = {
        'SearchConfig': [{
            namespace: namespace_search,
            destination: destination,
            input: [
                {id: 'Angebotsnr', translation: 'Angebotsnr', param: 'number', method: 'like'},
                {id: 'Text', translation: 'Text', param: 'billingComment', method: 'like'},
                {id: 'Kommentar', translation: 'Kommentar', param: 'comment', method: 'like'},
                {id: 'kundenfirma', translation: 'kundenfirma', param: 'kundenfirma', method: 'like'},
                {id: 'Kundenname', translation: 'Kundenname', param: 'kunde', method: 'like'}
            ],
            button: ['common.search', 'common.del.search', 'offer.create.new']
        }],
        'table': {
            'tableBody': {
                'data': response,
                'dataID': 'id',
                'underdata': true,
                'keys': ['id','nr', 'datum', 'info', 'recipient','netto','mwst','brutto','open'],
                'type': ['string','string', 'string', 'injection', 'string','euro', 'euro', 'euro', 'euro'],
                'position':['left','left','left','left','left','left','left','left','left']
            },
            'tableHeader': [
                'ID',
                'Nr.',
                'Datum',
                'Information',
                'Empfänger',
                'Nettosumme',
                'MwSt.',
                'Bruttosumme',
                'offen'
                ],
            'tableHeaderWidth': ['5%','5%','10%','20%','10%','15%','10%%','10%','10%'],
            'tableHeaderposition': ['left','left','left','left','left','left','left','left','left'],
            'tablecolor': ['light'],
            'menu': {
                'data': response,
                'menuDisable': false,
                'menutriggerDelete': true,
                'menuItem': [<IntlMessage Store={Store} messageId="common.edit"/>,
                    <IntlMessage Store={Store} messageId="common.delete"/>],
                'menuroute': ['/'+destination+'/Detail', '/'+destination+'/delete'],
                'menuParams': ['', 'id', 'id'],
                'menuIcons': ['pencil', 'Delete'],
            },
        }
    };
    if (response !== undefined && response.length > 0
        && MwST !== undefined && MwST.length > 0
        && buytype !== undefined && buytype.length > 0
        && pricetype !== undefined && pricetype.length > 0
        && servicetimetype !== undefined && servicetimetype.length > 0
        && fetchAllProducts !== undefined && fetchAllProducts.length > 0) {
    return(
        <>
            <div className={'site'}>
                <SearchJSX setSearchValue={setSearchValue} Store={Store}
                           SearchConfig={Configuration['SearchConfig']}/>
                <br style={{clear: 'both'}}/>
              <BuildBill response={response}  history={history}/>
               {/*<PielersTable Configuration={Configuration['table']}/>*/}

                <PaginationJSX response={response} history={history} to={'overview'}
                               handleSuccess={handleSucces} destination={destination}/>
            </div>
        </>
    )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default PeopleOverview;


const BuildBill = (props:{response:any,history:any}) => {

    let Bill = [];

    for(let x=1;x<props.response.length;x++) {
        let summe = parseInt(props.response[x].netto)
        let ConvertSum = Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2
        }).format(summe)
        Bill.push(
            <Col className={'col col-4 '}>
            <Card  className={'mb-3'} style={{border: '1px solid #015b91'}} onClick={()=>props.history('/offer/detail/'+props.response[x].id)}>
                <Card.Body>
                    <Card.Title>Angebotsnummer: {props.response[x].number}</Card.Title>
                    <Card.Text>
                        <Row>
                            <Col>{props.response[x].customer.customer.firma}</Col>
                        </Row>
                        <Row>
                            <Col>Geschäftsführer:</Col>
                            <Col>{props.response[x].customer.customer.geschaeftsfuehrer}</Col>
                        </Row>
                        <Row>
                            <Col>Adressse:</Col>
                            <Col>{props.response[x].customer.customer.address.strasse}<br/>
                                {props.response[x].customer.customer.address.location.PLZ} {props.response[x].customer.customer.address.location.Ort}
                            </Col>
                        </Row>
                       {/* <Row>
                            <Col><p dangerouslySetInnerHTML={{__html: props.response[x].info}} /></Col>
                        </Row>*/}
                    </Card.Text>

                </Card.Body>
            </Card>
            </Col>
        )
    }

    return(
        <>
            <Row>
        {Bill}
            </Row>
        </>
    )
}


