import IntlMessage, {IntlMessageValue} from '../../../../../@WUM/core/component/const/IntlMessage';
import React from "react";

function configuration(conf: string,response:any,newElement:any = false,Store:any) {

  const dataColOne = [
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'id',
      'value': (newElement === false ? response.id : '0'),
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.company" />,
      'type': 'text',
      'name': 'firma',
      'placeholder': 'Vorsatz (z.B. Titel)',
      'value': (newElement === false ? response.firma : ''),
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.managing-director" />,
      'type': 'text',
      'name': 'geschaeftsfuehrer',
      'placeholder': 'Vorsatz (z.B. Titel)',
      'value': (newElement === false ? response.geschaeftsfuehrer : ''),
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.contact-person" />,
      'type': 'text',
      'required': true,
      'name': 'ansprechparter',
      'placeholder': IntlMessageValue('de','name.last.provide',Store),
      'value': (newElement === false ? response.ansprechparter : ''),
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.table.head.remark" />,
      'type': 'text',
      'required': true,
      'name': 'bemerkungen',
      'placeholder': IntlMessageValue('de','name.first.provide',Store),
      'value': (newElement === false ? response.bemerkungen : ''),
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.follow-up" />,
      'type': 'text',
      'required': true,
      'name': 'bemerkungen',
      'placeholder': IntlMessageValue('de','name.first.provide',Store),
      'value': (newElement === false ? response.bemerkungen : ''),
    },
  ];

    return dataColOne;

}

export default configuration;
