import React from "react";
import PeopleOverview from "./Pages/overview";
import {
    initialStatePeopledMenuConfig,
    initialStatePeoplePagesConfig, PeopledMenuConfigInterface,
    PeoplePagesConfigInterface
} from "../interface/People.interface";
import PeopleNew from "./Pages/new";
import DetailPeople from "./Pages/detail";
import Peopledeleted from "./Pages/deleted";
import DetailPreview from "./Pages/detailPreview";

export const OfferPagesConfig = (props: PeoplePagesConfigInterface = initialStatePeoplePagesConfig) => {


    return [
        {
            path: '/offer/overview/:page',
            elements: <PeopleOverview value={props.value}/>,
            roles: ['superadmin','admin']

        },
        {
            path: '/offer/new/:page',
            elements: <PeopleNew value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/offer/detail/:id',
            elements: <DetailPeople value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/offer/delete/:deleteID',
            elements: <Peopledeleted value={props.value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/offer/preview/:page',
            elements: <DetailPreview value={props.value}/>,
            roles: ['superadmin','admin']
        }
    ];
}

export const OfferMenuConfig = (props: PeopledMenuConfigInterface = initialStatePeopledMenuConfig) => {
    return [
        {
            name: 'Angebote',
            route: '/offer/overview/1',
            icon: 'request_quote',
            roles: ['superadmin','admin']
        },
    ]
}

export const QuoteMenuConfig = (value:any) => {
    return [
        {
            header: 'Angebote',
            icon: 'request_quote',
            roles: ['superadmin','admin'],
            menuitem: [
                {
                    name: 'Überblick',
                    route: '/offer/overview/1',
                    icon: 'payments',
                    roles: ['superadmin','admin']
                },
            ]
        },
    ];
}


