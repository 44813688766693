import {ApiRoot} from "../../../../@WUM/core/RestFullApi/api";

const sendDeleted = (id:any,successDeleted:any,handleCloseDelete:any,setFetchData:any,deletedRoute:any) => {

    var raw = '';

    var requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('token') as string)
        },
        body: raw
    }

    let route = deletedRoute.replace('[param]',id)

    fetch(ApiRoot()+''+route, requestOptions)
        .then((response) => response.text())
        .then((result) => successDeleted(handleCloseDelete,setFetchData))
        .catch((error) => console.log(error))
}

export default sendDeleted;

