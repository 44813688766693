import {StyleSheet} from "@react-pdf/renderer";

export const stylesOverview = StyleSheet.create({
    // Positioniert overview ganz hinten rechts und als Row
    overviewParent: {
        fontSize: 10,
        justifyContent: "flex-end",
        flexDirection: 'row', // Set flex direction to 'row' for horizontal arrangement
    },
    // Alignment des Textes der Zusammenfassung
    overview:{
        borderStyle: "solid",
        borderWidth: 0,
        width: 60,
        textAlign: "right",
        fontWeight: "normal"
    },
    // Alignment des Textes der Zusammenfassung
    highlight:{
        borderStyle: "solid",
        borderWidth: 0,
        width: 60,
        textAlign: "right",
        fontWeight: "bold"
    },
});
