

export interface configMwStType {
  "id": number,
    Schluessel:string;
    Text: string;
    standartwert:string;
}

export function outputMwStType(Data: any) {
  let back = {
    id: Data.id,
    Schluessel:Data.Schluessel,
    Text: Data.Text,
    standartwert:Data.standartwert
  }

  return back;
}
